<template>
  <b-form-group label="Assign to folder">
    <b-form-row>
      <b-col>
        <Multiselect
          v-model="folder"
          label="name"
          track-by="id"
          :options="folders"
          placeholder="None"
          select-label=""
          deselect-label=""
        />
        <input
          type="hidden"
          name="folder_id"
          :value="folder?.id"
        >
      </b-col>
      <b-col cols="auto">
        <b-button
          v-b-modal.createLayoutFolderModal
          variant="primary"
        >
          New Folder…
        </b-button>
      </b-col>
    </b-form-row>
    <CreateLayoutFolderModal @ok="selectFolder" />
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import CreateLayoutFolderModal from './modals/CreateLayoutFolderModal.vue'

export default {
  name: 'LayoutFolderSelect',

  components: {
    CreateLayoutFolderModal,
    Multiselect,
  },

  data: () => ({
    folder: null,
  }),

  computed: {
    ...mapGetters('layout', [
      'folders',
    ]),
  },

  async created () {
    await this.fetchFolders({
      pageSize: 999,
      pageNumber: 1,
    })
  },

  methods: {
    ...mapActions('layout', [
      'fetchFolders',
    ]),
    selectFolder (folder) {
      this.folder = folder
    },
  },
}
</script>

<style scoped lang="scss">

</style>
