var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { label: "Presets" } },
        [
          _c("Multiselect", {
            staticClass: "mb-1",
            attrs: {
              options: _vm.presets,
              label: "name",
              "track-by": "id",
              "allow-empty": false,
              "show-labels": false,
            },
            on: { input: _vm.handleSelectPreset },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function (props) {
                  return [
                    _c("div", {
                      staticClass: "preset-preview",
                      style: props.option.preview_style,
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(props.option.name))]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.selectedPresetModel,
              callback: function ($$v) {
                _vm.selectedPresetModel = $$v
              },
              expression: "selectedPresetModel",
            },
          }),
          _vm._v(" "),
          _vm.selectedPreset
            ? _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.bottom",
                      modifiers: { hover: true, bottom: true },
                    },
                  ],
                  attrs: { title: "Apply", variant: "primary" },
                  on: { click: _vm.applyPreset },
                },
                [_c("b-icon", { attrs: { icon: "check-lg" } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.bottom",
                  modifiers: { hover: true, bottom: true },
                },
              ],
              attrs: {
                title: _vm.selectedPreset.id ? `Edit` : "Save",
                variant: "outline-primary",
              },
              on: { click: _vm.savePresetOfOvalShadow },
            },
            [
              !_vm.selectedPreset
                ? _c("b-icon", { attrs: { icon: "save-fill" } })
                : _c("b-icon", { attrs: { icon: "pencil-fill" } }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.selectedPreset
            ? _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.bottom",
                      modifiers: { hover: true, bottom: true },
                    },
                  ],
                  attrs: { title: "Delete", variant: "outline-danger" },
                  on: { click: _vm.deletePreset },
                },
                [_c("b-icon", { attrs: { icon: "trash-fill" } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.bottom",
                  modifiers: { hover: true, bottom: true },
                },
              ],
              attrs: { title: "Reset settings", variant: "outline-danger" },
              on: { click: _vm.resetSettings },
            },
            [_vm._v("\n      Reset\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Gradient", "label-cols": "4" } },
        [
          _c(
            "b-checkbox",
            {
              staticClass: "mt-2",
              attrs: { inline: "" },
              on: {
                change: function ($event) {
                  _vm.editor.radialGradient = false
                },
                input: _vm.redraw,
              },
              model: {
                value: _vm.editor.linearGradient,
                callback: function ($$v) {
                  _vm.$set(_vm.editor, "linearGradient", $$v)
                },
                expression: "editor.linearGradient",
              },
            },
            [_vm._v("\n      Linear\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-checkbox",
            {
              staticClass: "mt-2",
              attrs: { inline: "" },
              on: {
                change: function ($event) {
                  _vm.editor.linearGradient = false
                },
                input: _vm.redraw,
              },
              model: {
                value: _vm.editor.radialGradient,
                callback: function ($$v) {
                  _vm.$set(_vm.editor, "radialGradient", $$v)
                },
                expression: "editor.radialGradient",
              },
            },
            [_vm._v("\n      Radial\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          attrs: {
            disabled: _vm.disabledRounding,
            label: "Rounding",
            "label-cols": "4",
          },
        },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: {
                          max: _vm.maxRounding,
                          min: "0",
                          number: "",
                          type: "number",
                        },
                        on: { input: _vm.redraw },
                        model: {
                          value: _vm.editor.rounding,
                          callback: function ($$v) {
                            _vm.$set(_vm.editor, "rounding", $$v)
                          },
                          expression: "editor.rounding",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: {
                  max: _vm.maxRounding,
                  min: "0",
                  number: "",
                  type: "range",
                },
                on: { input: _vm.redraw },
                model: {
                  value: _vm.editor.rounding,
                  callback: function ($$v) {
                    _vm.$set(_vm.editor, "rounding", $$v)
                  },
                  expression: "editor.rounding",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-checkbox-group",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editor.rounding && !_vm.disabledRounding,
                  expression: "editor.rounding && !disabledRounding",
                },
              ],
              staticClass: "mt-1",
              attrs: {
                "button-variant": "outline-primary",
                buttons: "",
                size: "sm",
              },
              on: { input: _vm.redraw },
              model: {
                value: _vm.selectedCorners,
                callback: function ($$v) {
                  _vm.selectedCorners = $$v
                },
                expression: "selectedCorners",
              },
            },
            [
              _c(
                "b-checkbox",
                { attrs: { value: "topLeft" } },
                [_c("b-icon", { attrs: { icon: "arrow-up-left" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-checkbox",
                { attrs: { value: "topRight" } },
                [_c("b-icon", { attrs: { icon: "arrow-up-right" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-checkbox",
                { attrs: { value: "bottomRight" } },
                [_c("b-icon", { attrs: { icon: "arrow-down-right" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-checkbox",
                { attrs: { value: "bottomLeft" } },
                [_c("b-icon", { attrs: { icon: "arrow-down-left" } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Angle", "label-cols": "4" } },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: {
                          max: "360",
                          min: "0",
                          number: "",
                          type: "number",
                        },
                        on: { input: _vm.redraw },
                        model: {
                          value: _vm.editor.angle,
                          callback: function ($$v) {
                            _vm.$set(_vm.editor, "angle", $$v)
                          },
                          expression: "editor.angle",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: { max: "360", min: "0", number: "", type: "range" },
                on: { input: _vm.redraw },
                model: {
                  value: _vm.editor.angle,
                  callback: function ($$v) {
                    _vm.$set(_vm.editor, "angle", $$v)
                  },
                  expression: "editor.angle",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Transparency", "label-cols": "4" } },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: {
                          max: "100",
                          min: "0",
                          number: "",
                          type: "number",
                        },
                        on: { input: _vm.redraw },
                        model: {
                          value: _vm.editor.transparency,
                          callback: function ($$v) {
                            _vm.$set(_vm.editor, "transparency", $$v)
                          },
                          expression: "editor.transparency",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: { max: "100", min: "0", number: "", type: "range" },
                on: { input: _vm.redraw },
                model: {
                  value: _vm.editor.transparency,
                  callback: function ($$v) {
                    _vm.$set(_vm.editor, "transparency", $$v)
                  },
                  expression: "editor.transparency",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isGradientSelected
        ? _c(
            "b-form-group",
            { attrs: { label: "Amplitude", "label-cols": "4" } },
            [
              _c(
                "b-input-group",
                [
                  _c("b-input", {
                    attrs: { max: "75", min: "0", type: "range" },
                    on: { input: _vm.redraw },
                    model: {
                      value: _vm.editor.amplitude,
                      callback: function ($$v) {
                        _vm.$set(_vm.editor, "amplitude", $$v)
                      },
                      expression: "editor.amplitude",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isGradientSelected
        ? _c(
            "b-form-group",
            { attrs: { label: "Frequency", "label-cols": "4" } },
            [
              _c(
                "b-input-group",
                [
                  _c("b-input", {
                    attrs: { max: "100", min: "1", type: "range" },
                    on: { input: _vm.redraw },
                    model: {
                      value: _vm.editor.frequency,
                      callback: function ($$v) {
                        _vm.$set(_vm.editor, "frequency", $$v)
                      },
                      expression: "editor.frequency",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Gaussian blur", "label-cols": "4" } },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: {
                          max: "200",
                          min: "0",
                          number: "",
                          type: "number",
                        },
                        on: { input: _vm.redraw },
                        model: {
                          value: _vm.editor.blur,
                          callback: function ($$v) {
                            _vm.$set(_vm.editor, "blur", $$v)
                          },
                          expression: "editor.blur",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: { max: "200", min: "0", type: "range" },
                on: { input: _vm.redraw },
                model: {
                  value: _vm.editor.blur,
                  callback: function ($$v) {
                    _vm.$set(_vm.editor, "blur", $$v)
                  },
                  expression: "editor.blur",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Colors", "label-cols": "4" } },
        [
          _c(
            "b-input-group",
            [
              _c("b-input", {
                attrs: { max: "5", min: "1", type: "range" },
                on: { input: _vm.colorsNumChange },
                model: {
                  value: _vm.colorsNum,
                  callback: function ($$v) {
                    _vm.colorsNum = $$v
                  },
                  expression: "colorsNum",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { "label-cols": false } },
        [
          !(_vm.editor.radialGradient || _vm.editor.linearGradient)
            ? _c(
                "div",
                { staticClass: "d-inline-flex" },
                _vm._l(_vm.editor.colors, function (color, index) {
                  return _c("b-input", {
                    key: index,
                    staticClass: "color-fill-item m-1",
                    attrs: { debounce: "50", type: "color" },
                    on: { update: _vm.redraw },
                    model: {
                      value: _vm.editor.colors[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.editor.colors, index, $$v)
                      },
                      expression: "editor.colors[index]",
                    },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.editor.radialGradient || _vm.editor.linearGradient
            ? _c("ColorStop", {
                attrs: {
                  "active-color": _vm.activeColor,
                  editor: _vm.editor,
                  "show-color-picker": _vm.showColorPicker,
                  tabindex: "0",
                },
                on: {
                  colorStepsInput: _vm.updateStepOfColor,
                  colorStopClicked: _vm.setActiveColor,
                  colorUpdated: _vm.updateColor,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }