var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    { attrs: { label: "Assign to folder" } },
    [
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            [
              _c("Multiselect", {
                attrs: {
                  label: "name",
                  "track-by": "id",
                  options: _vm.folders,
                  placeholder: "None",
                  "select-label": "",
                  "deselect-label": "",
                },
                model: {
                  value: _vm.folder,
                  callback: function ($$v) {
                    _vm.folder = $$v
                  },
                  expression: "folder",
                },
              }),
              _vm._v(" "),
              _c("input", {
                attrs: { type: "hidden", name: "folder_id" },
                domProps: { value: _vm.folder?.id },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.createLayoutFolderModal",
                      modifiers: { createLayoutFolderModal: true },
                    },
                  ],
                  attrs: { variant: "primary" },
                },
                [_vm._v("\n        New Folder…\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("CreateLayoutFolderModal", { on: { ok: _vm.selectFolder } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }