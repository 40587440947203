<template>
  <div
    class="c-main-view"
    @click.self="clearSelection"
  >
    <TopBar />
    <div
      class="c-main-view__container"
      @click.self="clearSelection"
    >
      <component
        :is="view"
        :is-drag-and-drop-available="false"
      />
      <div
        v-if="isListLoading"
        class="c-main-view__spinner-overlay"
      >
        <b-spinner
          class="c-main-view__spinner-overlay__spinner"
          variant="success"
          label="Spinning"
        />
      </div>
    </div>
    <PaginationComponent />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import TopBar from "./TopBar.vue";
import GridView from "./browser/views/GridView.vue";
import PaginationComponent from "./PaginationComponent.vue";

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('fileBrowserProductSelector')

export default {
  name: "MainView",
  components: {
    PaginationComponent,
    TopBar
  },
  data: () => ({
    isDragging: false
  }),
  computed: {
    ...mapGetters(['isListLoading', 'selectedCount', 'currentFolderId']),
    view() {
      return GridView
    },
  },
  watch: {
    currentFolderId: function () {
      this.fetchData()
    }
  },
  mounted() {
    this.preventDefaultDropEvents();
  },
  methods: {
    ...mapActions(['clearSelection', 'fetchData']),
    preventDefaultDropEvents() {
      const events = ['dragenter', 'dragover', 'dragleave', 'drop']
      events.forEach((eventName) => {
        document.body.addEventListener(eventName, e => e.preventDefault())
      })
    },
  }
}
</script>

<style scoped lang="scss">
.c-main-view {
  border: 1px solid #727272;
  border-radius: 20px;
  padding: 20px;
  position: relative;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  &__spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.5;

    &__spinner {
      position: absolute;
      top: calc(50% - 32px/2);
      left: calc(50% - 32px/2);
    }
  }

  &__drag-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #a0a0a0;
    opacity: 0.15;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #727272;

    &--text {
      color: black;
      font-size: 36px;
    }
  }
}
</style>
