var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-button-toolbar",
        [
          _vm.mode === _vm.MODES.VIEW && _vm.isAnySelected
            ? _c(
                "b-button-group",
                { staticClass: "mr-3" },
                [
                  _c(
                    "ButtonOutline",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { title: "Show on canvas" },
                      on: { click: _vm.showOnCanvas },
                    },
                    [_c("i", { staticClass: "fas fa-crosshairs" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "ButtonOutline",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { title: "Replace" },
                    },
                    [_c("i", { staticClass: "fas fa-redo-alt" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "ButtonOutline",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { title: "Delete" },
                    },
                    [_c("i", { staticClass: "fas fa-trash" })]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.mode === _vm.MODES.REPLACE
            ? _c(
                "b-button-group",
                { staticClass: "mr-3" },
                [
                  _c(
                    "ButtonOutline",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { title: "Cancel" },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "x-circle-fill", "aria-hidden": "true" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isAnySelected
                    ? _c(
                        "ButtonOutline",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          attrs: { title: "Select" },
                        },
                        [
                          _c("b-icon", {
                            attrs: { icon: "check-lg", "aria-hidden": "true" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-button-group",
            { staticClass: "mr-3" },
            [
              _vm.mode > _vm.MODES.VIEW
                ? _c(
                    "ButtonOutline",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { title: "Home" },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "house-fill", "aria-hidden": "true" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.mode > _vm.MODES.VIEW
                ? _c(
                    "ButtonOutline",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { title: "Folder view" },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "folder-check", "aria-hidden": "true" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ButtonOutline",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  class: _vm.isGroupActive ? "active" : "",
                  attrs: { title: "Grouped view" },
                  on: { click: _vm.toggleGroupMode },
                },
                [_c("i", { staticClass: "fas fa-layer-group" })]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button-group",
            [
              _c(
                "ButtonOutline",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  attrs: { title: "Save" },
                },
                [_c("i", { staticClass: "fas fa-save" })]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }