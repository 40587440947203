import Template from '@/banner_template/Template';
import { updateObject } from '../helpers';
import {
  setDataForExistFields
} from "@frontend/group/modules/copy-and-resize/handlers/resize-proportional-template-handler/set-data-for-exist-fields";
import { FIELD_TYPE_MULTI_LINE_TEXT } from '@frontend/constants/type-fields-of-template';
import {
  handleObjectsIntersections
} from '@frontend/group/modules/copy-and-resize/handlers/resize-like-grid-handler/handle-objects-intersections';

const SHOULD_CLOSED_TO_THE_BOTTOM = [ 'legal', 'claims' ]

const COEFFICIENT_FOR_BROAD_SCALE = 0.2

const PADDING_TOP_KEY = 'TOP'
const PADDING_LEFT_KEY = 'LEFT'
const PADDING_RIGHT_KEY = 'RIGHT'
const PADDING_BOTTOM_KEY = 'BOTTOM'

const PADDING = {
  [PADDING_RIGHT_KEY]: 0.03,
  [PADDING_BOTTOM_KEY]: 0.03,
  [PADDING_TOP_KEY]: 0.03,
  [PADDING_LEFT_KEY]: 0.03,
}

const PERCENTS_OF_HEIGHT_TO_ENABLE_TEXT_LINES_REDUCING = 0.7
const MINIMUM_OF_TEXT_TO_ENABLE_TEXT_LINES_REDUCING = 2;
const COEFFICIENT_TO_APPLY_AFTER_TEXT_LINES_CONCAT = 1.03;

const applyPadding = ({ dimension, object, x, y, scaleX, scaleY, originalPoints, canvas }) => {
  let newX = x, newY = y, newScaleX = scaleX, newScaleY = scaleY;

  for (const paddingDirection in PADDING) {
    let padding = 0
    let spaceLeft = 0
    switch (paddingDirection) {
      case PADDING_LEFT_KEY:
        padding = dimension.width * PADDING[paddingDirection]
        newX = newX >= padding ? newX : padding
        break;
      case PADDING_TOP_KEY:
        padding = dimension.height * PADDING[paddingDirection]
        newY = newY >= padding ? newY : padding
        break;
      case PADDING_RIGHT_KEY:
        padding = dimension.width * PADDING[paddingDirection]
        spaceLeft = dimension.width - (newX + object.width * scaleX)
        newX = spaceLeft >= padding ? newX : (newX - padding - spaceLeft)
        break;
      case PADDING_BOTTOM_KEY:
        padding = dimension.height * PADDING[paddingDirection]
        spaceLeft = dimension.height - (newY + object.height * scaleY)
        newY = spaceLeft >= padding ? newY : (newY - padding - spaceLeft)
        break;
    }
  }

  const paddingY = newY - y;
  const paddingX = newX - x;

  if (
    (newY + object.height * scaleY > dimension.height - paddingY) &&
    (originalPoints.y + object.getScaledHeight() <= canvas.height)
  ) {
    const desiredHeight = dimension.height - newY - paddingY;
    const downscaleFactor = desiredHeight / (object.height * scaleY);

    newScaleY *= downscaleFactor;
    newScaleX *= downscaleFactor;
  }

  if (
    (newX + object.width * scaleX > dimension.width - paddingX) &&
    (originalPoints.x + object.getScaledWidth() <= canvas.width)
  ) {
    const desiredWidth = dimension.width - newX - paddingX;
    const downscaleFactor = desiredWidth / (object.width * scaleX);

    newScaleY *= downscaleFactor;
    newScaleX *= downscaleFactor;
  }


  return { x: newX, y: newY, scaleX: newScaleX, scaleY: newScaleY }
}

const getObjectScaleFactor = ({object, dimension, canvas}) => {
    const orWidth = object.getScaledWidth() / canvas.width * dimension.width / object.getScaledWidth();
    const orHeight = object.getScaledHeight() / canvas.height * dimension.height / object.getScaledHeight();
    const scale = Math.min(orWidth, orHeight);

    // apply percentage of min dimension and difference between dimensions
    return scale + Math.abs(orHeight - orWidth) * COEFFICIENT_FOR_BROAD_SCALE;
}

const sortObjectsByXCoordinate = (objects, origins) => {
  return objects.sort((a, b) => {
    const aPoints = a.getPointByOrigin(...origins);
    const bPoints = b.getPointByOrigin(...origins);

    if (aPoints.x < bPoints.x) return -1;
    if (aPoints.x > bPoints.x) return 1;

    return 0;
  });
}

export const resizeLikeGridHandler = ({
  isCopyObject,
  dimension,
  mainTemplate,
  canvas,
  isUseDestinationTemplateFields,
  objects,
  templateSettings,
}) => {
  const origins = ['left', 'top'];
  const template = new Template(dimension?.template || {});
  const rsW = dimension.width / canvas.width;
  const rsH = dimension.height / canvas.height;

  const allObjects = isUseDestinationTemplateFields
    ? setDataForExistFields({
      objects,
      template,
      mainTemplate,
      isCopyObject,
      templateSettings
    })
    : objects;
  const sortedObjects = sortObjectsByXCoordinate(allObjects, origins);

  const resizedObjects = [];

  for (const _object of sortedObjects) {
    const object = isCopyObject ? _.cloneDeep(_object) : _object;
    const originalPoints = object.getPointByOrigin(...origins);
    const scale = getObjectScaleFactor({ object, dimension, canvas });

    let scaleX = object.scaleX * scale;
    let scaleY = object.scaleY * scale;

    const originalOffsetXPercent = originalPoints.x / canvas.width;
    const originalOffsetYPercent = originalPoints.y / canvas.height;

    let newOffsetX = dimension.width * originalOffsetXPercent;
    let newOffsetY = dimension.height * originalOffsetYPercent;

    // Adjust newOffsetX if the element is going beyond the right edge
    if (
        (Math.round(newOffsetX + object.width * scaleX) > dimension.width) &&
        (object.templateField?.type === FIELD_TYPE_MULTI_LINE_TEXT || (Math.round(originalPoints.x + object.getScaledWidth()) <= canvas.width))
    ) {
        newOffsetX = dimension.width - object.width * scaleX;
    }

    if (
        (newOffsetY < 0) 
        && (object.templateField?.type === FIELD_TYPE_MULTI_LINE_TEXT || originalPoints.y >= 0)
    ) {
        newOffsetY = dimension.height - object.height * scaleY;
    }

    // If element's offset X is less than 0, scale it down to fit within dimension.width
    if (newOffsetX < 0 && originalPoints.x >= 0) {
      const maxAllowedWidth = dimension.width;
      const currentWidth = object.width * scaleX;

      if (currentWidth > maxAllowedWidth) {
        if (object.templateField?.type === FIELD_TYPE_MULTI_LINE_TEXT) {
          object.width = maxAllowedWidth / scaleX;
        } else {
          const downscaleFactor = maxAllowedWidth / currentWidth;
          scaleX *= downscaleFactor;
          scaleY *= downscaleFactor;
        }

        newOffsetX = 0;
      }
    }

    if (
      (newOffsetY + object.height * scaleY > dimension.height) &&
      (originalPoints.y + object.getScaledHeight() <= canvas.height)
    ) {
      const excessHeight = (newOffsetY + object.height * scaleY) - dimension.height;

      if (newOffsetY - excessHeight < 0) {
        newOffsetY = 0;
        const desiredHeight = dimension.height - newOffsetY;
        const downscaleFactor = desiredHeight / (object.height * scaleY);

        scaleY *= downscaleFactor;
        scaleX *= downscaleFactor;
      } else {
        newOffsetY -= excessHeight;
      }
    }

    if (
      object.templateField?.type === FIELD_TYPE_MULTI_LINE_TEXT
      && SHOULD_CLOSED_TO_THE_BOTTOM.some(inclusion => object?.templateField.name.toLowerCase().includes(inclusion))
    ) {
      newOffsetY = dimension.height - object.height * scaleY
    }

    // text lines reducing
    if (
      object.templateField?.type === FIELD_TYPE_MULTI_LINE_TEXT
      && object.height * scaleY / dimension.height > PERCENTS_OF_HEIGHT_TO_ENABLE_TEXT_LINES_REDUCING
      && object.textLines.length > MINIMUM_OF_TEXT_TO_ENABLE_TEXT_LINES_REDUCING
    ) {

      const lastWidth = object.measureLine([object._textLines.length - 1]).width
      const sLastWidth = object.measureLine([object._textLines.length - 2]).width
      object._textLines = object._textLines.reduceRight((acc, current, i) => {
        if ((object._textLines.length - 2) === i) {
          acc[0] = [...current, ' ', ...acc[0]]
        } else {
          acc.push(current)
        }
        return acc
      }, []).reverse()

      object.width = (lastWidth + sLastWidth) * COEFFICIENT_TO_APPLY_AFTER_TEXT_LINES_CONCAT
    }

    // padding
    const { x, y, scaleX: updatedScaleX, scaleY: updatedScaleY } = applyPadding({
      dimension,
      object,
      x: newOffsetX,
      y: newOffsetY,
      scaleX,
      scaleY,
      canvas,
      originalPoints
    });

    newOffsetX = x;
    newOffsetY = y;
    scaleX = updatedScaleX;
    scaleY = updatedScaleY;

    object.set({
      scaleX,
      scaleY,
    });

    object.setPositionByOrigin({
      x: newOffsetX,
      y: newOffsetY
    }, ...origins);

    object.setCoords();

    if (object.templateField?.type === FIELD_TYPE_MULTI_LINE_TEXT) {
      object.initDimensions();
    }

    updateObject(object, { x: rsW, y: rsH });
    resizedObjects.push(object);
  }
  
  const objectsToCheckIntersections = resizedObjects.filter(_object => !!_object.templateField);

  handleObjectsIntersections({ objects: objectsToCheckIntersections, dimension, rsW, rsH });
}
