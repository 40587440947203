var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-item-overlay" }, [
    _c(
      "div",
      {
        staticClass: "c-item-overlay__item add-item",
        on: { click: _vm.addItem },
      },
      [
        _c("b-icon", {
          staticStyle: {
            color: "#479382",
            stroke: "#fff",
            "stroke-width": "1.5px",
          },
          attrs: { icon: "plus-circle-fill", "font-scale": "1.3" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "c-item-overlay__item count" }, [
      _c("span", { staticClass: "count-text" }, [
        _vm._v("\n      " + _vm._s(_vm.count) + "\n    "),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "c-item-overlay__item delete-item",
        on: { click: _vm.decreaseCount },
      },
      [
        _c("b-icon", {
          staticStyle: {
            color: "#479382",
            stroke: "#fff",
            "stroke-width": "1.5px",
          },
          attrs: { icon: "dash-circle-fill", "font-scale": "1.3" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }