<template>
  <div>
    <Multiselect
      v-model="selectedFolder"
      label="name"
      track-by="id"
      :options="items"
      :show-labels="true"
      @input="emitSelection"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
name: 'SelectFolder',
components: {
  Multiselect
},
props: {
    items: {
    type: Array,
    required: true
    }
},
data() {
    return {
    selectedFolder: null
    };
},
methods: {
    emitSelection() {
    this.$emit('update:selectedFolder', this.selectedFolder.id);
    }
}
};
</script>

<style scoped>

</style>
  
