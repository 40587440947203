import { dataURIToBlob } from "../helpers";

export default {
  getTemplates (params = {}) {
    return axios.get('/video/templates/all', { params })
  },

  deleteTemplate (id) {
    return axios.delete(`/video/templates/${id}`)
  },

  attachTagToTemplate (id, tag) {
    return axios.post(`/video/templates/${id}/tag`, { tag })
  },

  detachTagFromTemplate (id, tagId) {
    return axios.delete(`/video/templates/${id}/tag/${tagId}`)
  },

  updateThumbnails (id, thumbnails) {
    const req = new FormData()
    thumbnails.forEach(({Image, Scene}) => {
      req.append('thumbnails[]', dataURIToBlob(Image))
      req.append('scenes[]', Scene)
    })
    return axios.post(`/video/templates/${id}/thumbnails`, req)
  },
  getTemplatesByCustomer(id) {
    return axios.post(`/banner/get-templates/${id}`)
  },
  createTemplate(customerId, layoutId, payload) {
    return axios.post(`/banner/${customerId}/group/${layoutId}/template`, payload);
  }
}