var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-item-overlay" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: { hover: true },
          },
        ],
        staticClass: "c-item-overlay__item delete-item",
        attrs: { title: "Replace" },
        on: { click: _vm.decreaseCount },
      },
      [
        _c("i", {
          staticClass: "fas fa-redo-alt",
          staticStyle: { color: "#0d6854" },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: { hover: true },
          },
        ],
        staticClass: "c-item-overlay__item add-item",
        attrs: { title: "Delete" },
        on: { click: _vm.addItem },
      },
      [
        _c("i", {
          staticClass: "fas fa-trash",
          staticStyle: { color: "#0d6854" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }