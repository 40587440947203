import _ from "lodash";
import Requests from "../../services/api/layout-folder";
import {createModal} from "../../components/modules/layout-folder-browser/modals/action-creater";

const initState = () => ({
    isFileBrowserModalOpened: false,
    fileBrowserModalType: '',
    multiselectEnabled: false,
    isListLoading: false,
    items: [],
    progressingItems: [],
    selected: [],
    currentFolderId: null,
    currentFolderName: '',
    pagination: {
        currentPage: 1,
        pageSize: 12,
        totalCount: 0,
        lastPage: 1,
    },
    searchQuery: '',
    modal: {
        type: '',
        payload: null,
    },
    breadcrumbs: [],
})

export const MUTATIONS = {
    SET_ITEMS: 'SET_ITEMS',
    UPDATE_PROGRESS_PERCENTAGE: 'UPDATE_PROGRESS_PERCENTAGE',
    SET_LIST_LOADING: 'SET_LIST_LOADING',
    SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
    SET_LAST_PAGE: 'SET_LAST_PAGE',
    SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
    SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
    TOGGLE_SELECTED_ITEMS: 'TOGGLE_SELECTED_ITEMS',
    CLEAR_SELECTION: 'CLEAR_SELECTION',
    SET_MODAL: 'SET_MODAL',
    FINISH_PROGRESSING: 'FINISH_PROGRESSING',
    UPDATE_ITEM_NAME: 'UPDATE_ITEM_NAME',
    SET_BREADCRUMBS: 'SET_BREADCRUMBS',
    SET_SHOW_ONLY_FOLDERS: 'SET_SHOW_ONLY_FOLDERS',
    SET_CURRENT_FOLDER_ID: 'SET_CURRENT_FOLDER_ID',
    SET_CURRENT_FOLDER_NAME: 'SET_CURRENT_FOLDER_NAME',
    SET_MULTISELECT_ENABLED: 'SET_MULTISELECT_ENABLED',
    SET_IS_FILE_BROWSER_MODAL_OPENED: 'SET_IS_FILE_BROWSER_MODAL_OPENED',
    SET_FILE_BROWSER_MODAL_TYPE: 'SET_FILE_BROWSER_MODAL_TYPE',
    SET_SELECTION: 'SET_SELECTION',
}

export default {
    namespaced: true,
    state: initState(),
    getters: {
        isListLoading: state => state.isListLoading,
        items: state => state.items,
        selected: state => state.selected,
        firstSelectedItem: state => state.selected.length && state.items.find(item => item.id === state.selected[0]),
        selectedItems: state => state.selected.map(selectedId => state.items.find(item => item.id === selectedId)),
        selectedFolders: state => state.items.filter(item => item.type === 'folder' && state.selected.includes(item.id)).map(item => item.id),
        totalCount: state => state.pagination.totalCount,
        currentPage: state => state.pagination.currentPage,
        searchQuery: state => state.searchQuery,
        isSelected: state => item => state.selected.includes(item.id),
        selectedCount: state => state.selected.length,
        isAnySelected: state => !!state.selected.length,
        modal: state => state.modal,
        breadcrumbs: state => state.breadcrumbs,
        currentFolderId: state => state.currentFolderId,
        currentFolderName: state => state.currentFolderName,
        multiselectEnabled: state => state.multiselectEnabled,
        isFileBrowserModalOpened: state => state.isFileBrowserModalOpened,
        fileBrowserModalType: state => state.fileBrowserModalType,
    },
    mutations: {
        [MUTATIONS.SET_LIST_LOADING](state, payload) {
            state.isListLoading = payload;
        },
        [MUTATIONS.SET_ITEMS](state, payload) {
            state.items = payload;
        },
        [MUTATIONS.SET_TOTAL_COUNT](state, payload) {
            state.pagination.totalCount = payload
        },
        [MUTATIONS.SET_LAST_PAGE](state, payload) {
            state.pagination.lastPage = payload
        },
        [MUTATIONS.SET_CURRENT_PAGE](state, payload) {
          state.pagination.currentPage = payload
        },
        [MUTATIONS.SET_SEARCH_QUERY](state, payload) {
          state.searchQuery = payload
        },
        [MUTATIONS.TOGGLE_SELECTED_ITEMS](state, id) {
            if (state.selected.includes(id)) {
                state.selected.splice(state.selected.indexOf(id), 1)
            } else {
                state.selected.push(id)
            }
        },
        [MUTATIONS.CLEAR_SELECTION](state) {
            state.selected = [];
        },
        [MUTATIONS.SET_SELECTION](state, payload) {
            state.selected = payload
        },
        [MUTATIONS.SET_MODAL](state, payload) {
            state.modal = payload;
        },
        [MUTATIONS.UPDATE_ITEM_NAME](state, { id, name }) {
            const item = state.items.find(item => item.id === id);

            if (!item) {
                return;
            }

            item.name = name;
        },
        [MUTATIONS.SET_BREADCRUMBS](state, payload) {
            state.breadcrumbs = payload;
        },
        [MUTATIONS.SET_SHOW_ONLY_FOLDERS] (state, payload) {
            state.showOnlyFolders = payload
        },
        [MUTATIONS.SET_CURRENT_FOLDER_ID] (state, payload) {
          state.currentFolderId = payload
        },
        [MUTATIONS.SET_CURRENT_FOLDER_NAME] (state, payload) {
          state.currentFolderName = payload
        },
        [MUTATIONS.SET_MULTISELECT_ENABLED] (state, payload) {
          state.multiselectEnabled = payload
        },
        [MUTATIONS.SET_IS_FILE_BROWSER_MODAL_OPENED] (state, payload) {
          state.isFileBrowserModalOpened = payload
        },
        [MUTATIONS.SET_FILE_BROWSER_MODAL_TYPE] (state, payload) {
          state.fileBrowserModalType = payload
        }
    },
    actions: {
        async fetchList({ commit, state }) {
          if (!state.isListLoading) {
            commit(MUTATIONS.SET_LIST_LOADING, true)

            const { data } = await Requests.getFolders({
              pageSize: state.pagination.pageSize,
              pageNumber: state.pagination.currentPage,
              searchKey: state.searchQuery,
              parentId: state.currentFolderId,
            })
            commit(MUTATIONS.SET_ITEMS, data.items)
            commit(MUTATIONS.SET_TOTAL_COUNT, data.totalCount)
            commit(MUTATIONS.SET_LAST_PAGE, data.last_page)
            commit(MUTATIONS.CLEAR_SELECTION)
            commit(MUTATIONS.SET_LIST_LOADING, false)
          }
        },
        async addFolder({ dispatch, commit, state }) {
            await Requests.createFolder(state.currentFolderId);
            await dispatch('fetchList');
            await dispatch('setCurrentPage', state.pagination.lastPage);
        },
        async deleteById({ commit, dispatch, state }, item) {
            try {
                const wasOnLastPage = state.pagination.currentPage === state.pagination.lastPage;
                
                await Requests.deleteFolder(item.id);
                await dispatch('fetchList');
                if (wasOnLastPage) {
                  await dispatch('setCurrentPage', state.pagination.lastPage);
                }
                window.livewire.emit('updatedGridLayoutsIds', []);
            } finally {
                commit(MUTATIONS.SET_MODAL, createModal())
            }
        },
        async editFile({ commit }, { id, name, parent_id }) {
            try {
                await Requests.editFolder(id, name);
                commit(MUTATIONS.UPDATE_ITEM_NAME, { id, name })
            } catch (error) {
                toastr.error('Something went wrong on updating name')
            } finally {
                commit(MUTATIONS.SET_MODAL, createModal());
            }
        },
        async fetchBreadcrumbs({ commit, state }){
            if (!state.currentFolderId) {
                return commit(MUTATIONS.SET_BREADCRUMBS, []);
            }
            try {
                const { data } = await Requests.getBreadcrumbs(state.currentFolderId);
                
                const breadcrumbs = data.map(layoutFolder => {
                  return {
                    id: layoutFolder.id,
                    text: layoutFolder.name
                  };
                })
                
                commit(MUTATIONS.SET_BREADCRUMBS, breadcrumbs);
            } catch (error) {
              return commit(MUTATIONS.SET_BREADCRUMBS, []);
            }
        },
        clearSelection ({ commit }) {
            commit(MUTATIONS.CLEAR_SELECTION)
        },
        selectAll ({ commit, state }) {
            commit(MUTATIONS.SET_SELECTION, _.filter(_.map(state.items, 'id')))
        },
        setShowOnlyFolders({ commit }, payload) {
            commit(MUTATIONS.SET_SHOW_ONLY_FOLDERS, payload)
        },
        setCurrentFolder({ commit, state }, payload) {
          commit(MUTATIONS.SET_CURRENT_PAGE, 1)
          commit(MUTATIONS.SET_SEARCH_QUERY, '')
          commit(MUTATIONS.SET_CURRENT_FOLDER_ID, payload?.id || null)
          commit(MUTATIONS.SET_CURRENT_FOLDER_NAME, payload?.name || null)
        },
        setCurrentPage({ commit, dispatch, state }, payload) {
          commit(MUTATIONS.SET_CURRENT_PAGE, payload)
          dispatch('fetchList')
        },
        setSearchQuery({ commit, dispatch, state }, payload) {
          commit(MUTATIONS.SET_CURRENT_PAGE, 1)
          commit(MUTATIONS.SET_SEARCH_QUERY, payload)
          dispatch('fetchList')
        },
        disableMultiselect({ commit }) {
          commit(MUTATIONS.SET_MULTISELECT_ENABLED, false)
        },
        setIsFileBrowserModalOpened({ commit, state }, payload) {
          commit(MUTATIONS.SET_IS_FILE_BROWSER_MODAL_OPENED, payload);
        },
        openFileBrowserModal({ commit, state }, payload) {
          commit(MUTATIONS.CLEAR_SELECTION)
          commit(MUTATIONS.SET_CURRENT_PAGE, 1)
          commit(MUTATIONS.SET_SEARCH_QUERY, '')
          
          if (payload.type === 'Copy') {
            commit(MUTATIONS.SET_MULTISELECT_ENABLED, true);
          }
          
          commit(MUTATIONS.SET_IS_FILE_BROWSER_MODAL_OPENED, true);
          commit(MUTATIONS.SET_FILE_BROWSER_MODAL_TYPE, payload.type);
        },
        async moveItemsToFolder({ commit, dispatch, state }, payload ) {
          const draggedLayouts = payload.items.filter(item => item.type === 'layout')
          const draggedFolders = payload.items.filter(item => item.type === 'folder');

          if (draggedLayouts.length > 0) {
            await Requests.moveLayoutsToFolder(payload.folderId, draggedLayouts.map(layout => layout.grid_layout_id));
          }

          if (draggedFolders.length > 0) {
            await Requests.moveFoldersToFolder(payload.folderId, draggedFolders.map(folder => folder.id));
          }
          
          dispatch('fetchList');
        },
        async archiveLayout({ commit, dispatch, state }, item) {
          await Requests.archiveLayout(item.customer_id, item.grid_layout_id);
          dispatch('fetchList');
        },
        async removeLayout({ commit, dispatch, state }, item) {
          await Requests.deleteFolderLayouts(state.currentFolderId, [item.grid_layout_id]);
          dispatch('fetchList');
        }
    }
}
