<template>
  <b-modal
    id="export-templates-modal"
    title="Export"
    centered
  >
    <div class="form-row">
      <div class="col-md-2">
        <label>Format</label>
      </div>
      <div class="col-md-10 form-group">
        <b-form-select
          v-model="selectedFormat"
          :options="formats"
          class="form-control"
        />
      </div>
    </div>
    <template v-if="selectedFormat === 'gif' || selectedFormat === 'pdf'">
      <div class="form-row container-downloadable-groups">
        <div class="col-md-2">
          <label>Group</label>
        </div>
        <div class="col-md-10 form-group">
          <MultipleSelectJquery
            v-model="selectedGroupInstances"
            multiple
            :data="groupsInstances"
          />
        </div>
      </div>

      <div
        v-if="selectedFormat === 'pdf'"
        class="form-row"
      >
        <div class="col-md-2" />
        <div class="col-md-10 form-group">
          <label>
            <input
              v-model="createOneFilePerGroup"
              type="checkbox"
            >
            Create one file for each group
          </label>
        </div>
      </div>
      
      <div
        v-if="selectedFormat === 'pdf'"
        class="form-row"
      >
        <div class="col-md-2" />
        <div class="col-md-10 form-group">
          <label>
            <input
              v-model="createPageForEachTemplate"
              type="checkbox"
            >
            Create one page for each template in the group
          </label>
        </div>
      </div>

      <div
        v-if="selectedFormat === 'pdf' && selectedGroupInstances.length == 1"
        class="form-row"
      >
        <div class="col-md-2" />
        <div class="col-md-10 form-group">
          <label>
            <input
              v-model="getDirectLinktoFile"
              type="checkbox"
            >
            Get direct link to the file
          </label>
        </div>
      </div>

      <div 
        v-if="selectedFormat === 'pdf'"
        class="form-row -groups"
      >
        <div class="col-md-2">
          <label>Save to folder</label>
        </div>
        <div class="col-md-10 form-group">
          <SelectFolder
            :items="folders"
            @update:selectedFolder="handleSelection"
          />
        </div>
      </div>
      <div class="form-row container-downloadable-single-template">
        <div class="col-md-2">
          <label>Single Templates</label>
        </div>
        <div class="col-md-10 form-group">
          <MultipleSelectJquery
            v-model="selectedSingleInstances"
            multiple
            :data="singleInstances"
          />
        </div>
      </div>
    </template>

    <template v-else>
      <div class="form-row container-downloadable-templates">
        <div class="col-md-2">
          <label>Download</label>
        </div>
        <div class="col-md-10 form-group">
          <Multiselect
            v-model="selectedInstances"
            :options="instancesOption"
            track-by="instance_id"
            label="name"
            placeholder="Select instances..."
            :close-on-select="false"
            multiple
          />
        </div>
      </div>
      <div 
        v-if="selectedFormat === 'pptx'"
        class="form-row -groups"
      >
        <div class="col-md-2">
          <label>Save to folder</label>
        </div>
        <div class="col-md-10 form-group">
          <SelectFolder
            :items="folders"
            @update:selectedFolder="handleSelection"
          />
        </div>
      </div>
    </template>
    
    <div
      v-if="['psd', 'jpg_from_psd', 'psd_and_jpg'].includes(selectedFormat)"
      class="form-row"
    >
      <div class="col-md-2" />
      <div class="col-md-10 form-group">
        <label>
          <input
            v-model="downloadInBackground"
            type="checkbox"
          >
          Download in background
        </label>
      </div>
    </div>      

    <div
      v-if="['psd', 'jpg', 'png', 'jpg_from_psd'].includes(selectedFormat)"
      class="form-row container-platforms"
    >
      <div class="col-md-2">
        <label>DPI</label>
      </div>
      <div class="col-md-10 form-group">
        <b-form-select
          v-model.number="selectedDpi"
          class="form-control"
          @change="dpi = selectedDpi"
        >
          <option value="72">
            72
          </option>
          <option value="75">
            75
          </option>
          <option value="100">
            100
          </option>
          <option value="150">
            150
          </option>
          <option value="200">
            200
          </option>
          <option value="300">
            300
          </option>
          <option value="0">
            Custom
          </option>
        </b-form-select>
        <div class="my-2" />
        <b-form-input
          v-if="!selectedDpi"
          v-model.number="dpi"
          type="number"
        />
      </div>
    </div>

    <div
      v-if="selectedFormat === 'html'"
      class="form-row container-platforms"
    >
      <div class="col-md-2">
        <label>Platform</label>
      </div>
      <div class="col-md-10 form-group">
        <b-form-select
          v-model="selectedPlatform"
          class="form-control"
        >
          <option value="">
            None
          </option>
          <option value="ttd">
            The Trade Desk (TTD)
          </option>
        </b-form-select>
      </div>
    </div>
    <template #modal-footer>
      <b-button
        variant="primary"
        :disabled="isSaving"
        @click="onSave"
      >
        <div v-if="isSaving">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Exporting...
        </div>
        <span v-else>Export</span>
      </b-button>
      <b-button
        variant="secondary"
        @click="onClose"
      >
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { exportTemplatesEventBroker, EVENTS } from '../../../group/modules/export-templates/event-broker';
import MultipleSelectJquery from '../../common/atoms/MultipleSelectJquery.vue';
import SelectFolder from '../../common/atoms/SelectFolder.vue';
import Multiselect from "vue-multiselect";
import {bus} from '../../../app'
import {getDefaultFieldName} from "@frontend/services/helpers/scene-creation";


export default {
  name: 'ExportTemplatesModal',
  components: {
    MultipleSelectJquery,
    Multiselect,
    SelectFolder
  },
  data() {
    return {
      isSaving: false,
      formats: [
        {
          text: 'JPG',
          value: 'jpg'
        },
        {
          text: 'PNG',
          value: 'png'
        },
        {
          text: 'GIF',
          value: 'gif'
        },
        {
          text: 'HTML',
          value: 'html'
        },
        {
          text: 'PDF',
          value: 'pdf'
        },
        {
          text: 'PPTX',
          value: 'pptx'
        },
        {
          text: 'Feed',
          value: 'feed'
        },
        {
          text: 'PSD',
          value: 'psd'
        },
        {
          text: 'TIFF',
          value: 'tiff'
        },
        {
          text: 'JPG from PSD',
          value: 'jpg_from_psd'
        },
        {
          text: 'PSD and JPG',
          value: 'psd_and_jpg'
        },
      ],
      dpi: 72,
      selectedDpi: 72,
      selectedFormat: 'jpg',
      selectedPlatform: '',
      selectedInstances: [],
      selectedGroupInstances: [],
      selectedSingleInstances: [],
      selectedFolder: null,
      instances: [],
      groupsInstances: [],
      singleInstances: [],
      folders: [],
      selectAllText : 'Select All',
      createPageForEachTemplate: true,
      createOneFilePerGroup: true,
      getDirectLinktoFile: false,
      downloadInBackground: true,
      singleInstanceId: null
    };
  },
  computed:{
      instancesOption() {
          const selectAllOption = {instance_id: -1, name: this.selectAllText};
          return [selectAllOption, ...this.instances];
      }
  },
  watch : {
      selectedInstances(newVal) {
          const allSelected = newVal.some(option => option.instance_id === -1);
          if (allSelected) {
              this.toggleSelectAllText();
          }
      },
  },
  mounted() {
    exportTemplatesEventBroker.on(EVENTS.INIT, this.init.bind(this));
    exportTemplatesEventBroker.on(EVENTS.SHOW, this.onShow.bind(this));
    exportTemplatesEventBroker.on(EVENTS.CLOSE, this.onClose.bind(this));
    exportTemplatesEventBroker.on(EVENTS.FINISH, this.onFinish.bind(this));
    bus.$on('singleInstanceId', (singleInstanceId) => {
      if(singleInstanceId) {
        this.selectedInstances = this.instancesOption.filter(option => option.instance_id == singleInstanceId); 
      }
    });
  },
  methods: {
    init({ groupsInstances, singleInstances, instances, folders }) {
      this.groupsInstances = groupsInstances;
      this.singleInstances = singleInstances;
      this.instances = instances.map(({ text, value }) => ({ name: text, instance_id: value }));
      this.folders = folders;
    },
    onShow() {
      this.$bvModal.show('export-templates-modal');
    },
    onClose() {
      this.isSaving = false;
      this.$bvModal.hide('export-templates-modal');
    },
    onFinish(){
      this.isSaving = false;
    },
    onSave() {

      if (this.selectedFormat === 'pdf' && (!this.selectedGroupInstances.length && !this.selectedSingleInstances.length)) {
        window.toastr.error("Please select at least one group or template from the list");
        return;
      }

      if (this.selectedFormat === 'gif' && (!this.selectedGroupInstances.length && !this.selectedSingleInstances.length)) {
        window.toastr.error("Please select at least one group from the list");
        return;
      }

      if (this.selectedFormat !== 'gif' && this.selectedFormat !== 'pdf' && !this.selectedInstances.length){
        window.toastr.error("Please select at least one template from the list");
        return;
      }

      this.isSaving = true;

      exportTemplatesEventBroker.fire(EVENTS.EXPORT, {
        exportFormat: this.selectedFormat,
        selectedInstances: this.selectedInstances,
        selectedGroupInstances: this.selectedGroupInstances,
        selectedSingleInstances: this.selectedSingleInstances,
        selectedHTMLPlatform: this.selectedPlatform,
        dpi: this.dpi,
        createPageForEachTemplate: this.createPageForEachTemplate,
        getDirectLinktoFile: this.getDirectLinktoFile,
        selectedFolder: this.selectedFolder,
        createOneFilePerGroup: this.createOneFilePerGroup,
        downloadInBackground: this.downloadInBackground
      })
    },
    selectAllOptions(selected) {
        if (selected) {
            this.selectedInstances = this.instances.map(instance => instance.instance_id);
        } else {
            this.selectedInstances = [];
        }
    },
    toggleSelectAllText() {
        if (this.selectAllText === 'Select All') {
            this.selectedInstances = this.instancesOption.filter(option => option.instance_id !== -1);
            this.selectAllText = 'Unselect All';
        } else {
            this.selectedInstances = [];
            this.selectAllText = 'Select All';
        }
    },
    handleSelection(selectedFolderId) {
      this.selectedFolder = selectedFolderId;
    }
  }
}
</script>
<style>
@media (min-width: 576px) {
    #export-templates-modal .modal-dialog {
        width: 900px !important;
        max-width: 900px !important;
    }
}

.container-downloadable-templates .multiselect__tags {
    max-height: 135px;
    overflow: scroll;
}
</style>
