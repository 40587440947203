var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-folder-layer__item d-flex align-items-center mt-2" },
    [
      _c("div", { staticClass: "c-folder-layer__item--preview mr-2" }, [
        _c(
          "div",
          { staticClass: "a-text-layer" },
          [
            _c("b-icon", {
              staticClass: "d-block",
              attrs: { icon: "textarea-t" },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "layer--item__text" }, [
        _vm._v("\n    " + _vm._s(_vm.node.text) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }