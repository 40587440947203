import { EditTemplateContext } from "@frontend/contexts/edit-template-context";
import { psdLayersEventBroker, EVENTS as PSD_LAYERS_EVENT } from '../psd-layers/event-broker';
import { importPsdEventBroker, EVENTS as IMPORT_PSD_EVENTS } from './event-broker';
import TemplateService from '@frontend/services/api/template';
import { resizeWithAspectRatio } from '@frontend/services/helpers';
import { MOCK_INSTANCE_ID, getUpdatedDataLayoutTemplatesGrid } from '../get-updated-data-layout-templates-grid';
import {multipleTemplateHandler} from "../psd-layers/helpers";
import LAYOUT_API from '@frontend/services/api/layout';
import Route from '@frontend/services/helpers/url';
import templateApi from '@frontend/services/api/template';
import vuexStore from '@frontend/store';
import { MUTATIONS } from '@frontend/store/psd-layers';
import Router from '@frontend/router';

export const IMPORT_AND_CREATE_STRATEGY = 'import-and-create';
export const IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY = 'import-into-exists-template';
export const ACTION_IMPORT_FROM_PSD = 'import-from-psd';

const LIMIT_IMPORT_FROM_PDS_WIDTH = 5000;
const LIMIT_IMPORT_FROM_PDS_HEIGHT = 5000;
const MOCK_CONTENT_FOR_GRID = () => $('<div></div>');

export const importPreselectedPsd = async (callback) => {
    const queryParams = getQueryParams();

    if (queryParams.hasOwnProperty('file_id') && queryParams.hasOwnProperty('folder_id')) {
        if (queryParams.hasOwnProperty('templates_ids')) {
            vuexStore.commit(`psdLayers/${MUTATIONS.SET_IS_IMPORT_INTO_TEMPLATE}`, true);
            const { data: templates } = await templateApi.getTemplatesByIds(queryParams.templates_ids);
            initImportIntoExistsTemplateStrategy({ templates });
        } else {
            initImportAndCreateStrategy();
        }

        await removeQueryParams(queryParams);

        window.selectedBackground = MOCK_CONTENT_FOR_GRID();
        window.openSelectImageModalHandler({
            isMultipleTemplateSelectionType: true,
        });

        setTimeout(() => {
            window.update_file_images(1, 'folder_content', queryParams.folder_id);
            $('tags').css('display', 'none');

            $('#nav-files-tab').click();

            setTimeout(() => {
                const element = $(`[data-item-id="${queryParams.file_id}"]`);

                if (element.length) {
                    element.click();
                }

                if (callback && typeof callback === 'function') {
                    callback();
                }
            }, 2000);
        }, 500);
    }
}

export const removeQueryParams = () => {
    Route.deleteParam('file_id');
    Route.deleteParam('folder_id');
    Route.deleteParam('templates_ids');
}

export const getLayoutInfo = () => {
    const grid = $("#template-group-preview");
    const customerId = grid.attr("data-customer-id");
    const id = grid.attr("data-layout-id");
    const name = grid.attr("data-layout-name");
    const perPage = $('input[name="per_page"]').val()

    return { id, customerId, name, perPage };
}

function getDataGrid(newTemplates) {
    const previews = window.previews || [];
    const oldTemplates = [];

    previews.forEach(({ template, settings: { instance_id, x, y, w, h } }) => {
        oldTemplates.push({
            id: template.id,
            instanceId: instance_id,
            name: template.name,
            width: template.width,
            height: template.height,
            content: MOCK_CONTENT_FOR_GRID(),
            gridData: { x, y, w, h }
        });
    });

    return getUpdatedDataLayoutTemplatesGrid(oldTemplates, newTemplates);
}

export function getGridDataForImportAndCreateStrategy(gridData) {
    const { oldTemplates, newTemplates } = gridData;

    return {
        oldTemplates: oldTemplates.map(({ instance_id, w, h, y, x }) => ({ instance_id, w, h, y, x })),
        newTemplate: (({ w, h, y, x }) => ({ w, h, y, x }))(newTemplates[0]),
    }
}

function initImportAndCreateStrategy() {
    const { id, customerId } = getLayoutInfo();

    psdLayersEventBroker.on(
        PSD_LAYERS_EVENT.FILL_TEMPLATE,
        multipleTemplateHandler(async (state) => {
            const width = state.template.width;
            const height = state.template.height;
            let transformSize;

            if (LIMIT_IMPORT_FROM_PDS_WIDTH < width || LIMIT_IMPORT_FROM_PDS_HEIGHT < height) {
                transformSize = resizeWithAspectRatio(width, height, LIMIT_IMPORT_FROM_PDS_WIDTH, LIMIT_IMPORT_FROM_PDS_HEIGHT);

                window.toastr.info(`PSD will be imported at ${transformSize.width} x ${transformSize.height}`);
            }

            const gridData = getDataGrid([{
                width: transformSize?.width || width,
                height: transformSize?.height || height
            }]);

            const name = `${state.originalFileName.split('/').pop()} ${state.template.name || ''}`.trim()

            await TemplateService.createTemplate(customerId, id, {
                width,
                height,
                name,
                layers: state.layers,
                isTreatMultipleSpaces: state.isTreatMultipleSpaces,
                gridData: getGridDataForImportAndCreateStrategy(gridData),
                isReplace: state.isReplace,
            });

        }, () => {
            psdLayersEventBroker.fire(PSD_LAYERS_EVENT.CLOSE_AND_RELOAD);
        }),
        true
    );
}

export const createFillTemplateHandlerForImportIntoExistingTemplate = (params, before, after) => async (state) => {
    let _params = params

    if (typeof before === 'function') {
        const beforeParams = await before()
        if (beforeParams) {
            _params = {
                ..._params,
                ...beforeParams
            }
        }
    }

    const {customerId, name, id, perPage, templates} = _params

    const { oldTemplates, newTemplates } = getDataGrid(templates);
    const settings =  JSON.stringify([...oldTemplates, ...newTemplates]);

    await LAYOUT_API.updateLayout(customerId, id, { name, settings });

    const localData = newTemplates.map(({ instance_id, template_id }) => {

        const data = { instanceId: instance_id, state };

        if (templates[0].id === template_id) {
            return data;
        }

        const _template = templates.find(template => template.id === template_id);
        const layers = state.layers.map(layer => {
            const mappedField = layer.field;

            if (!mappedField) return layer;

            const field = _template.fields.find(field => field.name === mappedField.name);

            return { ...layer, field };
        });

        return {
            ...data,
            state: { ...state, layers },
        }
    });

    localStorage.setItem(ACTION_IMPORT_FROM_PSD, JSON.stringify(localData));

    if (typeof after === 'function') {
        await after()
    }
}

function initImportIntoExistsTemplateStrategy({ templates }) {
    const firstTemplate = templates[0];
    window.template_data = firstTemplate;
    window.$context.get(EditTemplateContext).setFieldsToFill(firstTemplate.fields);
    const { id, customerId, name, perPage } = getLayoutInfo();

    psdLayersEventBroker.on(
      PSD_LAYERS_EVENT.FILL_TEMPLATE,
      createFillTemplateHandlerForImportIntoExistingTemplate(
        {customerId, name, id, perPage, templates},
        undefined,
        () => {
            const page = Math.floor(window.previews.length / +perPage);

            if (page) {
                Route.addParam('page', page + 1)
            }

            Route
              .addParam('action', ACTION_IMPORT_FROM_PSD)
              .reload();
        }
      ),
      true
    )
}

function openSelectImageModal() {
    const preview = window.previews[0];
    const $customerInput = $('input[name="customer"]');
    const $customerIdInput = $('input[name="customer_id"]');
    const $templateIdInput = $("input[name='template_id']");
    const $themeSelect = $("select[name='theme']");

    $templateIdInput.val(preview?.template?.id);
    $customerIdInput.val(preview?.template?.customer?.id);
    $customerInput.val(preview?.template?.customer?.value);
    $themeSelect.empty().append(
      `<option value="${preview?.data?.theme}"></option>`
    );
    $themeSelect.val(preview?.data?.theme);
    
    importPsdEventBroker.fire(IMPORT_PSD_EVENTS.SELECT_WAY_IMPORT_FROM_PSD.INIT);

    importPsdEventBroker.on(IMPORT_PSD_EVENTS.SELECT_WAY_IMPORT_FROM_PSD.SAVE, ({ selectedSratagy, selectedTemplates }) => {
        if (selectedSratagy === IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY) {
            initImportIntoExistsTemplateStrategy(selectedTemplates);
        } else {
            initImportAndCreateStrategy();
        }
    
        setTimeout(() => {
            window.selectedBackground = MOCK_CONTENT_FOR_GRID();
            window.openSelectImageModalHandler({ isMultipleTemplateSelectionType: true });
        }, 250);

    }, true);
}

const getQueryParams = () => {
    let params = {};
    window.location.search.substring(1).split("&").forEach(function(param) {
        let pair = param.split("=");
        params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    });

    return params;
}

$(document).ready(function () {
    $('.import-from-psd').on('click', openSelectImageModal);
});
