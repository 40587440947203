<template>
  <div class="bottom-bar">
    <b-pagination
      ref="pa"
      v-model="current"
      class="c-pagination"
      pills
      align="center"
      :total-rows="totalCount"
      :per-page="12"
      @change="paginationChange"
    >
      <slot />
    </b-pagination>
  </div>
</template>

<script>

import {createNamespacedHelpers} from "vuex";

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('fileBrowserProductSelector')

export default {
  name: "PaginationComponent",
  data: () => ({
    current: 1
  }),
  computed: {
    ...mapGetters([
      'totalCount',
      'items',
      'currentPage',
      'selectedItems'
    ])
  },
  watch: {
    currentPage(v) {
      if (v !== this.current) {
        this.current = v;
      }
    },
  },
  created() {
    this.current = this.currentPage;
  },
  methods: {
    ...mapActions([
      'setCurrentPage'
    ]),
    paginationChange(page) {
      this.setCurrentPage(page);
    }
  }
}
</script>

<style scoped lang="scss">
.c-pagination {
  width: fit-content;
  margin: .75rem auto;

  ::v-deep .page-item {
    .page-link {
      font-family: "Nunito", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #727272;
      border: none;
    }

    .page-link:hover {
      background-color: #95d9cc;
    }

    .page-link:focus {
      box-shadow: unset;
    }
  }

  ::v-deep .page-item.active {
    .page-link {
      background-color: #007964;
      color: white;
    }
  }
}
</style>
