var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "mb-2" }, [_vm._v("\n    Group:\n  ")]),
      _vm._v(" "),
      _c("PositionAndSize", {
        attrs: {
          h: _vm.target.height * _vm.target.scaleY,
          w: _vm.target.width * _vm.target.scaleX,
          x: _vm.target.left,
          y: _vm.target.top,
        },
        on: { input: _vm.onChange },
      }),
      _vm._v(" "),
      _c(
        "b-btn",
        {
          attrs: { variant: "primary" },
          on: {
            click: function ($event) {
              return _vm.onChange(_vm.isCustomGroup ? "ungroup" : "group", null)
            },
          },
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.isCustomGroup ? "Ungroup" : "Group") + "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isTextObjects
        ? _c("ApplyToAllTextDropdown", {
            attrs: { options: _vm.options, target: _vm.target },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isProductImageObjects
        ? _c("ApplyToAllImageDropdown", {
            staticClass: "mt-3",
            attrs: { target: _vm.target },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }