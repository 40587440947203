<template>
  <div
    class="c-grid-view-item"
    @dragover="handleDragEnter"
    @dragleave="handleDragLeave"
  >
    <div
      class="c-grid-view-item__preview"
      :class="{ active: isSelected }"
      @click="$emit('click', item)"
      @dblclick="$emit('dblclick', item)"
    >
      <div
        class="c-grid-view-item__preview--item"
        :class="{ active: isSelected || isDragOver }"
      >
        <b-badge 
          v-if="isPsdParsed"
          class="psd-parsed-label" 
          pill
          variant="success"
        >
          PSD parsed
        </b-badge>
        <b-icon
          v-if="item.is_favorite"
          icon="bookmark-fill"
          class="favorite-icon"
        />
        <ItemView :item="item" />
        <div
          v-if="item.is_processing"
          class="c-grid-view-item__preview--progress w-100"
        >
          <b-progress
            class="a-progressing__bar"
            :value="item.percent"
            variant="success"
            striped
            animated
          />
        </div>
        <div class="c-grid-view-item__preview--overlay">
          <ItemOverlay
            v-if="isAvailableOverlay && item.name !== '...'"
            :item="item"
          />
        </div>
        <div
          v-if="item.count > 0"
          class="item-status"
        >
          <b-icon
            icon="check-circle-fill"
            font-scale="1.3"
            style="color: #479382; stroke: #fff; stroke-width: 1.5px"
          />
        </div>
        <div 
          v-if="item.type === 'folder'"
          class="c-grid-view-item__folder_name"
        >
          {{ truncatedName }}
        </div>
      </div>
    </div>
    <div 
      v-if="item.type !== 'folder'"
      class="c-grid-view-item__name"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
import ItemView from "./ItemView.vue";
import ItemOverlay from "./item-overlays/ItemOverlay.vue";
import { FILE_TYPE } from "@frontend/constants/file-image-types";

const MAX_STRING_LENGTH = 20;

export default {
  name: "GridItem",
  components: {ItemOverlay, ItemView},
  props: {
    item: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isAvailableOverlay: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isDragOver: false,
      tooltip: '',
      isTooltipVisible: false
    };
  },
  computed: {
    truncatedName() {
      if (this.item.name.length > MAX_STRING_LENGTH) {
        return this.item.name.substring(0, MAX_STRING_LENGTH) + '...';
      } else {
        return this.item.name;
      }
    },
    isPsdParsed() {
      return this.item.type === FILE_TYPE.PSD && this.item.is_psd_parsed;
    }
  },
  methods:{
    handleDragEnter() {
        if (this.item.type === TYPE.FOLDER){
          this.isDragOver = !this.item.is_root;
        }
    },
    handleDragLeave() {
        if (this.item.type === TYPE.FOLDER){
          this.isDragOver = false;
        }
    },
  }
}
</script>

<style scoped lang="scss">
  .c-grid-view-item {
    width: 140px;
    height: fit-content;
    user-select: none;

    &__preview {
      float: inherit;
      border: 1px solid #e1e1e1;
      background: white;
      box-sizing: border-box;
      box-shadow: 0 4px 4px #f8f8f8;
      border-radius: 10px;
      position: relative;
      transition: background-color ease-in-out 0.4s;
      overflow: hidden;

      &--item {
        border: 4px solid transparent;
        height: 90px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          border: 4px solid #95d9cc;
        }

        &.active {
          border: 4px solid #209c86;
        }
        
        .psd-parsed-label {
          position: absolute;
          top: 5px;
          left: 5px;
        }
      }

      &.active, &:hover {
        border: 1px solid transparent;
      }

      &--overlay {
        position: absolute;
        top: 0;
        right: 5px;
        display: none;
      }

      &:hover &--overlay {
        display: block;
      }

      &--progress {
        position: absolute;
        left: 0;
        top: calc(50% - 8px);
      }
    }

    &__name {
      transition: ease-in-out;
      word-break: break-all;
      font-size: 14px;
      color: #464646;
      text-align: center;
    }

    .tooltip {
      position: absolute;
      background-color: #333;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }
  .folder-items .c-grid-view-item {
    &__preview {
      background: #EEEEEE;
      &--item {
        height: 52px;
        justify-content: left;
      }
    }
  }

  .folder-items .c-grid-view-item__folder_name {
    font-size: 0.9em;
    width: 67%;
  }

  .favorite-icon {
    position: absolute;
    font-size: 0.6rem;
    top: 22px;
    left: 16px;
  }

  .item-status {
    position: absolute;
    bottom: 0;
    left: 5px;
  }
</style>
