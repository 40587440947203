import { EventBroker } from "../../../lib/utils/event-broker";

export const EVENTS = {
    INIT: 'INIT',
    CLOSE: 'CLOSE',
    TEXT_CHANGED: 'TEXT_CHANGED',
    IMAGE_CHANGED: 'IMAGE_CHANGED',
    SHAPE_CHANGED: 'SHAPE_CHANGED',
    CUSTOM_GROUP_CHANGED: 'GROUP_CHANGED',
    PRODUCT_DIMENSION_CHANGED: 'PRODUCT_DIMENSION_CHANGED',
    ANIMATION_MODULE_CHANGED: 'ANIMATION_MODULE_CHANGED',
    ANIMATION_MODULE_PREVIEW: 'ANIMATION_MODULE_PREVIEW',
    PROPERTIES_MODULE_CHANGED: 'PROPERTIES_MODULE_CHANGED',
    SET_ACTIVE_OBJECT: 'SET_ACTIVE_OBJECT',
    TRANSLATE: 'TRANSLATE',
    TOGGLE_LOADING: 'TOGGLE_LOADING',
}

export const editorControlEventBroker = new EventBroker();
