import {DEFAULT_NAMES_MAP} from "../../constants/draft-scenes";

export const concatNameAndSuffix = (name, suffix) => `${name}${suffix}`;

export const getDefaultFieldName = (sceneType, sceneRows) => {
    const name = DEFAULT_NAMES_MAP[sceneType];
    if (!name) {
        return '';
    }
    let suffix = 1;
    while (sceneRows.find(({Name}) => Name === concatNameAndSuffix(name, suffix))) {
        suffix++;
    }

    return concatNameAndSuffix(name, suffix);
}