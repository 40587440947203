var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.creatives.length
      ? _c(
          "div",
          { staticClass: "text-center mt-5" },
          [
            _c("b-spinner", { attrs: { variant: "primary" } }),
            _vm._v(" "),
            _c("p", { staticClass: "mt-2" }, [
              _vm._v("\n      Generating Previews...\n    "),
            ]),
          ],
          1
        )
      : _c(
          "div",
          _vm._l(_vm.creatives, function (creative, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "creative-item mt-3 mb-2 border rounded p-3",
              },
              [
                _c("h3", [_vm._v(_vm._s(creative.name))]),
                _vm._v(" "),
                _c("img", { attrs: { src: creative.url, alt: "preview" } }),
              ]
            )
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }