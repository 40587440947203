<template>
  <div
    class="c-item-overlay"
  >
    <div
      v-b-tooltip.hover
      title="Replace"
      class="c-item-overlay__item delete-item"
      @click="decreaseCount"
    >
      <i
        class="fas fa-redo-alt"
        style="color: #0d6854"
      />
      <!--      <b-icon
        icon="arrow-repeat"
        font-scale="1.1"
        style="color: #479382"
      />-->
    </div>
    <div
      v-b-tooltip.hover
      title="Delete"
      class="c-item-overlay__item add-item"
      @click="addItem"
    >
      <i
        class="fas fa-trash"
        style="color: #0d6854"
      />
    </div>
  </div>
</template>


<script>
import { createNamespacedHelpers } from 'vuex'

const { mapMutations, mapActions } = createNamespacedHelpers('fileBrowserProductSelector')

export default {
  name: "ItemOverlay",
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      count: this.item.count
    };
  },
  watch:{
    item(newVal) {
      if (this.count !== newVal.count) {
        this.count = newVal.count;
      }
    }
  },
  methods: {
    ...mapActions([
      'pickItem',
      'deleteItem'
    ]),
    addItem(e) {
      e.stopPropagation();
      this.pickItem({
        ...this.item,
        count: ++this.count
      });
    },
    decreaseCount(e) {
      e.stopPropagation();
      if (this.count > 0) {
        this.deleteItem({
          ...this.item,
          count: --this.count
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .c-item-overlay {
    margin-top: 3px;
    padding: 2px;
    display: flex;
    flex-direction: column;

    &__item {
      padding-right: 3px;
      cursor: pointer;

      .fa-edit {
        margin-top: 5px;
        color: #747474;
      }
    }
  }

  .c-item-overlay.folder_overlay {
    padding: 2px;
    display: block;
  }
</style>
