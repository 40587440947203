<template>
  <div 
    v-click-outside="onSearchKeyBlur"
    class="product-images-input"
  >
    <b-form-input
      id="multipleValues"
      v-model="searchInputValue"
      readonly
      placeholder="Product images"
      autocomplete="off"
      @focus="expandDropdown"
    />
    <b-button
      v-if="searchInputValue"
      variant="light"
      class="clear-product-input-btn"
      @click="$bvModal.show('modal-warning-product-image')"
    >
      <b-icon
        icon="x-circle"
        class="icon-clear-input"
      />
    </b-button>

    <div
      v-if="dropdownIsExpanded"
      id="file_ids_dropdown"
    >
      <file-browser-product-selector />
    </div>

    <b-modal
      id="modal-warning-product-image"
      hide-backdrop
      content-class="shadow"
      title=""
      @ok="clearInputValue"
    >
      <p class="my-2">
        Your changes will be lost! Are you sure you want to clear the workspace?
      </p>
    </b-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('fileBrowserProductSelector')


export default {
  name: 'ProductImagesDropdown',
  directives: {
    ClickOutside: {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
          // Check if the click is outside the element and is not a pagination button
          if (!(el === event.target || el.contains(event.target))) {
            if (event.target.classList.contains('page-link')) {
              return;
            }

            // Call the provided method if the click is truly outside
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
    }
  },
  
  props: {
    selectedProducts: {
      type: Array,
      default: () => []
    }
  },
  
  data: () => ({
    dropdownIsExpanded: false
  }),

  computed: {
    ...mapGetters(['totalCount', 'pickedItems']),
    searchInputValue: {
      get() {
        return this.selectedProducts.map(item => item.name).join(' ');
      }
    },
  },
  watch: {
    pickedItems(items) {
      if (items.length > 0) {
        this.$emit('dropdown-item-click', items[0])
      }
    },
    dropdownIsExpanded(isExpanded) {
      this.setOnlyOneItemSelection(isExpanded);
    }
  },
  methods: {
    ...mapActions([
      'setOnlyOneItemSelection', 
      'setShowOnlySelected', 
      'setSelectedFileNames',
      'setCurrentPickedItems',
      'setSearchQuery', 
      'fetchData'
    ]),

    expandDropdown() {
      this.dropdownIsExpanded = true;
      this.setCurrentPickedItems(this.selectedProducts.map(item => item.name.split('.')[0]));
      
      if (this.pickedItems.length > 0) {
        this.setShowOnlySelected(true);
      }
    },

    onSearchKeyBlur(e) {
      this.dropdownIsExpanded = false;
    },

    clearInputValue() {
      this.setSearchQuery('');
      this.setCurrentPickedItems([]);
      this.$emit('clear-input-value');
    },
  }
}
</script>

<style lang="scss" scoped>
  .product-images-input {
    position: relative;
    
    #multipleValues[readonly] {
      background: initial;
      
      &:hover {
        cursor: pointer;
      }
    }

    .clear-product-input-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      background: transparent;
      border: none;

      &:hover {
        background-color: transparent !important;
        border: none;
      }

      &:disabled {
        border: none;
      }

      &:focus {
        outline: none;
        box-shadow: none;
        border-color: transparent !important;
        background-color: transparent !important;
      }

      &:active {
        box-shadow: none !important;
      }
    }

    #file_ids_dropdown {
      top: 0;
      right: 0;
      max-height: 770px;
      height: fit-content;
    }

    .product-message {
      position: absolute;
      left: 470px;
      top: 200px;
    }
  }
</style>
