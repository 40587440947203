import axios from "axios";

import Resumable from "resumablejs";
import parsePSDData, {recursiveFind, recursiveWalker} from "../../includes/parse_psd_data";
import {FileFormURL} from "@/includes/file-form-url";
import {setTemplateFields} from "@/includes/data-model-mapper";
import { EVENTS as PSD_LAYERS_EVENT, EVENTS, psdLayersEventBroker } from "../group/modules/psd-layers/event-broker";
import {ApplyStrategy} from "../services/api/psd-layers";
import PSDLayerService from "../services/api/psd-layers";
import {FIELD_TYPE_PRODUCT_IMAGE} from "@frontend/constants/type-fields-of-template";
import Base from "@/includes/parse_psd_data/psd-types/base";
import {PSD_LAYER_TYPE_TO_BASIC_MAP} from "@/includes/parse_psd_data/constants";
import { PARSED_FIELD_TYPES } from "@/includes/parse_psd_data/constants";
import { getImageDimensions } from "@frontend/services/helpers";
import BackgroundImage from "@/includes/parse_psd_data/psd-types/background-image";

import {Folder} from "@/includes/parse_psd_data/psd-types/folder";
import {Layer} from "@/includes/parse_psd_data/psd-types/layer";

const initialState = () => ({
    layersToImport: [],
    templateFieldsByType: {},
    parsedPsdModel: [],
    templates: [],
    selectedTemplates: [],
    previewTemplateThumbnail: '',
    entireImageThumbnail: '',
    entireImage: '',
    filename: '',
    originalFileName: '',
    progress: 0,
    isLoading: true,
    isError: false,
    errorMessage: 'Something went wrong',
    isGenerating: false,
    isUpdatingPreviewTemplate: false,
    isSavePsd: true,
    themeFields: {},
    isCreateIndividualAssets: false,
    isTreatMultipleSpacesAsSingle: false,
    isMultipleTemplateSelectionType: false,
    flow: ApplyStrategy.FLOWS.ADD_TO_TEMPLATE,
    isImportIntoTemplate: false,
    isReplaceExistingProduct: false,
    isAutoMapToFields: false,
    isRemovePlaceholderText: false,
    isUseSourceTextSettings: false,
    fieldsToMap: [],
    isTemplateFeatures: false,
    mergingNodes: [],
})

export const MUTATIONS = {
    RESET: 'RESET',
    SET_LAYERS_TO_IMPORT: 'SET_LAYERS_TO_IMPORT',
    SET_FILENAME: 'SET_FILENAME',
    SET_SELECTED_TEMPLATES: 'SET_SELECTED_TEMPLATES',
    SET_TEMPLATES: 'SET_TEMPLATES',
    TOGGLE_LAYER: 'TOGGLE_LAYER',
    SELECT_LAYER: 'SELECT_LAYER',
    TOGGLE_ALL: 'TOGGLE_ALL',
    SET_PROGRESS: 'SET_PROGRESS',
    SET_TEMPLATE_FIELDS_BY_TYPE: 'SET_TEMPLATE_FIELDS_BY_TYPE',
    SET_PARSED_PSD_MODEL: 'SET_PARSED_PSD_MODEL',
    SET_LOADING: 'SET_LOADING',
    SET_ORIGINAL_FILENAME: 'SET_ORIGINAL_FILENAME',
    SET_ERROR: 'SET_ERROR',
    SET_GENERATING: 'SET_GENERATING',
    SET_UPDATING_PREVIEW_TEMPLATE: 'SET_UPDATING_PREVIEW_TEMPLATE',
    SET_SAVE_PSD: 'SET_SAVE_PSD',
    SET_THEME_FIELDS: 'SET_THEME_FIELDS',
    SET_CREATE_INDIVIDUAL_ASSETS: 'SET_CREATE_INDIVIDUAL_ASSETS',
    SET_IMPORT_TEXT_FIELDS_AS_TEXT: 'SET_IMPORT_TEXT_FIELDS_AS_TEXT',
    SET_TREAT_MULTIPLE_SPACES_AS_SINGLE: 'SET_TREAT_MULTIPLE_SPACES_AS_SINGLE',
    SET_FLOW: 'SET_FLOW',
    SET_ENTIRE_IMAGE_THUMBNAIL: 'SET_ENTIRE_IMAGE_THUMBNAIL',
    SET_PREVIEW_TEMPLATE_THUMBNAIL: 'SET_PREVIEW_TEMPLATE_THUMBNAIL',
    SET_ENTIRE_IMAGE: 'SET_ENTIRE_IMAGE',
    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
    SET_MULTIPLE_TEMPLATE_SELECTION_TYPE: 'SET_MULTIPLE_TEMPLATE_SELECTION_TYPE',
    SET_VIEW_TYPE: 'SET_VIEW_TYPE',
    SET_FIELD: 'SET_FIELD',
    UPDATE_LAYER: 'UPDATE_LAYER',
    SET_IS_IMPORT_INTO_TEMPLATE: 'SET_IS_IMPORT_INTO_TEMPLATE',
    SET_IS_REPLACE_EXISTING_PRODUCT: 'SET_IS_REPLACE_EXISTING_PRODUCT',
    SET_AUTO_MAP_TO_FIELDS: 'SET_AUTO_MAP_TO_FIELDS',
    SET_REMOVE_PLACEHOLDER_TEXT: 'SET_REMOVE_PLACEHOLDER_TEXT',
    SET_FIELDS_TO_MAP: 'SET_FIELDS_TO_MAP',
    SET_LOGO_FIELD_URL: 'SET_LOGO_FIELD_URL',
    SET_TEMPLATE_FEATURES: 'SET_TEMPLATE_FEATURES',
    REPLACE_NODE: 'REPLACE_NODE',
    ADD_MERGING_NODE: 'ADD_MERGING_NODE',
    REMOVE_MERGING_NODE: 'REMOVE_MERGING_NODE',
    SET_FOR_ALL_PARENTS: 'SET_FOR_ALL_PARENTS',
    SET_USE_SOURCE_TEXT_SETTINGS: 'SET_USE_SOURCE_TEXT_SETTINGS',
};

let cancelToken;
let resumable;

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        entireImageThumbnail: state => state.entireImageThumbnail,
        previewTemplateThumbnail: state => state.previewTemplateThumbnail,
        selectedTemplates: state => state.selectedTemplates,
        templates: state => state.templates,
        progress: state => state.progress,
        isLoading: state => state.isLoading,
        templateFieldsByType: state => state.templateFieldsByType,
        isError: state => state.isError,
        errorMessage: state => state.errorMessage,
        isGenerating: state => state.isGenerating,
        isUpdatingPreviewTemplate: state => state.isUpdatingPreviewTemplate,
        isSavePsd: state => state.isSavePsd,
        isCreateIndividualAssets: state => state.isCreateIndividualAssets,
        isTreatMultipleSpacesAsSingle: state => state.isTreatMultipleSpacesAsSingle,
        isMultipleTemplateSelectionType: state => state.isMultipleTemplateSelectionType,
        flow: state => state.flow,
        isImportIntoTemplate: state => state.isImportIntoTemplate,
        isReplaceExistingProduct: state => state.isReplaceExistingProduct,
        isAutoMapToFields: state => state.isAutoMapToFields,
        isRemovePlaceholderText: state => state.isRemovePlaceholderText,
        isTemplateFeatures: state => state.isTemplateFeatures,
        mergingNodes: state => state.mergingNodes,
        isUseSourceTextSettings: state => state.isUseSourceTextSettings
    },
    mutations: {
        [MUTATIONS.RESET](_state) {
            const initial = {
              ...initialState(),
              isImportIntoTemplate: _state.isImportIntoTemplate
            };

            Object.keys(initial).forEach(key => {
                _state[key] = initial[key]
            })
        },
        [MUTATIONS.SET_FILENAME](state, payload) {
            state.filename = payload;
        },
        [MUTATIONS.SET_SELECTED_TEMPLATES](state, payload) {
            state.selectedTemplates = payload || [];
        },
        [MUTATIONS.SET_TEMPLATES](state, payload) {
            state.templates = payload;

            if (payload.length === 1) {
                state.selectedTemplates = payload;
            }
        },
        [MUTATIONS.TOGGLE_LAYER](state, layer) {
            recursiveWalker(state.templates, node => {
                if (node.uuid === layer.uuid) {
                    node.visible = !node.visible;

                    if (node.visible) {
                        let _node = node.parent;

                        while (!!_node) {
                            _node.visible = true;
                            _node = _node.parent;
                        }
                    }

                    if (node.children) {
                        recursiveWalker(
                          node.children,
                            _node => _node.visible = node.visible
                        )
                    }
                }
            })
        },
        [MUTATIONS.TOGGLE_ALL](state, selected) {
            recursiveWalker(state.templates, node => {
                node.visible = selected;
            })
        },
        [MUTATIONS.SELECT_LAYER](state, layer) {
            recursiveWalker(state.templates, node => {
                if (node.uuid === layer.uuid) {
                    node.visible = true;

                    let _node = node;

                    while (!!_node) {
                        _node.visible = true;
                        _node = _node.parent;
                    }
                }
            })
        },
        [MUTATIONS.SET_PROGRESS](state, payload) {
            state.progress = payload;
        },
        [MUTATIONS.SET_TEMPLATE_FIELDS_BY_TYPE](state, payload) {
            state.templateFieldsByType = payload
        },
        [MUTATIONS.SET_PARSED_PSD_MODEL](state, payload) {
            state.parsedPsdModel = payload;
        },
        [MUTATIONS.SET_LOADING](state, payload) {
            state.isLoading = payload;
        },
        [MUTATIONS.SET_ORIGINAL_FILENAME](state, payload) {
            state.originalFileName = payload;
        },
        [MUTATIONS.SET_ERROR](state, payload) {
            state.isError = payload;
        },
        [MUTATIONS.SET_GENERATING](state, payload) {
            state.isGenerating = payload;
        },
        [MUTATIONS.SET_SAVE_PSD](state, payload) {
            state.isSavePsd = payload;
        },
        [MUTATIONS.SET_THEME_FIELDS](state, payload) {
            state.themeFields = payload;
        },
        [MUTATIONS.SET_CREATE_INDIVIDUAL_ASSETS](state, payload) {
            state.isCreateIndividualAssets = payload;
        },
        [MUTATIONS.SET_TREAT_MULTIPLE_SPACES_AS_SINGLE](state, payload) {
            state.isTreatMultipleSpacesAsSingle = payload;
        },
        [MUTATIONS.SET_FLOW](state, payload) {
            state.flow = payload;
        },
        [MUTATIONS.SET_ENTIRE_IMAGE_THUMBNAIL](state, payload) {
            state.entireImageThumbnail = payload;
        },
        [MUTATIONS.SET_PREVIEW_TEMPLATE_THUMBNAIL](state, payload) {
            state.previewTemplateThumbnail = payload;
        },
        [MUTATIONS.SET_ENTIRE_IMAGE](state, payload) {
            state.entireImage = payload;
        },
        [MUTATIONS.SET_ERROR_MESSAGE](state, payload) {
            state.errorMessage = payload;
            state.isError = true;
        },
        [MUTATIONS.SET_MULTIPLE_TEMPLATE_SELECTION_TYPE](state, payload) {
            state.isMultipleTemplateSelectionType = payload;
        },
        [MUTATIONS.SET_LAYERS_TO_IMPORT](state, payload) {
            state.layersToImport = payload
        },
        [MUTATIONS.SET_FIELD](state, {uuid, field}) {
            const _layer = recursiveFind(state.templates, _layer => _layer.uuid === uuid);

            if (_layer) {
                _.set(_layer, 'field', field)
            }
        },
        [MUTATIONS.UPDATE_LAYER](state, { layer, key, value }) {
          const _layer = recursiveFind(state.templates, _layer => _layer.uuid === layer.uuid);

          if (_layer) {
            _.set(_layer, key, value);
          }
        },
        [MUTATIONS.SET_UPDATING_PREVIEW_TEMPLATE](state, payload) {
            state.isUpdatingPreviewTemplate = payload;
        },
        [MUTATIONS.SET_IS_IMPORT_INTO_TEMPLATE](state, payload) {
            state.isImportIntoTemplate = payload;
        },
        [MUTATIONS.SET_IS_REPLACE_EXISTING_PRODUCT](state, payload) {
            state.isReplaceExistingProduct = payload;
        },
        [MUTATIONS.SET_AUTO_MAP_TO_FIELDS](state, payload) {
            state.isAutoMapToFields = payload;
        },
        [MUTATIONS.SET_REMOVE_PLACEHOLDER_TEXT](state, payload) {
            state.isRemovePlaceholderText = payload;
        },
        [MUTATIONS.SET_FIELDS_TO_MAP](state, payload) {
            state.fieldsToMap = payload;
        },
        [MUTATIONS.SET_LOGO_FIELD_URL](state, { logoUrl, width, height }) {
            state.fieldsToMap = state.fieldsToMap.map(field => {
                if (field.type === PARSED_FIELD_TYPES.USER_IMAGE) {
                    return {
                        ...field,
                        publicPath: logoUrl,
                        width,
                        height
                    }
                }

                return field;
            });
        },
        [MUTATIONS.SET_TEMPLATE_FEATURES](state, payload) {
            state.isTemplateFeatures = payload
        },
        [MUTATIONS.REPLACE_NODE](state, {node, newNode}) {
            recursiveWalker(state.templates, _node => {
                if (_node instanceof Folder) {
                    const index = _node.children.findIndex(child => child.uuid === node.uuid)
                    if (index > -1) {
                        _node.children[index] = newNode
                        _node.children = [..._node.children]
                    }
                }
            })
        },
        [MUTATIONS.ADD_MERGING_NODE](state, uuid) {
            state.mergingNodes.push(uuid)
        },
        [MUTATIONS.REMOVE_MERGING_NODE](state, uuid) {
            state.mergingNodes.splice(state.mergingNodes.indexOf(uuid), 1)
        },
        [MUTATIONS.SET_USE_SOURCE_TEXT_SETTINGS](state, payload) {
            state.isUseSourceTextSettings = payload;
        },
    },
    actions: {
        loadFile({ dispatch }, file) {
            switch(true) {
                case file instanceof File:
                    dispatch('fromFile', file);
                    break;
                case file instanceof FileFormURL:
                    dispatch('fromUrl', file);
                    break;
            }
        },
        fromFile({ commit, state, dispatch }, file) {
            commit(MUTATIONS.SET_ORIGINAL_FILENAME, file.name);

            resumable = new Resumable({
                target: '/file/psd_layer_split',
                query: { _token: $('#psd-preview').data('csrf') },
                chunkSize: 1024 * 10000, // in bytes
                testChunks: false,
                simultaneousUploads: 1,
            });

            resumable.addFile(file)

            resumable.on('fileAdded', () => {
                resumable.upload();
            })

            resumable.on('uploadStart', () => {
                commit(MUTATIONS.SET_PROGRESS, 0);
            })

            resumable.on('complete', () => {
                commit(MUTATIONS.SET_PROGRESS, 1);
            })

            resumable.on('progress', () => {
                const currentValue = Math.floor(resumable.progress() * 100) / 100;
                commit(MUTATIONS.SET_PROGRESS, currentValue)
            })

            resumable.on('fileSuccess', async (r, response) => {
                const data = JSON.parse(response);

                const userLogo = state.fieldsToMap.find(field => field.type === PARSED_FIELD_TYPES.USER_IMAGE);

                if (state.fieldsToMap.length && userLogo) {
                    await dispatch('uploadUsersLogo', { file: userLogo.value, filePath: data.parsePsdData.entireImage });
                }

                const parsePsdData = parsePSDData(data.parsePsdData, state.fieldsToMap)

                commit(MUTATIONS.SET_ENTIRE_IMAGE_THUMBNAIL, parsePsdData.entireImageThumbnail);
                commit(MUTATIONS.SET_ENTIRE_IMAGE, parsePsdData.entireImage);
                commit(MUTATIONS.SET_FILENAME, data.fileName);
                commit(MUTATIONS.SET_TEMPLATE_FIELDS_BY_TYPE, setTemplateFields())
                commit(MUTATIONS.SET_PARSED_PSD_MODEL, parsePsdData)
                commit(MUTATIONS.SET_TEMPLATES, parsePsdData.templates)
                commit(MUTATIONS.SET_LOADING, false)
            })

            resumable.on('error', () => {
                commit(MUTATIONS.SET_ERROR_MESSAGE, initialState().errorMessage);
            });
        },
        fromUrl({ commit, dispatch } , file) {
            const originFileName = `${file.name}.psd`
            commit(MUTATIONS.SET_FILENAME, file.url);
            commit(MUTATIONS.SET_ORIGINAL_FILENAME, originFileName);
            commit(MUTATIONS.SET_SAVE_PSD, false)

            axios.post('/file/preview_psd_file', {
                filename: file.url,
                originFileName,
            })
                .then(({ data }) => {
                    const parsePsdData = parsePSDData(data.parsePsdData)
                    commit(MUTATIONS.SET_ENTIRE_IMAGE_THUMBNAIL, parsePsdData.entireImageThumbnail || parsePsdData.entireImage);
                    commit(MUTATIONS.SET_ENTIRE_IMAGE, parsePsdData.entireImage);
                    commit(MUTATIONS.SET_TEMPLATE_FIELDS_BY_TYPE, setTemplateFields())
                    commit(MUTATIONS.SET_PARSED_PSD_MODEL, parsePsdData)
                    commit(MUTATIONS.SET_TEMPLATES, parsePsdData.templates)
                    commit(MUTATIONS.SET_LOADING, false)
                    if (!data.isOptimized) {
                        dispatch('updatePSD')
                    }
                }).catch((e) => {
                    const errorMessage = e.response?.data?.message ?? initialState().errorMessage;
                          toastr.error(errorMessage);
                          commit(MUTATIONS.SET_ERROR_MESSAGE, initialState().errorMessage);
                      })
        },
        async updatePSD({ state, rootGetters }) {
          await PSDLayerService.updatePSD({
                filePath: state.filename,
                psdData: {
                    parsePsdData: state.parsedPsdModel,
                }
            });

            const selectedFile = rootGetters['fileBrowser/firstSelectedItem'];
          
            psdLayersEventBroker.fire(PSD_LAYERS_EVENT.PSD_FILE_PARSED, {
                fileId: selectedFile.id
            });
        },
        cancel() {
            if (cancelToken) {
                cancelToken.cancel();
            }

            if (resumable) {
                resumable.cancel();
            }

            psdLayersEventBroker.fire(EVENTS.CANCEL);
        },
        addToTemplate(module) {
            module.commit(MUTATIONS.SET_GENERATING, true);
            const applyStrategy = new ApplyStrategy(module);
            applyStrategy
                .build(ApplyStrategy.FLOWS.ADD_TO_TEMPLATE)
                .all()
                .then((isReload) => {
                    if (isReload) {
                        location.reload();
                    }
                })
        },
        proceedByFlow(module) {
            module.commit(MUTATIONS.SET_GENERATING, true);
            const applyStrategy = new ApplyStrategy(module);
            applyStrategy
                .build(module.state.flow)
                .all()
                .then((isReload) => {
                    psdLayersEventBroker.fire(EVENTS.CANCEL);
                })
        },
        updatePreviewTemplate(module) {
            const { commit } = module;
            commit(MUTATIONS.SET_UPDATING_PREVIEW_TEMPLATE, true);

            const applyStrategy = new ApplyStrategy(module);
            applyStrategy
                .build(ApplyStrategy.FLOWS.UPDATE_PREVIEW_TEMPLATE)
                .all()
                .then((event) => {
                    commit(MUTATIONS.SET_UPDATING_PREVIEW_TEMPLATE, false);
                    commit(MUTATIONS.SET_PREVIEW_TEMPLATE_THUMBNAIL, event.preview)
                })
        },
        setAutoMapFields({ commit, getters }) {
            commit(MUTATIONS.SET_AUTO_MAP_TO_FIELDS, !getters.isAutoMapToFields);

            if (!getters.isAutoMapToFields) {
                return
            }

            if (!getters.isImportIntoTemplate) { // import to new template
                recursiveWalker(getters.templates, node => {
                    if (!(node instanceof Layer) || !node.data.name.includes(FIELD_TYPE_PRODUCT_IMAGE)) {
                        return;
                    }
                    commit(MUTATIONS.UPDATE_LAYER, {layer: node, key: 'fieldType', value: FIELD_TYPE_PRODUCT_IMAGE})
                    commit(MUTATIONS.UPDATE_LAYER, {layer: node, key: 'data.fieldType', value: FIELD_TYPE_PRODUCT_IMAGE})
                })
            } else { // import into existing template
                let isAnyMapped = false
                recursiveWalker(getters.templates[0].children, node => {
                    const mapped = getters.templateFieldsByType[PSD_LAYER_TYPE_TO_BASIC_MAP[node.type]]
                      ?.find(field => node.data.name.includes(field.name))

                    if (!(node instanceof Base) || !mapped) {
                        return;
                    }

                    isAnyMapped = true
                    
                    commit(MUTATIONS.SET_FIELD, { uuid: node.uuid, field: mapped });
                    commit(MUTATIONS.SELECT_LAYER, node);
                })
                if (isAnyMapped) {
                    commit(MUTATIONS.SELECT_LAYER, getters.templates[0])
                }
            }
        },
        async uploadUsersLogo({ commit, dispatch }, { file, filePath }) {
            try {
                const path = filePath.split('/').slice(0, 2).join('/');
                const formData = new FormData();
                formData.append('logo', file);
                formData.append('path', path);

                const { data } = await PSDLayerService.uploadUsersLogo(formData);

                const { width, height } = await getImageDimensions(file);

                commit(MUTATIONS.SET_LOGO_FIELD_URL, {
                    logoUrl: data.logoUrl,
                    width,
                    height
                });

            } catch (e) {
                console.error('The error occurred in the uploadUsersLogo action.');
            }
        },
        async mergeNode({ state, commit }, node) {
            const splitEntireImagePath = state.entireImageThumbnail.split('/');
            splitEntireImagePath.pop()

            commit(MUTATIONS.ADD_MERGING_NODE, node.uuid)

            try {
                const response = await PSDLayerService.mergeNode({
                    node,
                    folder: splitEntireImagePath.join('/')
                })

                const backgroundImage = new BackgroundImage(response.data)

                commit(MUTATIONS.REPLACE_NODE, {
                    node,
                    newNode: backgroundImage
                })
            } finally {
                commit(MUTATIONS.REMOVE_MERGING_NODE, node.uuid)
            }
        }
    }
}
