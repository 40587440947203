<template>
  <b-modal
    v-model="isOpened"
    title="Catalog Management"
    centered
    ok-title="Go to catalog"
    :ok-disabled="!(handledCatalog || selectedCatalog)"
    @ok="goToCatalog"
    @cancel="onClose"
  >
    <b-tabs content-class="mt-3">
      <b-tab
        title="Create"
        active
        @click="onTabClick"
      >
        <div role="group">
          <label for="input-live">Name of catalog:</label>
          <b-form-input
            v-model="name"
            :state="nameState"
            placeholder="Enter name"
            aria-describedby="input-live-feedback"
            trim
          />
          <b-form-invalid-feedback id="input-live-feedback">
            Enter a name of at least 1 and less than 255 chars.
          </b-form-invalid-feedback>
        </div>
        <b-button
          class="mt-3"
          variant="primary"
          :disabled="!nameState"
          @click="create"
        >
          Create catalog
        </b-button>
      </b-tab>
      <b-tab 
        title="Catalogs" 
        @click="onTabClick"
      >
        <b-pagination
          v-model="catalogsPage"
          :total-rows="catalogsCount"
          :per-page="catalogsPerPage"
          @change="getCatalogList"
        />
        <b-list-group>
          <b-list-group-item
            v-for="catalog in catalogs"
            :key="catalog.id"
            class="cursor-pointer"
            :active="selectedCatalog === catalog"
            @click="selectedCatalog = catalog"
          >
            {{ catalog.name }}
          </b-list-group-item>
        </b-list-group>
        <b-button
          class="mt-3"
          variant="primary"
          :disabled="!selectedCatalog"
          @click="addToCatalog"
        >
          Add to selected catalog
        </b-button>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import {
  CATALOG_MANAGEMENT_EVENTS,
  catalogManagementEventBroker
} from "@frontend/group/modules/catalog-management/event-broker";
import CatalogService from '@/frontend/services/api/catalogs'

const initialState = () => ({
  isOpened: false,
  name: 'New Catalog',
  layoutIds: [],
  catalogs: [],
  selectedCatalog: undefined,
  catalogsPage: 1,
  catalogsPerPage: undefined,
  catalogsCount: undefined,
  handledCatalog: undefined,
})
export default {
  name: "CatalogManagementModal",
  data: initialState,
  computed: {
    nameState() {
      return this.name.length <= 255 && this.name.length > 0
    }
  },
  mounted() {
    catalogManagementEventBroker.on(CATALOG_MANAGEMENT_EVENTS.INIT, this.init.bind(this))
  },
  methods: {
    init({ layoutIds }) {
      this.isOpened = true;
      this.layoutIds = layoutIds;

      this.getCatalogList(this.catalogsPage);
    },
    async getCatalogList(page) {
      const { data } = await CatalogService.list(page)
      this.catalogs = data.catalogs;
      this.catalogsCount = data.count;
      this.catalogsPerPage = data.perPage;
    },
    async create(event) {
      event.preventDefault()
      try {
        const { data } = await CatalogService.create({
          name: this.name,
          layoutIds: this.layoutIds,
        });

        this.handledCatalog = data.id;
        this.catalogs.unshift(data);

        toastr.success('Catalog created')
      } catch (error) {
        toastr.error('Something went wrong')
      }
    },
    async addToCatalog() {
      try {
        await CatalogService.update(this.selectedCatalog.id, {
          layoutIds: this.layoutIds
        });

        this.handledCatalog = this.selectedCatalog.id;

        toastr.success('Catalog updated')
      } catch (error) {
        toastr.error('Something went wrong')
      }
    },
    goToCatalog() {
      const lnk = document.createElement('a');
      lnk.href = `/catalogs/${this.handledCatalog || this.selectedCatalog.id}`;
      lnk.target = 'blank';
      lnk.click();
      this.onClose();
    },
    onClose() {
      const initial = initialState();
      Object.keys(initial).forEach(key => {
        this[key] = initial[key]
      });
    },
    onTabClick() {
      const initial = initialState();
      this.selectedCatalog = initial.selectedCatalog;
      this.handledCatalog = initial.handledCatalog;
      this.name = initial.name;
    }
  }
}
</script>

<style scoped lang="scss">

</style>
