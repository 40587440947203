<template>
  <div class="c-folder-layer__item d-flex align-items-center mt-2">
    <div class="c-folder-layer__item--preview mr-2">
      <div class="a-text-layer">
        <b-icon
          icon="textarea-t"
          class="d-block"
        />
      </div>
    </div>
    <div>
      {{ node.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TextLayer",
  props: {
    node: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped lang="scss">
.a-text-layer {
  font-size: 24px;
}
</style>
