var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Multiselect", {
        attrs: {
          label: "name",
          "track-by": "id",
          options: _vm.items,
          "show-labels": true,
        },
        on: { input: _vm.emitSelection },
        model: {
          value: _vm.selectedFolder,
          callback: function ($$v) {
            _vm.selectedFolder = $$v
          },
          expression: "selectedFolder",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }