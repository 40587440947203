var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-inline-block" },
    [
      _c(
        "b-dropdown",
        {
          attrs: {
            text: "Export",
            variant: "outline-primary",
            size: "sm",
            disabled: _vm.disabled,
          },
        },
        [
          _c(
            "b-dropdown-item",
            {
              on: {
                click: function ($event) {
                  return _vm.downloadImage(_vm.formats.PNG)
                },
              },
            },
            [_vm._v("\n      PNG\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-dropdown-item",
            {
              on: {
                click: function ($event) {
                  return _vm.downloadImage(_vm.formats.TIFF)
                },
              },
            },
            [_vm._v("\n      TIFF\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-dropdown-item",
            {
              on: {
                click: function ($event) {
                  return _vm.downloadClippedTiff()
                },
              },
            },
            [_vm._v("\n      Clipped TIFF\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-dropdown-item",
            {
              on: {
                click: function ($event) {
                  return _vm.downloadClippedTiff(true)
                },
              },
            },
            [_vm._v("\n      Clipped Product TIFF\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-dropdown-item",
            {
              on: {
                click: function ($event) {
                  return _vm.downloadImage(_vm.formats.JPG)
                },
              },
            },
            [_vm._v("\n      JPG\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-dropdown-item",
            {
              on: {
                click: function ($event) {
                  return _vm.downloadPDF()
                },
              },
            },
            [_vm._v("\n      PDF\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-dropdown-item",
            {
              on: {
                click: function ($event) {
                  return _vm.saveToProducts()
                },
              },
            },
            [_vm._v("\n      To Products\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-dropdown-item",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal.select-folder-modal",
                  modifiers: { "select-folder-modal": true },
                },
              ],
            },
            [_vm._v("\n      To File Browser\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "b-modal",
            {
              attrs: {
                id: "svg_file_export_modal",
                title: _vm.modalTitle,
                centered: "",
              },
              on: { shown: _vm.handleModalShown },
              scopedSlots: _vm._u([
                {
                  key: "modal-footer",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "radio-tiff" },
                        [
                          _c("b-form-group", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ ariaDescribedby }) {
                                  return [
                                    _vm.isTiffExport
                                      ? _c(
                                          "b-form-radio-group",
                                          {
                                            attrs: {
                                              id: "radio-group-tiff",
                                              "aria-describedby":
                                                ariaDescribedby,
                                              name: "select_tiff_format",
                                            },
                                            model: {
                                              value: _vm.tiffFormat,
                                              callback: function ($$v) {
                                                _vm.tiffFormat = $$v
                                              },
                                              expression: "tiffFormat",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "radio-title" },
                                              [_vm._v("File extension: ")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-form-radio",
                                              { attrs: { value: "tiff" } },
                                              [
                                                _vm._v(
                                                  "\n                TIFF\n              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-form-radio",
                                              { attrs: { value: "tif" } },
                                              [
                                                _vm._v(
                                                  "\n                TIF\n              "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            on: { click: _vm.close },
                          },
                          [_vm._v("\n            Cancel\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            on: { click: _vm.handleSave },
                          },
                          [_vm._v("\n            Save\n          ")]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                { staticClass: "f-name" },
                [
                  _c("b-form-input", {
                    attrs: { type: "text", placeholder: "File name" },
                    model: {
                      value: _vm.fileName,
                      callback: function ($$v) {
                        _vm.fileName = $$v
                      },
                      expression: "fileName",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "icon-btn",
                      attrs: {
                        variant: "light",
                        title: "Paste from clipboard",
                      },
                      on: { click: _vm.onPasteFromClipboard },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "icon-clipboard",
                        attrs: { icon: "clipboard" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isExportToFileBrowser
                ? _c("div", { staticClass: "mt-3" }, [
                    _c(
                      "select",
                      {
                        staticClass: "form-control form-control-sm",
                        attrs: { id: "file-format" },
                        domProps: { value: _vm.selectedFormat },
                        on: { input: _vm.setExportFormat },
                      },
                      _vm._l(_vm.formats, function (format, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: format } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(format.toUpperCase()) +
                                "\n          "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("SelectFolderModal", {
            on: { onSelectedItems: _vm.selectFolder },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }