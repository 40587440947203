<template>
  <div>
    <b-form-row class="mb-3">
      <b-col>
        <Multiselect
          class="multiselect-customer"
          track-by="name"
          label="name"
          placeholder="Select Customer"
          :value="value.customer"
          :options="customers"
          :show-labels="false"
          @input="onChangeCustomer"
        />
      </b-col>
      <b-col>
        <Multiselect
          v-model="tags"
          placeholder="Filter Templates"
          multiple
          :loading="isLoading"
          :options="getTags"
          :close-on-select="false"
          :show-labels="false"
          @input="onChangeTags"
        />
      </b-col>
    </b-form-row>
    <b-form-group>
      <template #label>
        <b-checkbox
          v-model="galleryView"
          class="float-right"
          switch
          @change="$refs.templatesSelect.activate()"
        >
          Gallery view
        </b-checkbox>
      </template>
      <Multiselect
        ref="templatesSelect"
        :class="['templates-dropdown', {'gallery-view': galleryView}]"
        track-by="name"
        label="name"
        placeholder="Select Template"
        multiple
        group-label="label"
        group-values="values"
        :group-select="true"
        :loading="isLoading"
        :value="value.templates"
        :options="getTemplates"
        :close-on-select="false"
        :show-labels="false"
        @input="(value) => onChange('templates', value)"
      >
        <template #option="props">
          <img
            v-if="!props.option.$groupLabel"
            :src="resolveUrl(props.option.image_url)"
            :alt="props.option.name"
          >
          <span>{{ props.option.$groupLabel || props.option.name }}</span>
        </template>
      </Multiselect>
    </b-form-group>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import TEMPLATE_API from '@frontend/services/api/template';

export default {
  components: { Multiselect },
  props: {
    value: {
      type: Object,
      default: () => ({
        customer: null,
        templates: []
      })
    },
    customers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      templatesFilteredByTags: {},
      templates: {},
      tags: [],
      galleryView: false,
    };
  },
  computed: {
    getTemplates () {
      return [
        {
          label: 'Select all',
          values: this.templatesFilteredByTags[this.value.customer?.id] || [],
        },
      ]
    },
    getTags() {
      const tags = this.templates[this.value.customer?.id]
        ?.filter(item => item.tags)
        ?.map(item => item.tags);
      
      return [...new Set(tags)].length ? [...new Set(tags)] : [];
    }
  },
  
  async mounted() {
    if (this.value.customer) {
      await this.fetchTemplates()
    }
  },
  methods: {
    onChange(key, value) {
      this.$emit('input', {
        ...this.value,
        [key]: value
      });
    },
    onChangeTags() {
      let filteredTemplates = [];

      if (!this.tags.length) {
        return this.templatesFilteredByTags = {
          ...this.templatesFilteredByTags,
          [this.value.customer?.id]: this.templates[this.value.customer?.id]
        }
      }

      this.tags.forEach(tag => {
        const templatesByTag = this.templates[this.value.customer?.id].filter(item => item.tags === tag);

        filteredTemplates = filteredTemplates.concat(templatesByTag);
      });

      this.templatesFilteredByTags[this.value.customer?.id] = filteredTemplates;
    },
    async onChangeCustomer(selectedCustomer) {
      const selectedCustomerId = selectedCustomer.id;
      this.tags = [];
      this.onChangeTags()
      this.filteredTemplates = {
        ...this.filteredTemplates,
        [selectedCustomerId]: this.templates[selectedCustomerId]
      }

      this.$emit('input', {
        customer: selectedCustomer,
        templates: []
      });

      if (this.templates[selectedCustomerId]) return;

      await this.fetchTemplates(selectedCustomerId)
    },
    async fetchTemplates(id = null) {
      const selectedCustomerId = id || this.value.customer.id
      this.isLoading = true;

      try {
        const { data } = await TEMPLATE_API.getTemplatesByCustomer(selectedCustomerId);

        this.templates = {
          ...this.templates,
          [selectedCustomerId]: data
        };

        this.templatesFilteredByTags = {
          ...this.templatesFilteredByTags,
          [selectedCustomerId]: data
        };

      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    resolveUrl(imgUrl) {
      return imgUrl?.length 
        ? `${window.location.origin}/share?file=${imgUrl}` 
        : '/images/image.svg';
    }
  }
}
</script>

<style lang="scss">
.multiselect-customer {
  .multiselect__single {
    margin-bottom: 13px;
  }
}

.templates-dropdown {
  img {
    width: 2em;
    height: 2em;
    object-fit: contain;
    margin-right: .5rem;
  }

  &.gallery-view {
    .multiselect__content {
      display: flex !important;
      flex-wrap: wrap;
    }

    .multiselect__element {
      width: calc(100% / 4);

      &:first-of-type {
        width: 100%;
      }
    }

    .multiselect__option {
      white-space: unset;
      word-break: break-all;
      height: 100%;
    }

    img {
      width: unset;
      max-width: 100%;
      height: auto;
    }
  }
}
</style>
