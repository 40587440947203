<template>
  <div class="a-color-picker">
    <b-button
      ref="colorPickerBtn"
      variant="light"
      class="rounded color-input-btn"
    >
      <div class="a-color-picker__preview" />
    </b-button>

    <b-popover
      :target="popoverTarget"
      triggers="focus"
    >
      <Compact
        :value="value"
        :palette="PALETTE"
        @input="onChange"
      />
      <Chrome
        :value="value"
        @input="onChange"
      />
    </b-popover>
  </div>
</template>

<script>
import { Chrome, Compact } from 'vue-color'

const PALETTE = [
  '#980000',
  '#FF0000',
  '#FF9900',
  '#FFFF00',
  '#00FF00',
  '#00FFFF',
  '#4A86E8',
  '#0000FF',
  '#9900FF',
  '#FF00FF',
  '#000000',
  '#FFFFFF'
]
export default {
  name: "ColorPickerAtom",
  components: {
    Compact,
    Chrome
  },
  props: {
    value: {
      type: String,
      default: () => '#FFFFFF'
    },
    format: {
      type: String,
      default: 'hex',
    },
  },
  computed: {
    PALETTE() {
      return PALETTE;
    },
  },
  methods: {
    popoverTarget() {
      return this.$refs.colorPickerBtn
    },
    onChange (color) {
      let val = color[this.format]
      switch (this.format) {
        case 'rgba':
          val = `rgba(${val.r}, ${val.g}, ${val.b}, ${val.a})`
          break
      }
      this.$emit('input', val)
    },
  }
}
</script>

<style scoped lang="scss">
  @import "../styles/rounded";

  .a-color-picker {
    &__preview {
      width: 37px;
      height: 37px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      background-color: v-bind(value);
    }
  }

  .color-input-btn {
    width: 39px;
    height: 39px;
  }
</style>
