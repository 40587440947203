import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { lab } from 'd3-color';
import Shapes from './Shapes';
import { serializerFactory } from '../../group/modules/generate-quick-download/simple-object-serializer';
import { EventBroker } from '../../lib/utils/event-broker';
import { SVG_EDITOR_EVENTS } from './events';
import OCRService from '../api/ocr';
import SvgImageEditor from '../../services/api/svg-image-editor';
import { fileLoaded, getSelectionBoxAsImageInstance } from './canvas-trait';
import svgEditor from '@frontend/store/svg-editor';
import { FILE_TYPE } from '@frontend/constants/file-image-types';
import { downloadFileByBlob } from '@frontend/services/helpers';

export default class ProductCanvas {
  static SVG_STORE = 'svgEditor/';
  static FILE_BROWSER_STORE = 'fileBrowser/';
  static PNG = 'png';
  static PDF = 'pdf';
  static PNG_TYPE = 'image/png'
  static SUFFIXES = ['_MobileHero'];
  static PRODUCT_ID = 'bgProductImage';
  static COLOR_DIFF_PERCENT = 5;
  static MOVE = 'move';
  static CROSS_HAIR = 'crosshair';
  static BUCKET_CURSOR = 'url(/images/paint-bucket-icon.png) 1.5 26, auto';
  static PASTE_AREA = 'pasteArea'
  static FABRIC_OBJECTS = [
    fabric.Path,
    fabric.Circle,
    fabric.Ellipse,
    fabric.Rect,
    fabric.Triangle,
    fabric.IText
  ];
  static TEXT_CURSOR = 'text';
  static DEFAULT_DIMENSION = 3000;
  static DEFAULT_ELEMENT_HEIGHT = 1024;
  static SQUARE_ORIENTATION = 'square';
  static TALL_ORIENTATION = 'tall';
  static CROP_AREA_REC = 'cropAreaRec'
  static BASIC_TYPES = ['circle', 'ellipse', 'rect', 'triangle', 'path', 'textbox'];
  static SHAPES_TYPES = ['circle', 'ellipse', 'rect', 'triangle'];
  static TRANSPARENT = 'transparent';
  static REPLACE_COLOR_MODE = 'replace_color';
  static REPLACE_GRADIENT_MODE = 'replace_gradient';
  
  canvas;
  vueStore;
  eventBroker;
  initImage = {
    path: null,
    name: null,
    width: null,
    height: null
  };
  isPaintBucketOn = false;
  isDrawingModeOn = false;
  colorToDraw = '#000000';
  freeDrawingBrush= null;
  isRectanglePAMode = false;
  rectanglePA = null;
  pointerStartX = 0;
  pointerStartY = 0;
  ShapeInstance = null;
  shapeType = null;
  brushSize = 5;
  isDrawTextMode = false;
  textRect = null;
  textModalRef = null;
  canvasElement = null;
  isPDFExport = false;
  isCropAreaMode = false;
  cropArea = null;
  loadingProcess = false;
  strokeWidth = 2;
  shapeModalRef = null;
  bucketFillMode = ProductCanvas.REPLACE_COLOR_MODE;

  constructor(canvasElement, store, height = ProductCanvas.DEFAULT_ELEMENT_HEIGHT) {
    this.canvasElement = canvasElement;
    this.canvasElement.width = Number((window.innerWidth * 0.4).toFixed(0));
    ProductCanvas.DEFAULT_ELEMENT_HEIGHT = height;
    this.canvasElement.height = height;
    this.canvas = new fabric.Canvas(this.canvasElement, {
      preserveObjectStacking: true,
    });
    this.vueStore = store;
    this.eventBroker = new EventBroker();
    this.updateProductInstance();
    this.bindEvents();
  }

  //traits
  getSelectionBoxAsImageInstance = getSelectionBoxAsImageInstance
  fileLoaded = fileLoaded;

  get selectionBoxIds() {
    return [ProductCanvas.CROP_AREA_REC]
  }

  get multiplier() {
    return 5
  }

  bindEvents() {
    this.canvas.on('mouse:up', this.onMouseUp.bind(this));
    this.canvas.on('mouse:down:before', this.onMouseDownBefore.bind(this));
    this.canvas.on('mouse:down', this.onMouseDown.bind(this));
    this.canvas.on('object:modified', this.onObjectModified.bind(this));
    this.canvas.on('before:selection:cleared', this.beforeOnSelectionCleared.bind(this));
    this.canvas.on('selection:cleared', this.onSelectionCleared.bind(this));
    this.canvas.on('mouse:move', this.onMouseMove.bind(this));
  }

  on(...args) {
    this.eventBroker.on(...args)
  }

  onObjectModified() {
    this.updateProductInstance();
  }

  beforeOnSelectionCleared() {
    this.removeEmptyText();
  }

  onSelectionCleared() {
    this.updateProductInstance();
  }

  onMouseDownBefore() {
    this.colorToDraw = this.getColorToDraw();
    this.brushSize = this.getBrushSize();
    this.strokeWidth = this.getStrokeWidth();
    this.onDrawMode();
  }

  onMouseUp() {
    const hasPasteArea = this.canvas.getActiveObjects().some(o => o.id === ProductCanvas.PASTE_AREA);
    
    if (!hasPasteArea) {
      this.discardActiveObjectOnSvgCanvas();
    }

    if (this.isRectanglePAMode) {
      this.stopRectanglePasteArea();
    }

    if (this.ShapeInstance) {
      this.stopShapeDrawing();
    }

    if (this.isDrawTextMode) {
      this.stopTextRectDrawing();
    }
    
    if (this.isCropAreaMode) {
      this.stopCropArea();
    }

    this.updateProductInstance();
  }

  onMouseDown(event) {
    this.setPointerStart(event);
    
    if (this.isPaintBucketOn) {
      const pointer = this.canvas.getPointer(event);
      const clickedObject = event.target;

      if (this.isPointInsideObject(pointer, clickedObject)) {
        this.vueStore.dispatch(`${ProductCanvas.FILE_BROWSER_STORE}setIsSvgLoading`, true);
        setTimeout(() => {
          this.floodFillGradient(clickedObject, new fabric.Color(this.colorToDraw), pointer.x, pointer.y);
          this.canvas.renderAll();
          this.vueStore.dispatch(`${ProductCanvas.FILE_BROWSER_STORE}setIsSvgLoading`, false);
        }, 300);
      }
    }

    if (this.isRectanglePAMode) {
      this.createPasteArea();
    }

    if (this.shapeType) {
      this.createShape();
    }

    if (this.isDrawTextMode) {
      this.createTextRect();
    }
    
    if (this.isCropAreaMode) {
      this.createCropArea();
    }
  }

  onMouseMove(event) {
    if (this.isRectanglePAMode) {
      this.drawPasteArea(event);
    }

    if (this.ShapeInstance) {
      this.drawShape();
    }
    
    if (this.isCropAreaMode) {
      this.drawCropArea();
    }
  }
  
  onKeyUp(e) {
    switch (e.keyCode) {
      // Delete key
      case 46:
      case 8:
        return this.deleteSelectedObjects();
      // Enter key
      case 13:
      case 36:
      case 76:
        this.openTextSettingsModal();
        this.applyCropArea();
        this.openShapeSettingsModal();
        break;
        // p key
      case 80:
        return this.cloneBasicObject();
        // b key
      case 66:
        return this.lockMovement();
        // g key
      case 71:
        return this.groupUngroupActiveObjects();
    }
  }
  
  onKeyDown(e) {
    switch(e.keyCode) {
      case 37: // left
      case 38: // up
      case 39: // right
      case 40: // down
        this.moveObjectByArrows(e);
        break;
    }
  }

  moveObjectByArrows(event) {
    const selectedObject = this.canvas.getActiveObject();

    if (selectedObject) {
      const arrowDirections = {
        37: { property: 'left', value: -1 }, // left
        38: { property: 'top', value: -1 }, // up
        39: { property: 'left', value: 1 }, // right
        40: { property: 'top', value: 1 }, // down
      };

      const direction = arrowDirections[event.keyCode];

      if (direction) {
        event.preventDefault();
        
        selectedObject.set({ [direction.property]: selectedObject[direction.property] + direction.value });
        selectedObject.setCoords();
        selectedObject.canvas.renderAll();
      }
    }
  }

  discardActiveObjectOnSvgCanvas() {
    const isSvgInstanceHasSelectedObjects = this.vueStore.getters[`${ProductCanvas.SVG_STORE}isSvgInstanceHasSelectedObjects`];

    if (isSvgInstanceHasSelectedObjects) {
      const getSvgInstance = this.vueStore.getters[`${ProductCanvas.SVG_STORE}getSvgInstance`];
      getSvgInstance.discardActiveObject().renderAll();
    }
  }

  getObjects() {
    return this.canvas.getObjects();
  }

  getObjectById(id) {
    return this.getObjects().find((o) => o.id === id);
  }

  hasOnlyOneObjectOnCanvas() {
    return this.getObjects().length === 1;
  }

  getFileName(inputString, substringsToRemove) {
    let modifiedFileName = inputString;

    for (const substring of substringsToRemove) {
      const regex = new RegExp(`${substring}`, 'g');
      modifiedFileName = modifiedFileName.replace(regex, '');
    }

    modifiedFileName = modifiedFileName.replace(/\.[^/.]+$/, '');

    return `${modifiedFileName}${substringsToRemove[0]}`;
  }

  setImageData(fileData) {
    const img = new Image();
    
    img.onload = () => {
      this.initImage.path = fileData.path;
      this.initImage.name = this.getFileName(fileData.name, ProductCanvas.SUFFIXES);
      this.initImage.width = img.width;
      this.initImage.height = img.height;
      this.vueStore.dispatch(`${ProductCanvas.SVG_STORE}setBgImageInstance`, this.initImage);
    };
    
    img.src = fileData.path;
  }
  
  removePrevBgImage() {
    this.canvas.getObjects().forEach((obj) => {
      if (obj.id === ProductCanvas.PRODUCT_ID) {
        this.canvas.remove(obj);
      }
    });
  }
  
  async drawImageFromURL(fileData, isReset = false) {
    try {
      this.clearSelections();
      this.setImageData(fileData);
      this.removePrevBgImage();

      this.isPaintBucketOn = isReset ? this.isPaintBucketOn : false;
      
      const dataUrl = await this.makePNGDataUrl(fileData.path);
      
      fabric.Image.fromURL(dataUrl, (img, isError) => {
        if (isError) return toastr.error('File loading fail');
        
        img.set({
          scaleX: Math.min(this.canvas.width / img.width, this.canvas.height / img.height),
          scaleY: Math.min(this.canvas.width / img.width, this.canvas.height / img.height),
          selectable: false,
          hoverCursor: this.isPaintBucketOn ? ProductCanvas.BUCKET_CURSOR : ProductCanvas.MOVE,
          id: ProductCanvas.PRODUCT_ID
        });

        this.canvas.insertAt(img, 0).renderAll();
        isReset ? toastr.success('File reloaded') : toastr.success('File loaded');
        this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED)
        this.updateProductInstance();
      }); 
    } catch (error) {
      console.log(error);
    }
  }

  makePNGDataUrl(imgSrc) {
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(imgSrc, (img, isError) => {
        if (isError) {
          reject('File loading fail');
          toastr.error('File loading fail');
        }
        
        img.set({
          scaleX: Math.min(this.canvas.width / img.width, this.canvas.height / img.height),
          scaleY: Math.min(this.canvas.width / img.width, this.canvas.height / img.height),
        });
        
        resolve(img.toDataURL({ format: ProductCanvas.PNG, multiplier: 5 }));
      });
    });
  }

  async pasteImageFromUrl(imgSrc, toCenter = false) {
    try {
      const dataUrl = await this.makePNGDataUrl(imgSrc);

      fabric.Image.fromURL(dataUrl, (img, isError) => {
        if (isError) return toastr.error('File loading fail');

        img.set({
          scaleX: Math.min(this.canvas.width / img.width, this.canvas.height / img.height),
          scaleY: Math.min(this.canvas.width / img.width, this.canvas.height / img.height),
        });

        this.canvas.add(img);

        if (toCenter) {
          img.set({
            scaleX: 0.9 * Math.min(this.canvas.width / img.width, this.canvas.height / img.height),
            scaleY: 0.9 * Math.min(this.canvas.width / img.width, this.canvas.height / img.height),
          });

          this.canvas.viewportCenterObject(img);
        }

        this.canvas.renderAll();
        this.updateProductInstance();
        this.fileLoaded(this.vueStore.getters[`${ProductCanvas.SVG_STORE}getSvgCanvasInstance`]);
      });
    } catch (error) {
      console.error(error);
    }
  }
  
  resetToInitState() {
    this.canvas.clear();
    
    if (this.initImage.path) {
      return this.drawImageFromURL(this.initImage, true);
    }
  }

  async removeBG() {
    const selectedObject = this.canvas.getActiveObject();
    
    if (selectedObject) {
      if (selectedObject.type === 'image') {
        if (!selectedObject?.bgSet) {
          const src = selectedObject.getSrc();
          const response = await fetch(src);
          const contentType = response.headers.get('content-type');
          const blob = await response.blob();
          const file = new File([blob], 'filename.png', { type: contentType });
          const formData = new FormData();
          formData.append('file', file);
          
          axios.post('/video/remove-bg-image', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(({ data }) => {
            selectedObject.setSrc(data.data.path, function () {
              selectedObject.set({
                scaleX: selectedObject.scaleX * 1.001,
                scaleY: selectedObject.scaleY * 1.001
              });

              selectedObject.canvas.renderAll();
              selectedObject.bgSet = true;
            });
            selectedObject.bgSet = true;
            toastr.success('Background removed');
          }).catch((error) => {
            toastr.error('Remove background failed');
            console.log(error);
          });
        }
      }
    }
  }

  updateProductInstance() {
    this.vueStore.dispatch(`${ProductCanvas.SVG_STORE}setProductFabricInstance`, this.canvas);
    this.vueStore.dispatch(`${ProductCanvas.SVG_STORE}setProductCanvasInstance`, this);
  }

  getColorToDraw() {
    return this.vueStore.getters[`${ProductCanvas.SVG_STORE}getColorToDraw`];
  }

  getStrokeWidth() {
    return this.vueStore.getters[`${ProductCanvas.SVG_STORE}borderSize`];
  }

  getIsBorderShape() {
    return this.vueStore.getters[`${ProductCanvas.SVG_STORE}isBorderShape`];
  }
  
  getBrushSize() {
    return this.vueStore.getters[`${ProductCanvas.SVG_STORE}brushSize`];
  }
  
  pasteSelectedSvgInstanceAsImage(isApplySelectionBox = true) {
    const svgInstance = this.vueStore.getters[`${ProductCanvas.SVG_STORE}getSvgInstance`];
    const getSvgCanvasInstance = this.vueStore.getters[`${ProductCanvas.SVG_STORE}getSvgCanvasInstance`];
    const isSelectionBoxMode = this.vueStore.getters[`${ProductCanvas.SVG_STORE}isSelectionBoxMode`];
    const isSvgLassoMode = this.vueStore.getters[`${ProductCanvas.SVG_STORE}isSvgLassoMode`];
    const activeObject = svgInstance.getActiveObject();
    
    if  (isSelectionBoxMode && isApplySelectionBox) {
      return getSvgCanvasInstance.applySelectionBoxTarget();
    }
    
    if (isSvgLassoMode && isApplySelectionBox) {
      return getSvgCanvasInstance.applyLasso();
    }

    if (this.rectanglePA && activeObject) {
      return this.insidePasteArea(svgInstance, activeObject);
    }
    
    if (activeObject) {
      return this.pasteSelectedSvgObject(svgInstance, activeObject);
    }
  }

  insidePasteArea(svgInstance, activeObject) {
    const rectangleLeft = this.rectanglePA.left;
    const rectangleTop = this.rectanglePA.top;
    const rectangleWidth = this.rectanglePA.width * this.rectanglePA.scaleX;
    const rectangleHeight = this.rectanglePA.height * this.rectanglePA.scaleY;
    
    const objectDataURL = activeObject.toDataURL({
      format: ProductCanvas.PNG,
      multiplier: this.getMultiplier(activeObject),
    });

    fabric.Image.fromURL(objectDataURL, (image) => {
      image.set({
        left: rectangleLeft,
        top: rectangleTop,
        selectable: !this.isPaintBucketOn,
        hoverCursor: this.isPaintBucketOn ? ProductCanvas.BUCKET_CURSOR : ProductCanvas.MOVE
      });

      const scaleX = rectangleWidth / image.width;
      const scaleY = rectangleHeight / image.height;
      const scale = Math.min(scaleX, scaleY);

      image.scale(scale);
      image.setCoords();
      
      this.deletePasteArea();
      
      this.canvas.add(image);
      svgInstance.discardActiveObject().renderAll();
      this.updateProductInstance();
    });
  }
  
  pasteSelectedSvgObject(svgInstance, activeObject) {
    const zoomLevel = svgInstance.getZoom();
    const objectDataURL = activeObject.toDataURL({
      format: ProductCanvas.PNG,
      multiplier: this.getMultiplier(activeObject),
    });

    fabric.Image.fromURL(objectDataURL, (image) => {
      image.set({
        left: activeObject.left * zoomLevel,
        top: activeObject.top * zoomLevel,
        scaleX: zoomLevel / this.getMultiplier(activeObject),
        scaleY: zoomLevel / this.getMultiplier(activeObject),
        selectable: !this.isPaintBucketOn,
        hoverCursor: this.isPaintBucketOn ? ProductCanvas.BUCKET_CURSOR : ProductCanvas.MOVE
      });

      this.canvas.add(image);
      svgInstance.discardActiveObject().renderAll();
      this.updateProductInstance();
    });
  }
  
  getMultiplier(activeObject) {
    if (activeObject.scaleX > 2 || activeObject.scaleY > 2) {
      return 1
    }
    
    if (activeObject.scaleX < 1 || activeObject.scaleY < 1) {
      return 5
    }
    
    return 3
  }

  setPointerStart(event) {
    const pointer = this.canvas.getPointer(event);

    this.pointerStartX = pointer.x;
    this.pointerStartY = pointer.y;
  }

  createPasteArea() {
    this.rectanglePA = new fabric.Rect({
      id: ProductCanvas.PASTE_AREA,
      left: this.pointerStartX,
      top: this.pointerStartY,
      strokeDashArray: [5, 5],
      fill: 'rgba(255,255,255,0)',
      stroke: 'black',
      selectable: true,
      lockRotation: true,
    });

    this.rectanglePA.setControlVisible('mtr', false);
    this.canvas.add(this.rectanglePA);
  }

  drawPasteArea(event) {
    if (!this.rectanglePA) return;

    const pointer = this.canvas.getPointer(event);

    this.rectanglePA.set({
      left: Math.min(pointer.x, this.pointerStartX),
      top: Math.min(pointer.y, this.pointerStartY),
      width: Math.abs(pointer.x - this.pointerStartX),
      height: Math.abs(pointer.y - this.pointerStartY),
    });

    this.canvas.renderAll();
  }

  pasteAreaMode() {
    this.isRectanglePAMode = !this.isRectanglePAMode;
    const objects = this.canvas.getObjects();

    if (this.isRectanglePAMode) {
      this.deletePasteArea();
      
      objects.forEach((obj) => {
        obj.set({ selectable: false, hoverCursor: ProductCanvas.CROSS_HAIR });
      });
    } else {
      objects.forEach((obj) => {
        obj.set({ selectable: (obj.id !== ProductCanvas.PRODUCT_ID), hoverCursor: ProductCanvas.MOVE });
      });
    }

    this.canvas.discardActiveObject().renderAll();
  }

  stopRectanglePasteArea() {
    this.pasteAreaMode();
    this.rectanglePA.setCoords();
  }

  stopShapeDrawing() {
    this.ShapeInstance.getShape().setCoords();
    this.shapeType = null;
    this.ShapeInstance = null;
    this.makeObjectsSelectable();
  }
  
  makeObjectsSelectable() {
    const objects = this.canvas.getObjects();
    objects.forEach((obj) => {
      obj.set({
        selectable: obj.id !== ProductCanvas.PRODUCT_ID,
        hoverCursor: ProductCanvas.MOVE
      });
    });

    this.canvas.defaultCursor = ProductCanvas.MOVE;
  }

  makeObjectsUnselectable() {
    const objects = this.canvas.getObjects();
    objects.forEach((obj) => {
      obj.set({
        selectable: false,
        hoverCursor: ProductCanvas.CROSS_HAIR
      });
    });

    this.canvas.defaultCursor = ProductCanvas.CROSS_HAIR;
  }
  
  clearShape() {
    this.ShapeInstance = null;
    this.shapeType = null;
    this.makeObjectsSelectable();
  }

  createShape() {
    this.ShapeInstance = new Shapes({
      canvas: this.canvas,
      shapeType: this.shapeType,
      x: this.pointerStartX,
      y: this.pointerStartY,
      color: this.getIsBorderShape() ? ProductCanvas.TRANSPARENT : this.colorToDraw,
      strokeColor: this.colorToDraw,
      strokeWidth: this.strokeWidth,
      isStrokeShape: this.getIsBorderShape(),
    });
    
    this.ShapeInstance.createShape();
  }

  drawShape() {
    this.ShapeInstance.drawShape();
  }

  initShapeDrawing(shape) {
    this.shapeType = shape;
    this.makeObjectsUnselectable();
    this.canvas.discardActiveObject().renderAll();
  }
  
  async downloadImage(format, fileName) {
    try {
      this.clearSelections();
      const blob = await this.getBlobFromDataUrl();
      const formData = new FormData();
      formData.append('image', blob);
      const { data: { path } } = await SvgImageEditor.downloadImage(format, formData);
      this.createDownloadLink(path, format, fileName);
      this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED);
    } catch (error) {
      toastr.error('image download error');
      console.log(error);
      this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED);
    }
  }
  
  async getObjectPositions() {
    const clonedObjects = await this.cloneObjectsState();
    const canvas = this.prepareCanvasObjects();
    const objects = canvas.getObjects();
    const positions = objects.map(obj => ({
      top: obj.top,
      left: obj.left,
      width: obj.width * obj.scaleX,
      height: obj.height * obj.scaleY,
    }));
    this.restoreCanvasObjects(clonedObjects);

    return positions;
  }

  async getClippedTiffFormData(fileName, format) {
    const paths = await this.getObjectPositions();
    const blob = await this.getBlobFromDataUrl();
    const formData = new FormData();
    formData.append('paths', JSON.stringify(paths));
    formData.append('file', blob);
    formData.append('output_filename', `${fileName}.${format}`);

    return formData;
  }
  
  async getClippedData(formData) {
    if (this.getIsProductImage()) {
      return SvgImageEditor.createProductClippingTiff(formData);
    }

    return SvgImageEditor.createClippingTiff(formData);
  }

  async downloadClippedTiff(format, fileName) {
    try {
      this.clearSelections();
      const formData = await this.getClippedTiffFormData(fileName, format);
      const { data } = await this.getClippedData(formData);
      this.createDownloadLink(data.url, format, fileName);
      this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED);
    } catch (error) {
      toastr.error('download clipped TIFF error');
      this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED);
    }
  }

  prepareCanvasObjects() {
    let width;
    let height;
    let imageOffsetX = 0;
    let imageOffsetY = 0;

    if (this.initImage.width && this.initImage.height) {
      const bgImage = this.getObjectById(ProductCanvas.PRODUCT_ID);
      const point = bgImage.getPointByOrigin('left', 'top');

      width = this.initImage.width;
      height = this.initImage.height;
      imageOffsetX = point.x;
      imageOffsetY = point.y;
    } else {
      width = this.canvas.width;
      height = this.canvas.height;

      const ratio = width / height;
      width = ProductCanvas.DEFAULT_DIMENSION;
      height = ProductCanvas.DEFAULT_DIMENSION / ratio;
    }

    const scaleX = width / this.canvas.width;
    const scaleY = height / this.canvas.height;
    const scale = Math.max(scaleX, scaleY);
    const tempCanvas = new fabric.Canvas();
    
    tempCanvas.setWidth(Math.floor(width));
    tempCanvas.setHeight(Math.floor(height));
    tempCanvas.setBackgroundColor(this.canvas.get('backgroundColor'));
    
    if (this.canvas.get('backgroundColor') !== 'transparent' && this.isPDFExport) {
      const fullCanvasRect = new fabric.Rect({
        top: 0,
        left: 0,
        width: this.canvas.width,
        height: this.canvas.height,
        fill: this.canvas.get('backgroundColor'),
        selectable: false,
      });

      this.canvas.add(fullCanvasRect);
      this.canvas.moveTo(fullCanvasRect, 0);
    }

    const objects = this.canvas.getObjects();

    for (const object of objects) {
      if (object.type === 'group') {
        console.log(object);
        object.set({
          scaleX: object.scaleX * scale,
          scaleY: object.scaleY * scale,
          top: (object.top - imageOffsetY) * scale,
          left: (object.left - imageOffsetX) * scale
        });

        tempCanvas.add(object);
        
        // temporarily commented
        // object.getObjects().forEach((obj) => {
        //   const clone = fabric.util.object.clone(obj);
        //   const matrix = object.calcTransformMatrix();
        //   const finalPosition = fabric.util.transformPoint({ x: clone.left, y: clone.top }, matrix);
        //   const scaleY = clone.scaleY;
        //   const scaleX = clone.scaleX;
        //
        //   clone.set({
        //     scaleY: scaleY,
        //     scaleX: scaleX,
        //     top: finalPosition.y,
        //     left: finalPosition.x,
        //   });
        //
        //   tempCanvas.add(clone);
        // });
      } else {
        const clone = fabric.util.object.clone(object);
        clone.set({
          scaleX: clone.scaleX * scale,
          scaleY: clone.scaleY * scale,
          top: (clone.top - imageOffsetY) * scale,
          left: (clone.left - imageOffsetX) * scale
        });

        if (object instanceof fabric.Textbox && this.isPDFExport) {
          clone.set({
            fontSize: clone.fontSize * scale,
            lineHeight: 1,
          });
        }

        tempCanvas.add(clone);
      }
    }

    return tempCanvas;
  }
  
  async cloneObjectsState() {
    const objects = this.canvas.getObjects();
    const cloningPromises = [];

    for (const object of objects) {
      const promise = new Promise(resolve => {
        object.clone(clonedObj => {
          clonedObj.set({ id: object.id });
          
          if (object instanceof fabric.Image) {
            clonedObj.set({
              ...object,
              scaleX: object.scaleX,
              scaleY: object.scaleY,
            });
          }
          
          resolve(clonedObj);
        });
      });

      cloningPromises.push(promise);
    }

    return Promise.all(cloningPromises);
  }
  
  restoreCanvasObjects(clonedObjects) {
    const bgColor = this.canvas.get('backgroundColor');

    this.canvas.clear();

    for (const object of clonedObjects) {
      this.canvas.add(object);
    }

    this.canvas.setBackgroundColor(bgColor).requestRenderAll();
  }
  
  createDownloadLink(imageDataUrl, format, fileName) {
    const link = document.createElement('a');
    link.download = `${fileName}.${format}`;
    link.href = imageDataUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  async downloadPDF(fileName) {
    this.clearSelections();
    this.isPDFExport = true;
    
    const { PDF } = ProductCanvas;
    const simpleObjectSerializer = serializerFactory({ maskSettings: [] });
    const clonedObjects = await this.cloneObjectsState();
    const canvas = this.prepareCanvasObjects();
    this.restoreCanvasObjects(clonedObjects);
    const { SQUARE_ORIENTATION } = ProductCanvas;
    const orient = this.vueStore.getters[`${ProductCanvas.SVG_STORE}getOrient`];

    try {
      const { data: resData } = await axios.post('/banner/export-template-as-file', {
        name: fileName,
        width: canvas.width,
        height: canvas.height,
        extension: PDF,
        objects: simpleObjectSerializer(canvas.getObjects()),
        has_groups: 0,
        letter_type: orient === SQUARE_ORIENTATION ? SQUARE_ORIENTATION : null,
      }, { responseType: 'blob' });

      this.createDownloadLink(window.URL.createObjectURL(new Blob([resData])), PDF, fileName);
      this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED);
    } catch (error) {
      this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED);
      console.error(error);
      toastr.error(error.message);
    }

    this.isPDFExport = false;
  }

  clearSelections() {
    this.canvas.discardActiveObject().renderAll();
  }

  bringForward() {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      activeObject.bringForward();
      this.canvas.renderAll();
    }
  }

  sendBackwards() {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      activeObject.sendBackwards();
      this.canvas.renderAll();
    }
  }
  
  
  deletePasteArea() {
    if (this.rectanglePA) {
      this.canvas.remove(this.rectanglePA);
      this.rectanglePA = null;
    }
  }

  deleteSelectedObjects() {
    const activeObjects = this.canvas.getActiveObjects();

    if (!activeObjects.length) {
      return
    }

    activeObjects.forEach(obj => {
      if (obj.id === ProductCanvas.PASTE_AREA) {
        this.deletePasteArea();
      }

      this.canvas.remove(obj)
    });
    this.clearSelections();
    this.updateProductInstance();
  }
  
  handlePaintBucket(type) {
    if (type === 'none') {
      this.isPaintBucketOn = false;
      this.vueStore.dispatch(`${ProductCanvas.SVG_STORE}setIsPaintBucketOn`, this.isPaintBucketOn);
    } else {
      this.isPaintBucketOn = true;
      this.vueStore.dispatch(`${ProductCanvas.SVG_STORE}setIsPaintBucketOn`, this.isPaintBucketOn);
      this.bucketFillMode = type;
    }
    
    this.isDrawingModeOn = false;
    this.canvas.isDrawingMode = this.isDrawingModeOn;
    this.canvas.discardActiveObject().renderAll();
    this.setPainBucketMode();
  }
  
  setPainBucketMode() {
    const objects = this.canvas.getObjects();
    
    if (this.isPaintBucketOn) {
      objects.forEach((obj) => {
        obj.set({ selectable: false, hoverCursor: ProductCanvas.BUCKET_CURSOR });
      });
    } else {
      objects.forEach((obj) => {
        obj.set({
          selectable: obj.id !== ProductCanvas.PRODUCT_ID,
          hoverCursor: ProductCanvas.MOVE
        });
      });
    }
  }

  isPointInsideObject(point, object) {
    const transformedPoint = new fabric.Point(point.x, point.y);
    
    if (object) {
      return object.containsPoint(transformedPoint);
    }
    
    return false
  }

  floodFillGradient(fabricObject, newColor, pointerX, pointerY) {
    if (fabricObject instanceof fabric.Image) {
      const imgElement = fabricObject.getElement();
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const scaleX = fabricObject.scaleX;
      const scaleY = fabricObject.scaleY;
      const width = imgElement.width;
      const height = imgElement.height;
      const x = Math.floor((pointerX / scaleX) - (fabricObject.left / scaleX));
      const y = Math.floor((pointerY / scaleY) - (fabricObject.top / scaleY));
      canvas.width = width;
      canvas.height = height;

      ctx.clearRect(0,0, width, height);
      ctx.drawImage(imgElement, 0, 0, width, height);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const color = { r: newColor._source[0], g: newColor._source[1], b: newColor._source[2], a: 255 };
      
      if (this.bucketFillMode === ProductCanvas.REPLACE_COLOR_MODE) {
        this.fillAllSimilarColors(imageData, color, x, y);
      } else {
        this.floodFillGradientCanvas(imageData, color, x, y);
      }
      
      ctx.putImageData(imageData, 0, 0);
      fabricObject.setElement(canvas);
      fabricObject.setCoords();
    } else {
      fabricObject.set({ fill: newColor.toRgb() });
      this.canvas.renderAll();
    }
  }

  getColorAtPixel(imageData, x, y) {
    const { width, data } = imageData;
    const pixelIndex = (width * y + x) * 4;

    return {
      r: data[pixelIndex],
      g: data[pixelIndex + 1],
      b: data[pixelIndex + 2],
      a: data[pixelIndex + 3],
    };
  }

  colorDiff(color1, color2) {
    // Maximum value for color components in the RGB format (255)
    const maxRGB = 255;

    // Convert colors from HEX format to RGB format
    const rgb1 = [color1.r, color1.g, color1.b, color1.a];
    const rgb2 = [color2.r, color2.g, color2.b, color2.a];

    // Calculate the difference between color components
    const diffR = rgb1[0] - rgb2[0];
    const diffG = rgb1[1] - rgb2[1];
    const diffB = rgb1[2] - rgb2[2];

    // Calculate the percentage deviation for each color component
    const percentDiffR = (Math.abs(diffR) / maxRGB) * 100;
    const percentDiffG = (Math.abs(diffG) / maxRGB) * 100;
    const percentDiffB = (Math.abs(diffB) / maxRGB) * 100;

    // Calculate the overall percentage deviation
    return (percentDiffR + percentDiffG + percentDiffB) / 3;
  }

  colorDiffDeltaE(color1, color2) {
    color1 = lab(`rgb(${color1.r}, ${color1.g}, ${color1.b})`)
    color2 = lab(`rgb(${color2.r}, ${color2.g}, ${color2.b})`)

    // deltaE
    return Math.sqrt(
      Math.pow(color1.l - color2.l, 2) +
      Math.pow(color1.a - color2.a, 2) +
      Math.pow(color1.b - color2.b, 2)
    )
  }

  setColorAtPixel(imageData, color, x, y) {
    const { width, data } = imageData;
    const index = (width * y + x) * 4;

    data[index] = color.r & 0xff;
    data[index + 1] = color.g & 0xff;
    data[index + 2] = color.b & 0xff;
    data[index + 3] = color.a & 0xff;
  }
  
  getFillIntensity() {
    return this.vueStore.getters[`${ProductCanvas.SVG_STORE}fillIntensity`];
  }
  
  getIsProductImage() {
    return this.vueStore.getters[`${ProductCanvas.SVG_STORE}isProductImage`];
  }

  fillAllSimilarColors(imageData, newColor, x, y) {
    const targetColor = this.getColorAtPixel(imageData, x, y);
    const { width, height } = imageData;

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const color = this.getColorAtPixel(imageData, x, y);

        if (this.colorDiff(color, targetColor) <= this.getFillIntensity()) {
          this.setColorAtPixel(imageData, newColor, x, y);
        }
      }
    }
  }

  floodFillGradientCanvas(imageData, newColor, x, y) {
    const { width, height } = imageData;
    const stack = [];
    const baseColor = this.getColorAtPixel(imageData, x, y);
    let operator = { x, y };

    if (this.colorDiff(baseColor, newColor) <= this.getFillIntensity()) return;

    stack.push({ x: operator.x, y: operator.y });

    while (stack.length) {
      operator = stack.pop();
      let contiguousDown = true;
      let contiguousUp = true;
      let contiguousLeft = false;
      let contiguousRight = false;

      while (contiguousUp && operator.y >= 0) {
        operator.y--;
        const pixelColor = this.getColorAtPixel(imageData, operator.x, operator.y);
        contiguousUp = this.colorDiff(pixelColor, baseColor) <= this.getFillIntensity();
      }

      while (contiguousDown && operator.y < height) {
        this.setColorAtPixel(imageData, newColor, operator.x, operator.y);

        if (operator.x - 1 >= 0) {
          const leftColor = this.getColorAtPixel(imageData, operator.x - 1, operator.y);
          
          if (contiguousLeft && this.colorDiff(leftColor, baseColor) <= this.getFillIntensity()) {
            stack.push({ x: operator.x - 1, y: operator.y });
          }
          
          contiguousLeft = this.colorDiff(leftColor, baseColor) <= this.getFillIntensity();
        }

        if (operator.x + 1 < width) {
          const rightColor = this.getColorAtPixel(imageData, operator.x + 1, operator.y);
          
          if (contiguousRight && this.colorDiff(rightColor, baseColor) <= this.getFillIntensity()) {
            stack.push({ x: operator.x + 1, y: operator.y });
          }
          
          contiguousRight = this.colorDiff(rightColor, baseColor) <= this.getFillIntensity();
        }

        operator.y++;
        const pixelColor = this.getColorAtPixel(imageData, operator.x, operator.y);
        contiguousDown = this.colorDiff(pixelColor, baseColor) <= this.getFillIntensity();
      }
    }
  }

  setDrawMode(BrushType) {
    if (BrushType !== 'None') {
      this.isDrawingModeOn = true;
      this.canvas.isDrawingMode = this.isDrawingModeOn;
      this.freeDrawingBrush = new fabric[BrushType](this.canvas);
      this.canvas.freeDrawingBrush = this.freeDrawingBrush;
      this.isPaintBucketOn = false;
      this.setPainBucketMode();
      this.canvas.discardActiveObject().renderAll();
    } else {
      this.isDrawingModeOn = false;
      this.canvas.isDrawingMode = this.isDrawingModeOn;
      this.setPainBucketMode();
      this.canvas.discardActiveObject().renderAll();
    }
  }
  
  onDrawMode() {
    if (this.isDrawingModeOn) {
      this.freeDrawingBrush.width = this.brushSize;
      this.freeDrawingBrush.color = this.colorToDraw;
    }
  }

  textRectMode() {
    this.isDrawTextMode = !this.isDrawTextMode;
    this.vueStore.dispatch(`${ProductCanvas.SVG_STORE}setTextMode`, this.isDrawTextMode);
    this.handleObjectsOnTextMode();
    this.canvas.discardActiveObject().renderAll();
  }

  handleObjectsOnTextMode() {
    const objects = this.canvas.getObjects();

    if (this.isDrawTextMode) {
      objects.forEach((obj) => {
        obj.set({ selectable: false, hoverCursor: ProductCanvas.TEXT_CURSOR });
      });
    } else {
      objects.forEach((obj) => {
        obj.set({
          selectable: obj.id !== ProductCanvas.PRODUCT_ID,
          hoverCursor: ProductCanvas.MOVE
        });
      });
    }
  }

  addEventsToTextRect() {
    this.textRect.on('added', this.onTextAdded.bind(this));
    this.textRect.on('mousedown', this.onTextMouseDown.bind(this));
    this.textRect.on('selected', this.onTextSelected.bind(this));
    this.textRect.on('changed', this.onTextChanged.bind(this));
    this.textRect.on('modified', this.onTextModified.bind(this));
    this.textRect.on('editing:entered', this.onTextEditingEntered.bind(this));
    this.textRect.on('editing:exited', this.onTextEditingExited.bind(this));
  }

  onTextAdded() {}

  onTextMouseDown() {}

  onTextSelected() {}

  onTextChanged() {}
  
  onTextModified() {}

  onTextEditingEntered() {}

  onTextEditingExited() {}

  createTextRect() {
    this.textRect = new fabric.Textbox('', {
      left: this.pointerStartX,
      top: this.pointerStartY,
      fontSize: 40,
      fontFamily: 'Avenir-Black',
      fill: this.colorToDraw,
    });

    this.addEventsToTextRect();
    this.canvas.add(this.textRect);
  }

  stopTextRectDrawing() {
    this.textRect.setCoords();
    this.textRect.enterEditing();
    this.canvas.setActiveObject(this.textRect).requestRenderAll();
  }

  removeEmptyText() {
    const activeObject = this.canvas.getActiveObject();

    if (activeObject && activeObject instanceof fabric.IText) {
      this.isDrawTextMode = false;
      this.textRect = null;
      this.vueStore.dispatch(`${ProductCanvas.SVG_STORE}setTextMode`, this.isDrawTextMode);
      this.handleObjectsOnTextMode();
      
      if (!!!activeObject.text) {
        this.canvas.remove(activeObject);
      }
    }
  }

  openTextSettingsModal() {
    if (!this.textModalRef) return
    
    const activeObject = this.canvas.getActiveObject();
    
    if (activeObject && activeObject instanceof fabric.IText) {
      this.textModalRef.show();
    }
  }

  openShapeSettingsModal() {
    if (!this.shapeModalRef) return;

    const activeObject = this.canvas.getActiveObject();
    
    if (activeObject && ProductCanvas.BASIC_TYPES.includes(activeObject.type)) {
      this.shapeModalRef.show();
    }
  }

  rotateSelectedObject(deg) {
    const activeObject = this.canvas.getActiveObject();
    
    if (activeObject) {
      activeObject.rotate(activeObject.get('angle') + deg).setCoords();
      this.canvas.renderAll();
    }
  }
  
  setCanvasBackgroundColor() { 
    const bgColor = this.canvas.get('backgroundColor');
    this.colorToDraw = this.getColorToDraw();
    this.canvas.setBackgroundColor(bgColor === this.colorToDraw ? 'transparent' : this.colorToDraw).renderAll();
  }

  centerOneObjectOnCanvas() {
    if (!this.hasOnlyOneObjectOnCanvas()) return;

    const firstobject = this.getObjects()[0];

    if (firstobject) {
      this.canvas.viewportCenterObject(firstobject);
    }
  }

  centerSelected() {
    const active = this.canvas.getActiveObject();

    this.centerOneObjectOnCanvas();

    if (!active) return;

    const bgImage = this.getObjectById(ProductCanvas.PRODUCT_ID);
    
    if (bgImage) {
      const centerPoint = bgImage.getCenterPoint();
      active.setPositionByOrigin(centerPoint, 'center', 'center');
    } else {
      this.canvas.viewportCenterObject(active);
    }

    this.canvas.renderAll();
  }
  
  removeBgImage() {
    const bgImage = this.canvas.getObjects().find((o) => o.id === ProductCanvas.PRODUCT_ID);

    if (bgImage) {
      this.canvas.remove(bgImage);
    }
  }
  
  redrawBgImage() {
    if (this.initImage.path) {
      return this.drawImageFromURL(this.initImage, true);
    }
  }

  setOrientation(orientation) {
    this.removeBgImage();
    this.updateCanvasDimensions(orientation);
    this.redrawBgImage();
  }
  
  updateCanvasDimensions(orientation) {
    this.vueStore.dispatch(`${ProductCanvas.SVG_STORE}setOrientation`, orientation);
    const json = this.canvas.toJSON();
    const { SQUARE_ORIENTATION, DEFAULT_ELEMENT_HEIGHT } = ProductCanvas;
    let widthScaling = 0.4
    if (orientation === 'landscape') {
      widthScaling = $('.svg-wrapper').width() / window.innerWidth
    }
    const innerW = Number((window.innerWidth * widthScaling).toFixed(0));
    this.canvasElement.height = orientation === SQUARE_ORIENTATION ? innerW : DEFAULT_ELEMENT_HEIGHT;
    this.canvasElement.width = innerW;
    this.canvas.setDimensions({ width: this.canvasElement.width, height: this.canvasElement.height });
    this.canvas.loadFromJSON(json, this.canvas.renderAll.bind(this.canvas));
  }
  
  async getDataUrl() {
    const clonedObjects = await this.cloneObjectsState();
    const canvas = this.prepareCanvasObjects();
    this.restoreCanvasObjects(clonedObjects);
    
    return canvas.toDataURL();
  }
  
  async getBlobFromDataUrl() {
    const dataUrl = await this.getDataUrl();
    const byteString = atob(dataUrl.split(',')[1]);
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);

    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  }
  
  getDownloadFileName() {
    return this.initImage.name ? `${this.initImage.name}` : `${uuidv4()}${ProductCanvas.SUFFIXES}`
  }
  
  async saveTo(fileName) {
    const blob = await this.getBlobFromDataUrl();
    const file = new File([blob], fileName);
    const formData = new FormData();
    
    formData.append('upload_images[]', file);
    formData.append('image_type', 'product_image');
    formData.append('company_id', '-1');
    formData.append('initiator', 'image_editor');

    axios.post('/file/uploadimg/upload_images', formData)
      .then((response) => {
        toastr.success('Saved to products');
        this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED);
      })
      .catch((error) => {
        console.log(error);
        this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED);
      });
  }
  
  async saveToFileBrowser(fileName, format, folder) {
    const blob = await this.getBlobFromDataUrl();
    const file = new File([blob], fileName);
    const formData = new FormData();
    formData.append('image', file);
    formData.append('fileName', fileName);
    formData.append('format', format);
    formData.append('folder', JSON.stringify(folder));
    
    try {
      await SvgImageEditor.saveToFileBrowser(formData);
      toastr.success('File saved to File Browser');
      this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED);
    } catch (error) {
      toastr.error('save to File Browser error');
      this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED);
    }
  }
  
  applyCropArea() {
    this.clearCropArea();
  }
  
  initCropArea() {
    if (this.cropArea) {
      this.stopCropArea();
      this.removeCropArea();
    }
    
    this.isCropAreaMode = !this.isCropAreaMode;
    this.updateCropAreaModeState();
    this.updateCanvasCursor();
  }
  
  createCropArea() {
    this.cropArea = new fabric.Rect({
      id: ProductCanvas.CROP_AREA_REC,
      left: this.pointerStartX,
      top: this.pointerStartY,
      fill: 'rgba(0,121,100,0.2)',
      stroke: '#007964',
      strokeWidth: 0.2,
      selectable: true,
      lockRotation: true,
    })

    this.cropArea.setControlVisible('mtr', false);
    this.canvas.add(this.cropArea);
  }
  
  drawCropArea() {
    if (!this.cropArea) return;

    const { x, y } = this.canvas.getPointer(event);

    this.cropArea.set({
      left: Math.min(x, this.pointerStartX),
      top: Math.min(y, this.pointerStartY),
      width: Math.abs(x - this.pointerStartX),
      height: Math.abs(y - this.pointerStartY),
    });

    this.canvas.renderAll();
  }
  
  stopCropArea() {
    this.isCropAreaMode = !this.isCropAreaMode;
    this.cropArea.setCoords();
  }
  
  removeCropArea() {
    if (this.cropArea) {
      this.canvas.remove(this.cropArea).renderAll();
      this.cropArea = null;
    }
  }

  clearCropArea() {
    if (this.cropArea) {
      this.removeCropArea();
    }

    this.isCropAreaMode = false;
    this.updateCropAreaModeState();
    this.updateCanvasCursor();
  }
  
  updateCropAreaModeState() {
    this.vueStore.dispatch(`${ProductCanvas.SVG_STORE}setCropAreaMode`, this.isCropAreaMode);
  }

  updateCanvasCursor() {
    const objects = this.canvas.getObjects();

    if (this.isCropAreaMode) {
      objects.forEach((obj) => {
        obj.set({ selectable: false, hoverCursor: ProductCanvas.CROSS_HAIR });
      });

      this.canvas.discardActiveObject().renderAll();
    } else {
      objects.forEach((obj) => {
        obj.set({
          selectable: obj.id !== ProductCanvas.PRODUCT_ID,
          hoverCursor: this.isCropAreaMode ? ProductCanvas.CROSS_HAIR : ProductCanvas.MOVE
        })
      });
    }
  }

  async convertSelectedToText() {
    const activeObject = this.cropArea
        ? await this.getSelectionBoxAsImageInstance(this.cropArea)
        : this.canvas.getActiveObject();

    const base64 = activeObject.toDataURL({
      multiplier: 5,
    })

    const image = await fetch(base64).then(r => r.blob())

    const { data } = await OCRService.recognizeImage(image)

    if (!data.text) {
      toastr.error('No text found in selected area.')
      throw new Error('Nothing recognized');
    }

    return data.text;
  }

  clearCanvas() {
    this.canvas.clear();
    this.resetInitImage();
    this.updateProductInstance();
    const SvgCanvasInstance = this.vueStore.getters[`${ProductCanvas.SVG_STORE}getSvgCanvasInstance`];
    SvgCanvasInstance.removeBgImage();
    this.vueStore.dispatch(`${ProductCanvas.SVG_STORE}setBgImageInstance`, null);
    this.vueStore.dispatch(`${ProductCanvas.SVG_STORE}setIsProductImage`, false);
  }

  resetInitImage() {
    this.initImage = {
      path: null,
      name: null,
      width: null,
      height: null
    };
  }

  objectFitToCanvas() {
    const activeObject = this.canvas.getActiveObject();
    
    if (activeObject) {
      const scaleX = this.canvas.getWidth() / activeObject.width;
      const scaleY = this.canvas.getHeight() / activeObject.height;
      const scale = Math.min(scaleX, scaleY);

      activeObject.set({
        scaleX: scale,
        scaleY: scale,
      });
      
      this.canvas.viewportCenterObject(activeObject);
      this.canvas.renderAll();
    }
  }

  centerSelectedObject() {
    const active = this.canvas.getActiveObject();
    this.canvas.viewportCenterObject(active);
    this.canvas.renderAll();
  }

  changeColorForActiveObject() {
    const activeObject = this.canvas.getActiveObject();
    this.colorToDraw = this.getColorToDraw();
    
    if (activeObject && ProductCanvas.SHAPES_TYPES.includes(activeObject.type) && activeObject.isStrokeShape) {
      activeObject.set({ stroke: this.colorToDraw });
      
      return this.canvas.renderAll();
    }

    if (activeObject && ProductCanvas.BASIC_TYPES.includes(activeObject.type)) {
      const typePropertyMap = { path: 'stroke', default: 'fill' };
      const property = typePropertyMap[activeObject.type] || typePropertyMap['default'];
      activeObject.set({ [property]: this.colorToDraw });
      
      if (ProductCanvas.SHAPES_TYPES.includes(activeObject.type)) {
        activeObject.set({ stroke: this.colorToDraw });
      }
      
      return this.canvas.renderAll();
    }
  }

  cloneBasicObject() {
    const activeObject = this.canvas.getActiveObject();

    if (activeObject && ProductCanvas.BASIC_TYPES.includes(activeObject.type)) {
      activeObject.clone(cloned => {
        cloned.set({ top: cloned.top + 10, left: cloned.left + 10 });
        
        if (ProductCanvas.SHAPES_TYPES.includes(activeObject.type)) {
          cloned.set({
            isStrokeShape: activeObject.isStrokeShape,
            stroke: activeObject.stroke,
            strokeWidth: activeObject.strokeWidth,
          });
        }
        
        this.canvas.add(cloned);
        this.canvas.renderAll();
      });
    }
  }

  lockMovement() {
    const activeObject = this.canvas.getActiveObject();

    if (activeObject) {
      activeObject.set({
        lockMovementX: !activeObject.lockMovementX,
        lockMovementY: !activeObject.lockMovementY
      });
      
      this.canvas.renderAll();
    }
  }

  groupUngroupActiveObjects() {
    if (!this.canvas.getActiveObject()) {
      return;
    }

    if (this.canvas.getActiveObject().type === 'group') {
      this.canvas.getActiveObject().toActiveSelection();
      return this.canvas.requestRenderAll();
    }
    
    if (this.canvas.getActiveObject().type === 'activeSelection') {
      this.canvas.getActiveObject().toGroup();
      return this.canvas.requestRenderAll();
    }
  }
  
  async checkOnProductImage(fileName) {
    const formData = new FormData();
    const replasedNAme = fileName.replace(/(\.png)+$/, '.png');
    formData.append('fileName', replasedNAme);
    const { data } = await SvgImageEditor.checkOnProductImage(formData);
    this.vueStore.dispatch(`${ProductCanvas.SVG_STORE}setIsProductImage`, data.isProductImage);
  }
}
