var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: "Catalog Management",
        centered: "",
        "ok-title": "Go to catalog",
        "ok-disabled": !(_vm.handledCatalog || _vm.selectedCatalog),
      },
      on: { ok: _vm.goToCatalog, cancel: _vm.onClose },
      model: {
        value: _vm.isOpened,
        callback: function ($$v) {
          _vm.isOpened = $$v
        },
        expression: "isOpened",
      },
    },
    [
      _c(
        "b-tabs",
        { attrs: { "content-class": "mt-3" } },
        [
          _c(
            "b-tab",
            {
              attrs: { title: "Create", active: "" },
              on: { click: _vm.onTabClick },
            },
            [
              _c(
                "div",
                { attrs: { role: "group" } },
                [
                  _c("label", { attrs: { for: "input-live" } }, [
                    _vm._v("Name of catalog:"),
                  ]),
                  _vm._v(" "),
                  _c("b-form-input", {
                    attrs: {
                      state: _vm.nameState,
                      placeholder: "Enter name",
                      "aria-describedby": "input-live-feedback",
                      trim: "",
                    },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "input-live-feedback" } },
                    [
                      _vm._v(
                        "\n          Enter a name of at least 1 and less than 255 chars.\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "primary", disabled: !_vm.nameState },
                  on: { click: _vm.create },
                },
                [_vm._v("\n        Create catalog\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            { attrs: { title: "Catalogs" }, on: { click: _vm.onTabClick } },
            [
              _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.catalogsCount,
                  "per-page": _vm.catalogsPerPage,
                },
                on: { change: _vm.getCatalogList },
                model: {
                  value: _vm.catalogsPage,
                  callback: function ($$v) {
                    _vm.catalogsPage = $$v
                  },
                  expression: "catalogsPage",
                },
              }),
              _vm._v(" "),
              _c(
                "b-list-group",
                _vm._l(_vm.catalogs, function (catalog) {
                  return _c(
                    "b-list-group-item",
                    {
                      key: catalog.id,
                      staticClass: "cursor-pointer",
                      attrs: { active: _vm.selectedCatalog === catalog },
                      on: {
                        click: function ($event) {
                          _vm.selectedCatalog = catalog
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(catalog.name) + "\n        "
                      ),
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "primary", disabled: !_vm.selectedCatalog },
                  on: { click: _vm.addToCatalog },
                },
                [_vm._v("\n        Add to selected catalog\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }