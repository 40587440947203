<template>
  <div>
    <b-button-toolbar>
      <b-button-group
        v-if="mode === MODES.VIEW && isAnySelected"
        class="mr-3"
      >
        <ButtonOutline
          v-b-tooltip.hover
          title="Show on canvas"
          @click="showOnCanvas"
        >
          <i class="fas fa-crosshairs" />
        </ButtonOutline>
        <ButtonOutline
          v-b-tooltip.hover 
          title="Replace"
        >
          <i class="fas fa-redo-alt" />
        </ButtonOutline>
        <ButtonOutline
          v-b-tooltip.hover 
          title="Delete"
        >
          <i class="fas fa-trash" />
        </ButtonOutline>
      </b-button-group>
      
      <b-button-group
        v-if="mode === MODES.REPLACE"
        class="mr-3"
      >
        <ButtonOutline 
          v-b-tooltip.hover 
          title="Cancel"
        >
          <b-icon
            icon="x-circle-fill"
            aria-hidden="true"
          />
        </ButtonOutline>
        <ButtonOutline
          v-if="isAnySelected"
          v-b-tooltip.hover
          title="Select"
        >
          <b-icon
            icon="check-lg"
            aria-hidden="true"
          />
        </ButtonOutline>
      </b-button-group>
      
      <b-button-group class="mr-3">
        <ButtonOutline 
          v-if="mode > MODES.VIEW"
          v-b-tooltip.hover
          title="Home"
        >
          <b-icon
            icon="house-fill"
            aria-hidden="true"
          />
        </ButtonOutline>
        <ButtonOutline
          v-if="mode > MODES.VIEW"
          v-b-tooltip.hover
          title="Folder view"
        >
          <b-icon
            icon="folder-check"
            aria-hidden="true"
          />
        </ButtonOutline>
        <ButtonOutline
          v-b-tooltip.hover
          title="Grouped view"
          :class="isGroupActive ? 'active' : ''"
          @click="toggleGroupMode"
        >
          <i class="fas fa-layer-group" />
        </ButtonOutline>
      </b-button-group>
      
      <b-button-group>
        <ButtonOutline 
          v-b-tooltip.hover 
          title="Save"
        >
          <i class="fas fa-save" />
        </ButtonOutline>
      </b-button-group>
    </b-button-toolbar>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {MODES} from "@frontend/components/modules/file-browser-product-selector/constants";
import ButtonOutline from "@frontend/components/common/atoms/ButtonOutline.vue";

export default {
  name: "ToolBar",
  components: {ButtonOutline},
  data: () => ({
    
  }),
  computed: {
    MODES() {
      return MODES
    },
    ...mapGetters('fileBrowserProductSelector', [
      'isAnySelected',
      'isAnyPicked',
      'isFolderViewEnabled',
      'showOnlySelected',
      'isTopBarSwitchersEnabled',
      'mode',
      'selected'
    ]),
    ...mapGetters('layout', [
      'selectedInstance',
    ]),
    isGroupActive() {
      return this.mode > MODES.VIEW;
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    ...mapActions('fileBrowserProductSelector', ['setMode']),
    toggleGroupMode() {
      if (this.mode === MODES.GROUP) {
        this.setMode(MODES.VIEW);
      } else {
        this.setMode(MODES.GROUP);
      }
    },
    showOnCanvas() {
      const canvas = this.selectedInstance.canvas;
      
      const target = canvas.getObjects()
        .find((obj) => obj.id === this.selected[0])

      console.log('this.selected:', this.selected)
      console.log('this.selected[0].element_id:', this.selected[0].element_id)
      console.log('target:', target)

      const simulatedEvent = {target: canvas};
      this.selectedInstance.canvas.fire('selection:created', { e: simulatedEvent, target });
      canvas.setActiveObject(target);
      canvas.renderAll();
    }
  }
}
</script>

<style scoped lang="scss">

</style>
