import Base from './fields/Base';
import BackgroundColors from "./fields/BackgroundColor";
import { BACKGROUND_COLOR_ATTRIBUTE } from './constants/attribute-type';

function initAttributes(attributes = []) {
    return attributes.map(attribute => {
        switch(attribute.name) {
            case BACKGROUND_COLOR_ATTRIBUTE:
                return new BackgroundColors(attribute);
            default:
                return new Base(attribute);
        }
    });
}

export default class Theme {

    id;

    customerId;

    name;

    colors = []

    attributes = [];

    constructor(data = {}) {
        this.id = data.id;
        this.customerId = data.customer_id;
        this.name = data.name;
        this.colors = data.colors || this.colors;
        this.attributes = initAttributes(data?.attributes || this.attributes);
    }

    getFirstColor() {
        return this.colors[0];
    }

    getBackgroundColors() {
        return this.attributes.find(attribute => attribute instanceof BackgroundColors);
    }

    getColorsFromBackgroundColors() {
        const backgroundColors = this.getBackgroundColors();

        return backgroundColors?.colors || [];
    }
}