import { updateObject } from '@frontend/group/modules/copy-and-resize/helpers';

export function handleObjectsIntersections({ objects, dimension, rsW, rsH }) {
  const origins = ['left', 'top'];

  for (const [currentObjectIndex, currentObject] of objects.entries()) {
    const currentPoints = currentObject.getPointByOrigin(...origins);
    const currentX = currentPoints.x;
    const currentY = currentPoints.y;
    const currentWidth = currentObject.getScaledWidth();
    const currentHeight = currentObject.getScaledHeight();

    for (const [otherObjectIndex, otherObject] of objects.entries()) {
      if (currentObject === otherObject) {
        continue;
      }

      const otherPoints = otherObject.getPointByOrigin(...origins);
      const otherX = otherPoints.x;
      const otherY = otherPoints.y;
      const otherWidth = otherObject.getScaledWidth();
      const otherHeight = otherObject.getScaledHeight();

      const isHorizontalOverlap =
        currentX < otherX + otherWidth &&
        currentX + currentWidth > otherX;

      const isVerticalOverlap =
        currentY < otherY + otherHeight &&
        currentY + currentHeight > otherY;

      const isOverlapping = isHorizontalOverlap && isVerticalOverlap;

      if (!isOverlapping) {
        continue;
      }

      let newOffsetX = currentObjectIndex < otherObjectIndex ? currentX : otherX + otherWidth;
      let newOffsetY = currentY;

      const isFitsWithinCanvas  = newOffsetX + currentWidth <= dimension.width;

      if (!isFitsWithinCanvas) {
        if (currentObjectIndex < otherObjectIndex) {
          continue;
        }

        newOffsetX = otherX;
        newOffsetY = otherY + otherHeight;

        const isEnoughSpaceUnderOtherObject = newOffsetY + currentHeight <= dimension.height;

        if (!isEnoughSpaceUnderOtherObject) {
          continue;
        }
      }

      currentObject.setPositionByOrigin({ x: newOffsetX, y: newOffsetY }, ...origins);
      currentObject.setCoords();
      updateObject(currentObject, { x: rsW, y: rsH });
      break;
    }
  }
}
