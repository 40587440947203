import _ from "lodash";
import FileBrowser from "../../services/api/file-browser";
import {FILE_TYPE, TYPE} from "@frontend/constants/file-image-types";

const getFileIdOfItem = (item) => {
  return item.name.split('.')[0];
}

const getFilteredItems = (items) => {
  const resultItems = [];
  
  for (const itemToAdd of items) {
    if (itemToAdd.type === TYPE.FOLDER) {
      resultItems.push(itemToAdd);
      continue;
    }
    
    if (!resultItems.find(item => item.fileId === itemToAdd.fileId)) {
      resultItems.push(itemToAdd);
    }
  }

  return resultItems;
}

const initState = () => ({
  selectedFileNames: '',
  onlyOneItemSelection: false,
  isFolderViewEnabled: true,
  showOnlySelected: false,
  multiselectEnabled: false,
  isListLoading: false,
  pickedItems: [],
  items: [],
  progressingItems: [],
  selected: [],
  currentFolderId: null,
  currentFolderName: '',
  pagination: {
    currentPage: 1,
    pageSize: 12,
    totalCount: 0,
    lastPage: 1,
  },
  searchQuery: '',
  breadcrumbs: [],
})

export const MUTATIONS = {
  SET_ITEMS: 'SET_ITEMS',
  UPDATE_PROGRESS_PERCENTAGE: 'UPDATE_PROGRESS_PERCENTAGE',
  SET_LIST_LOADING: 'SET_LIST_LOADING',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  SET_LAST_PAGE: 'SET_LAST_PAGE',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  TOGGLE_SELECTED_ITEMS: 'TOGGLE_SELECTED_ITEMS',
  CLEAR_SELECTION: 'CLEAR_SELECTION',
  SET_MODAL: 'SET_MODAL',
  FINISH_PROGRESSING: 'FINISH_PROGRESSING',
  UPDATE_ITEM_NAME: 'UPDATE_ITEM_NAME',
  SET_BREADCRUMBS: 'SET_BREADCRUMBS',
  SET_SHOW_ONLY_FOLDERS: 'SET_SHOW_ONLY_FOLDERS',
  SET_CURRENT_FOLDER_ID: 'SET_CURRENT_FOLDER_ID',
  SET_CURRENT_FOLDER_NAME: 'SET_CURRENT_FOLDER_NAME',
  SET_MULTISELECT_ENABLED: 'SET_MULTISELECT_ENABLED',
  SET_SELECTION: 'SET_SELECTION',
  SET_IS_FOLDER_VIEW_ENABLED: 'SET_IS_FOLDER_VIEW_ENABLED',
  SET_SHOW_ONLY_SELECTED: 'SET_SHOW_ONLY_SELECTED',
  SET_PICKED_ITEMS: 'SET_PICKED_ITEMS',
  SET_ONLY_ONE_ITEM_SELECTION: 'SET_ONLY_ONE_ITEM_SELECTION',
  SET_SELECTED_FILE_NAMES: 'SET_SELECTED_FILE_NAMES',
}

export default {
  namespaced: true,
  state: initState(),
  getters: {
    isListLoading: state => state.isListLoading,
    items: state => state.items,
    selected: state => state.selected,
    firstSelectedItem: state => state.selected.length && state.items.find(item => item.id === state.selected[0]),
    selectedItems: state => state.selected.map(selectedId => state.items.find(item => item.id === selectedId)),
    selectedFolders: state => state.items.filter(item => item.type === 'folder' && state.selected.includes(item.id)).map(item => item.id),
    totalCount: state => state.pagination.totalCount,
    currentPage: state => state.pagination.currentPage,
    searchQuery: state => state.searchQuery,
    isSelected: state => item => state.selected.includes(item.id),
    selectedCount: state => state.selected.length,
    isAnySelected: state => !!state.selected.length,
    isAnyPicked: state => !!state.pickedItems.length,
    breadcrumbs: state => state.breadcrumbs,
    currentFolderId: state => state.currentFolderId,
    currentFolderName: state => state.currentFolderName,
    isFolderViewEnabled: state => state.isFolderViewEnabled,
    showOnlySelected: state => state.showOnlySelected,
    pickedItems: state => state.pickedItems,
    selectedFileNames: state => state.selectedFileNames,
    onlyOneItemSelection: state => state.onlyOneItemSelection,
  },
  mutations: {
    [MUTATIONS.SET_LIST_LOADING](state, payload) {
      state.isListLoading = payload;
    },
    [MUTATIONS.SET_ITEMS](state, payload) {
      state.items = payload;
    },
    [MUTATIONS.SET_TOTAL_COUNT](state, payload) {
      state.pagination.totalCount = payload
    },
    [MUTATIONS.SET_LAST_PAGE](state, payload) {
      state.pagination.lastPage = payload
    },
    [MUTATIONS.SET_CURRENT_PAGE](state, payload) {
      state.pagination.currentPage = payload
    },
    [MUTATIONS.SET_SEARCH_QUERY](state, payload) {
      state.searchQuery = payload
    },
    [MUTATIONS.TOGGLE_SELECTED_ITEMS](state, id) {
      if (state.selected.includes(id)) {
        state.selected.splice(state.selected.indexOf(id), 1)
      } else {
        state.selected.push(id)
      }
    },
    [MUTATIONS.CLEAR_SELECTION](state) {
      state.selected = [];
    },
    [MUTATIONS.SET_SELECTION](state, payload) {
      state.selected = payload
    },
    [MUTATIONS.SET_BREADCRUMBS](state, payload) {
      state.breadcrumbs = payload;
    },
    [MUTATIONS.SET_SHOW_ONLY_FOLDERS] (state, payload) {
      state.showOnlyFolders = payload
    },
    [MUTATIONS.SET_CURRENT_FOLDER_ID] (state, payload) {
      state.currentFolderId = payload
    },
    [MUTATIONS.SET_CURRENT_FOLDER_NAME] (state, payload) {
      state.currentFolderName = payload
    },
    [MUTATIONS.SET_MULTISELECT_ENABLED] (state, payload) {
      state.multiselectEnabled = payload
    },
    [MUTATIONS.SET_IS_FOLDER_VIEW_ENABLED] (state, payload) {
      state.isFolderViewEnabled = payload
    },
    [MUTATIONS.SET_SHOW_ONLY_SELECTED] (state, payload) {
      state.showOnlySelected = payload
    },
    [MUTATIONS.SET_PICKED_ITEMS] (state, payload) {
      state.pickedItems = payload
    },
    [MUTATIONS.SET_ONLY_ONE_ITEM_SELECTION] (state, payload) {
      state.onlyOneItemSelection = payload
    },
    [MUTATIONS.SET_SELECTED_FILE_NAMES] (state, payload) {
      state.selectedFileNames = payload
    },
  },
  actions: {
    async fetchList({ commit, state }) {
      const resolveType = (item) => {
        if (item.type === FILE_TYPE.FOLDER) {
          return FILE_TYPE.FOLDER;
        }
        
        return FILE_TYPE.IMAGE;
      }
      
      commit(MUTATIONS.SET_LIST_LOADING, true)
      
      if (state.pickedItems.length === 0) {
        commit(MUTATIONS.SET_SHOW_ONLY_SELECTED, false);
      }
      
      let query = state.searchQuery;

      if (state.showOnlySelected) {
        //add dot here in order to filter other items with similar name
        const queryArray = state.pickedItems.map(item => item.fileId + '.');
        query = queryArray.join(',');
      }
      
      const requestParams = {
        pageSize: state.pagination.pageSize,
        pageNumber: state.pagination.currentPage,
        searchKey: query,
      };
      
      if (!state.showOnlySelected) {
        if (state.isFolderViewEnabled) {
          requestParams['fileBrowser'] = true;

          if (!!state.currentFolderId) {
            requestParams['folder_id'] = state.currentFolderId;
          }
        }
      }
      
      const { data } = await FileBrowser.getFileList(requestParams);
      
      let items = data.items.map((item) => {
        const pickedItem = state.pickedItems.find(pickedItem => pickedItem.id === item.id);
        let count = 0;
        
        if (pickedItem) {
          count = pickedItem.count;
        }
        
        return {
          ...item,
          type: resolveType(item),
          count,
          fileId: getFileIdOfItem(item),
        }
      });
      
      items = getFilteredItems(items);

      commit(MUTATIONS.SET_ITEMS, items)
      commit(MUTATIONS.SET_TOTAL_COUNT, data.totalCount)
      commit(MUTATIONS.SET_LAST_PAGE, data.last_page)
      commit(MUTATIONS.CLEAR_SELECTION)
      commit(MUTATIONS.SET_LIST_LOADING, false)
    },
    async fetchBreadcrumbs({ commit, state }){
      if (!state.currentFolderId) {
        return commit(MUTATIONS.SET_BREADCRUMBS, []);
      }
      try {
        const { data } = await FileBrowser.getBreadcrumbs(state.currentFolderId, 'products');

        const breadcrumbs = data.map(folder => {
          return {
            id: folder.id,
            text: folder.name
          };
        })

        commit(MUTATIONS.SET_BREADCRUMBS, breadcrumbs);
      } catch (error) {
        return commit(MUTATIONS.SET_BREADCRUMBS, []);
      }
    },
    async fetchData({ dispatch }) {
      await dispatch('fetchList');
      await dispatch('fetchBreadcrumbs');
    },
    clearSelection ({ commit }) {
      commit(MUTATIONS.CLEAR_SELECTION)
    },
    selectAll ({ commit, state }) {
      commit(MUTATIONS.SET_SELECTION, _.filter(_.map(state.items, 'id')))
    },
    setShowOnlyFolders({ commit }, payload) {
      commit(MUTATIONS.SET_SHOW_ONLY_FOLDERS, payload)
    },
    setCurrentFolder({ commit, state }, payload) {
      commit(MUTATIONS.SET_CURRENT_PAGE, 1)
      commit(MUTATIONS.SET_SEARCH_QUERY, '')
      commit(MUTATIONS.SET_CURRENT_FOLDER_ID, payload?.id || null)
      commit(MUTATIONS.SET_CURRENT_FOLDER_NAME, payload?.name || null)
    },
    setCurrentPage({ commit, dispatch, state }, payload) {
      commit(MUTATIONS.SET_CURRENT_PAGE, payload)
      dispatch('fetchList')
    },
    setSearchQuery({ commit, dispatch, state }, payload) {
      commit(MUTATIONS.SET_SHOW_ONLY_SELECTED, false)
      commit(MUTATIONS.SET_CURRENT_PAGE, 1)
      commit(MUTATIONS.SET_SEARCH_QUERY, payload)
      dispatch('fetchList')
    },
    setIsFolderViewEnabled({ state, commit, dispatch }, payload) {
      commit(MUTATIONS.SET_LIST_LOADING, true)
      commit(MUTATIONS.SET_IS_FOLDER_VIEW_ENABLED, payload)
       if (payload) {
           commit(MUTATIONS.SET_SHOW_ONLY_SELECTED, false)
       }
      commit(MUTATIONS.SET_CURRENT_PAGE, 1)
      dispatch('fetchList')
      commit(MUTATIONS.SET_LIST_LOADING, false)
    },
    setShowOnlySelected({ state, commit, dispatch }, payload) {
      commit(MUTATIONS.SET_LIST_LOADING, true)
      commit(MUTATIONS.SET_CURRENT_PAGE, 1)
      commit(MUTATIONS.SET_SHOW_ONLY_SELECTED, payload)
      if (payload) {
        commit(MUTATIONS.SET_IS_FOLDER_VIEW_ENABLED, false)
      }
      dispatch('fetchList')
      commit(MUTATIONS.SET_LIST_LOADING, false)
    },
    setOnlyOneItemSelection({ commit }, payload) {
      commit(MUTATIONS.SET_ONLY_ONE_ITEM_SELECTION, payload)
    },
    pickItem({ state, commit, dispatch }, payload) {
      if (state.onlyOneItemSelection) {
        commit(MUTATIONS.SET_PICKED_ITEMS, [])
        const items = state.items.map(item => {
          return {
            ...item,
            count: 0
          }
        });
        commit(MUTATIONS.SET_ITEMS, items)
      }
      
      const items = state.items.map((item) => {
        return {
          ...item,
          count: payload.id === item.id ? payload.count : item.count
        }
      });

      commit(MUTATIONS.SET_ITEMS, items)
      
      const pickedItems = [...state.pickedItems];
      const alreadyExistingItem = pickedItems.find(pickedItem => pickedItem.id === payload.id);
      
      if (alreadyExistingItem) {
        alreadyExistingItem.count = payload.count;
      } else {
        pickedItems.push(payload);
      }
      
      commit(MUTATIONS.SET_PICKED_ITEMS, pickedItems)
    },
    deleteItem({ state, commit, dispatch }, payload) {
      let items = [...state.items];
      
      const itemToDecreaseCount = items.find(item => item.id === payload.id);
      itemToDecreaseCount.count = payload.count;
      
      commit(MUTATIONS.SET_ITEMS, items);

      let pickedItems = [...state.pickedItems];
      
      if (payload.count <= 0) {
        pickedItems = state.pickedItems.filter((item) => item.id !== payload.id);
      } else {
        const alreadyExistingItem = pickedItems.find(item => item.id === payload.id);
        alreadyExistingItem.count = payload.count;
      }
      
      commit(MUTATIONS.SET_PICKED_ITEMS, pickedItems);
    },
    async setCurrentPickedItems({ state, commit, dispatch }, payload) {
      commit(MUTATIONS.SET_LIST_LOADING, true)
      
      if (payload.length === 0) {
        commit(MUTATIONS.SET_PICKED_ITEMS, [])
        await dispatch('fetchList');
        commit(MUTATIONS.SET_LIST_LOADING, false)
        return;
      }
      
      const getUniqueValues = arr => {
        return Array.from(new Set(arr));
      }
      
      const uniqueNames = getUniqueValues(payload);
      
      const nameCountMap = {};
      
      for (const uniqueName of uniqueNames) {
        nameCountMap[uniqueName] = payload.filter(name => name === uniqueName).length;
      }
      
      //add dot here in order to filter other items with similar name
      const searchKey = uniqueNames.map(name => name + '.').join(',')

      const requestParams = {
        pageSize: 20,
        pageNumber: 1,
        searchKey,
      };

      const { data } = await FileBrowser.getFileList(requestParams);
      
      let items = data.items.map((item) => {
        const fileId = getFileIdOfItem(item);
        
        return {
          ...item,
          fileId,
          count: nameCountMap[fileId] ?? 1
        }
      });
      
      items = getFilteredItems(items);

      commit(MUTATIONS.SET_PICKED_ITEMS, items);
      commit(MUTATIONS.SET_IS_FOLDER_VIEW_ENABLED, false);
      commit(MUTATIONS.SET_SHOW_ONLY_SELECTED, true);
      dispatch('fetchList');
      commit(MUTATIONS.SET_LIST_LOADING, false)
    },
    togglePickedItem({ state, commit, dispatch }, payload) {
      let pickedItems = [...state.pickedItems];
      const item = pickedItems.find(item => item.fileId === payload.fileId);
      
      if (state.onlyOneItemSelection) {
        pickedItems = [];
      }
      
      if (item) {
        pickedItems = state.pickedItems.filter((pickedItem) => pickedItem.fileId !== payload.fileId);
      } else {
        payload.count = 1;
        pickedItems.push(payload);
      }
      
      commit(MUTATIONS.SET_PICKED_ITEMS, pickedItems);
      dispatch('fetchList');
    },
    setSelectedFileNames({ commit }, payload) {
      commit(MUTATIONS.SET_SELECTED_FILE_NAMES, payload);
    },
    syncPickedItemsWithSelectedFileNames({ state, commit }) {
      const resArray = [];
      
      for (const item of state.pickedItems) {
        for (let i = 0; i < item.count; i++) {
          resArray.push(item.fileId);
        }
      }

      commit(MUTATIONS.SET_SELECTED_FILE_NAMES, resArray.join(' '));
    }
  }
}
