var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { id: "export-templates-modal", title: "Export", centered: "" },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary", disabled: _vm.isSaving },
                  on: { click: _vm.onSave },
                },
                [
                  _vm.isSaving
                    ? _c("div", [
                        _c("span", {
                          staticClass: "spinner-border spinner-border-sm",
                          attrs: { role: "status", "aria-hidden": "true" },
                        }),
                        _vm._v("\n        Exporting...\n      "),
                      ])
                    : _c("span", [_vm._v("Export")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                { attrs: { variant: "secondary" }, on: { click: _vm.onClose } },
                [_vm._v("\n      Cancel\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "col-md-2" }, [
          _c("label", [_vm._v("Format")]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-10 form-group" },
          [
            _c("b-form-select", {
              staticClass: "form-control",
              attrs: { options: _vm.formats },
              model: {
                value: _vm.selectedFormat,
                callback: function ($$v) {
                  _vm.selectedFormat = $$v
                },
                expression: "selectedFormat",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.selectedFormat === "gif" || _vm.selectedFormat === "pdf"
        ? [
            _c(
              "div",
              { staticClass: "form-row container-downloadable-groups" },
              [
                _c("div", { staticClass: "col-md-2" }, [
                  _c("label", [_vm._v("Group")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-10 form-group" },
                  [
                    _c("MultipleSelectJquery", {
                      attrs: { multiple: "", data: _vm.groupsInstances },
                      model: {
                        value: _vm.selectedGroupInstances,
                        callback: function ($$v) {
                          _vm.selectedGroupInstances = $$v
                        },
                        expression: "selectedGroupInstances",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _vm.selectedFormat === "pdf"
              ? _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-2" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-10 form-group" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.createOneFilePerGroup,
                            expression: "createOneFilePerGroup",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.createOneFilePerGroup)
                            ? _vm._i(_vm.createOneFilePerGroup, null) > -1
                            : _vm.createOneFilePerGroup,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.createOneFilePerGroup,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.createOneFilePerGroup = $$a.concat([
                                    $$v,
                                  ]))
                              } else {
                                $$i > -1 &&
                                  (_vm.createOneFilePerGroup = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.createOneFilePerGroup = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(
                        "\n          Create one file for each group\n        "
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedFormat === "pdf"
              ? _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-2" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-10 form-group" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.createPageForEachTemplate,
                            expression: "createPageForEachTemplate",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.createPageForEachTemplate)
                            ? _vm._i(_vm.createPageForEachTemplate, null) > -1
                            : _vm.createPageForEachTemplate,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.createPageForEachTemplate,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.createPageForEachTemplate = $$a.concat([
                                    $$v,
                                  ]))
                              } else {
                                $$i > -1 &&
                                  (_vm.createPageForEachTemplate = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.createPageForEachTemplate = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(
                        "\n          Create one page for each template in the group\n        "
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedFormat === "pdf" &&
            _vm.selectedGroupInstances.length == 1
              ? _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-2" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-10 form-group" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.getDirectLinktoFile,
                            expression: "getDirectLinktoFile",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.getDirectLinktoFile)
                            ? _vm._i(_vm.getDirectLinktoFile, null) > -1
                            : _vm.getDirectLinktoFile,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.getDirectLinktoFile,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.getDirectLinktoFile = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.getDirectLinktoFile = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.getDirectLinktoFile = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(
                        "\n          Get direct link to the file\n        "
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedFormat === "pdf"
              ? _c("div", { staticClass: "form-row -groups" }, [
                  _c("div", { staticClass: "col-md-2" }, [
                    _c("label", [_vm._v("Save to folder")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-10 form-group" },
                    [
                      _c("SelectFolder", {
                        attrs: { items: _vm.folders },
                        on: { "update:selectedFolder": _vm.handleSelection },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-row container-downloadable-single-template",
              },
              [
                _c("div", { staticClass: "col-md-2" }, [
                  _c("label", [_vm._v("Single Templates")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-10 form-group" },
                  [
                    _c("MultipleSelectJquery", {
                      attrs: { multiple: "", data: _vm.singleInstances },
                      model: {
                        value: _vm.selectedSingleInstances,
                        callback: function ($$v) {
                          _vm.selectedSingleInstances = $$v
                        },
                        expression: "selectedSingleInstances",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        : [
            _c(
              "div",
              { staticClass: "form-row container-downloadable-templates" },
              [
                _c("div", { staticClass: "col-md-2" }, [
                  _c("label", [_vm._v("Download")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-10 form-group" },
                  [
                    _c("Multiselect", {
                      attrs: {
                        options: _vm.instancesOption,
                        "track-by": "instance_id",
                        label: "name",
                        placeholder: "Select instances...",
                        "close-on-select": false,
                        multiple: "",
                      },
                      model: {
                        value: _vm.selectedInstances,
                        callback: function ($$v) {
                          _vm.selectedInstances = $$v
                        },
                        expression: "selectedInstances",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _vm.selectedFormat === "pptx"
              ? _c("div", { staticClass: "form-row -groups" }, [
                  _c("div", { staticClass: "col-md-2" }, [
                    _c("label", [_vm._v("Save to folder")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-10 form-group" },
                    [
                      _c("SelectFolder", {
                        attrs: { items: _vm.folders },
                        on: { "update:selectedFolder": _vm.handleSelection },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
      _vm._v(" "),
      ["psd", "jpg_from_psd", "psd_and_jpg"].includes(_vm.selectedFormat)
        ? _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-md-2" }),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-10 form-group" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.downloadInBackground,
                      expression: "downloadInBackground",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.downloadInBackground)
                      ? _vm._i(_vm.downloadInBackground, null) > -1
                      : _vm.downloadInBackground,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.downloadInBackground,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.downloadInBackground = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.downloadInBackground = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.downloadInBackground = $$c
                      }
                    },
                  },
                }),
                _vm._v("\n        Download in background\n      "),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      ["psd", "jpg", "png", "jpg_from_psd"].includes(_vm.selectedFormat)
        ? _c("div", { staticClass: "form-row container-platforms" }, [
            _c("div", { staticClass: "col-md-2" }, [
              _c("label", [_vm._v("DPI")]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-10 form-group" },
              [
                _c(
                  "b-form-select",
                  {
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        _vm.dpi = _vm.selectedDpi
                      },
                    },
                    model: {
                      value: _vm.selectedDpi,
                      callback: function ($$v) {
                        _vm.selectedDpi = _vm._n($$v)
                      },
                      expression: "selectedDpi",
                    },
                  },
                  [
                    _c("option", { attrs: { value: "72" } }, [
                      _vm._v("\n          72\n        "),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "75" } }, [
                      _vm._v("\n          75\n        "),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "100" } }, [
                      _vm._v("\n          100\n        "),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "150" } }, [
                      _vm._v("\n          150\n        "),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "200" } }, [
                      _vm._v("\n          200\n        "),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "300" } }, [
                      _vm._v("\n          300\n        "),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "0" } }, [
                      _vm._v("\n          Custom\n        "),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "my-2" }),
                _vm._v(" "),
                !_vm.selectedDpi
                  ? _c("b-form-input", {
                      attrs: { type: "number" },
                      model: {
                        value: _vm.dpi,
                        callback: function ($$v) {
                          _vm.dpi = _vm._n($$v)
                        },
                        expression: "dpi",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedFormat === "html"
        ? _c("div", { staticClass: "form-row container-platforms" }, [
            _c("div", { staticClass: "col-md-2" }, [
              _c("label", [_vm._v("Platform")]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-10 form-group" },
              [
                _c(
                  "b-form-select",
                  {
                    staticClass: "form-control",
                    model: {
                      value: _vm.selectedPlatform,
                      callback: function ($$v) {
                        _vm.selectedPlatform = $$v
                      },
                      expression: "selectedPlatform",
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("\n          None\n        "),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "ttd" } }, [
                      _vm._v("\n          The Trade Desk (TTD)\n        "),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }