<template>
  <div class="dropdown-product-images form-row mb-1">
    <div class="col-3 d-flex align-items-center">
      Product
    </div>
    <div class="col-9">
      <div class="input-group">
        <input
          v-model="productIds"
          type="text"
          class="form-control product-ids-input"
          :disabled="disabled"
          readonly
          @focus="onFocusProductIds"
          @input="onInputProductIds"
        >
        <div class="input-group-append">
          <button
            class="btn btn-outline px-2"
            :disabled="disabled"
            @click="onFocusProductIds(!isShowDropdown)"
          >
            <i :class="{'cil-chevron-bottom': !isShowDropdown, 'cil-chevron-top': isShowDropdown }" />
          </button>
        </div>
      </div>
    </div>
    <div
      v-show="isShowDropdown"
      class="products-container"
    >
      <div class="file-browser-wrapper">
        <FileBrowser />
      </div>
      <div class="buttons-wrapper">
        <b-button
          @click="isShowDropdown = false"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="isSaveButtonDisabled"
          @click="handleSave"
        >
          Save
        </b-button>
      </div>
    </div>
    <div class="col-12 my-2">
      <ProductImageUploads @replaceImages="replaceProductIds" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ProductImageUploads from "./ProductImageUploads.vue";
import FileBrowser from "@frontend/components/modules/file-browser-product-selector/FileBrowser.vue";
import '@/../sass/includes/product-images-dropdown.scss'
import {createNamespacedHelpers} from "vuex";

const { mapGetters, mapActions } = createNamespacedHelpers('fileBrowserProductSelector')

export default {
  components: {ProductImageUploads, FileBrowser},
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      productIds: '',
      products: [],
      selectedProducts: [],
      currentPage: 1,
      totalProducts: 0,
      perPage: 10,
      isShowDropdown: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'pickedItems'
    ]),
    isSaveButtonDisabled() {
      if (this.pickedItems.length) {
        const newItemName = this.pickedItems[0].name.split('.')[0];

        if (newItemName && this.value !== newItemName) {
          return false;
        }
      }
      
      return true;
    }
  },
  watch: {
    value() {
      this.productIds = this.value;
    },
    isShowDropdown(newValue) {
      this.setOnlyOneItemSelection(newValue)
    }
  },
  mounted() {
    document.addEventListener('click', this.onClickDropdown.bind(this), true);
  },
  destroyed() {
    document.removeEventListener('click', this.onClickDropdown);
  },
  methods: {
    ...mapActions([
      'setCurrentPickedItems',
      'setOnlyOneItemSelection',
    ]),
    handleSave() {
      this.$emit('input', this.pickedItems[0].name.split('.')[0]);
    },
    async replaceProductIds(newProductName) {
      const {data} = await axios.get('/file/file_list', {
        params: {
          searchKey: newProductName,
          pageSize: 10,
          pageNumber: this.currentPage,
        }
      });
      this.selectedProducts = [];
      this.onSelectedProduct(data.items.map(product => {
        let productId = product.name.split('.').reverse();
        productId.shift();
        productId = productId.reverse().join('.');

        return {
          ...product,
          productId,
        }
      })[0])
    },
    onClickDropdown(event) {
      if (!event.target.closest('.dropdown-product-images')) {
        this.isShowDropdown = false;
      }
    },
    onSelectedProduct(product) {
      const selectedProduct = this.selectedProducts.find(_product => product.id ===_product.id);

      if (selectedProduct) {
        this.selectedProducts = this.selectedProducts.filter(_product => product.id !==_product.id)
      } else {
        this.selectedProducts.push(product);
      }

      this.selectedProducts = this.selectedProducts
        .reverse()
        .filter((selectedProduct, index) => index <= (this.limit - 1))
        .reverse();

      this.productIds = this.selectedProducts.map(selectedProduct => {
        return selectedProduct.productId;
      }).join(' ');

      this.$emit('input', this.productIds);
    },
    onFocusProductIds(isOpen = true) {
      this.setCurrentPickedItems([this.productIds]);
      this.isShowDropdown = isOpen;
    },
    onInputProductIds: _.debounce(function () {
      if (this.isLoading) return;
      this.findProduct()
    }, 100),
    async findProduct() {
      this.isLoading = true;

      try {
        const { data } = await axios.get('/file/file_list', { params: {
          searchKey: this.productIds.split(/[, ]+/).join(','),
          pageSize: 10,
          pageNumber: this.currentPage,
        }});

        this.products = data.items.map(product => {
          let productId = product.name.split('.').reverse();
          productId.shift();
          productId = productId.reverse().join('.');

          return {
            ...product,
            productId,
          }
        });
        this.totalProducts = data.totalCount;
      } catch(error) {

      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>
<style scoped lang="scss">
.dropdown-product-images {
  & input:hover {
    cursor: pointer;
  }
}

.product-ids-input[readonly] {
    background: #fff;
}

.file-browser-wrapper {
    max-height: 770px;
    overflow-y: auto;
    ::v-deep {
        .c-main-view {
            border: none;
        }
    }
}

.buttons-wrapper {
  display: flex;
  gap: 10px;
  margin: 15px 10px 0 0;
  justify-content: end;
}
::v-deep {
  .products-container {
    width: calc(65vw - 9px);
    height: fit-content;
    position: fixed;
    background: rgb(255, 255, 255);
    z-index: 101;
    top: 87px;
    right: 64px;
    border: 1px solid;
    box-shadow: 2px 2px #888888;
    overflow-y: auto;
    padding: 20px;
  }
}

@media screen and (max-height: 730px) {
  .file-browser-wrapper {
    max-height: 400px;
  }
  
  .products-container {
    height: 500px;
  }
  
}
</style>
