<template>
  <div>
    <b-form-group label="Presets">
      <Multiselect
        v-model="selectedPresetModel"
        class="mb-1"
        :options="presets"
        label="name"
        track-by="id"
        :allow-empty="false"
        :show-labels="false"
        @input="handleSelectPreset"
      >
        <template #option="props">
          <div
            class="preset-preview"
            :style="props.option.preview_style"
          />
          <span>{{ props.option.name }}</span>
        </template>
      </Multiselect>
      <b-button
        v-if="selectedPreset"
        v-b-tooltip.hover.bottom
        title="Apply"
        variant="primary"
        @click="applyPreset"
      >
        <b-icon icon="check-lg" />
      </b-button>
      <b-button
        v-b-tooltip.hover.bottom
        :title="selectedPreset.id ? `Edit`: 'Save'"
        variant="outline-primary"
        @click="savePresetOfOvalShadow"
      >
        <b-icon
          v-if="!selectedPreset"
          icon="save-fill"
        />
        <b-icon
          v-else
          icon="pencil-fill"
        />
      </b-button>
      <b-button
        v-if="selectedPreset"
        v-b-tooltip.hover.bottom
        title="Delete"
        variant="outline-danger"
        @click="deletePreset"
      >
        <b-icon icon="trash-fill" />
      </b-button>
      <b-button
        v-b-tooltip.hover.bottom
        title="Reset settings"
        variant="outline-danger"
        @click="resetSettings"
      >
        Reset
      </b-button>
    </b-form-group>
    <b-form-group
      label="Gradient"
      label-cols="4"
    >
      <b-checkbox
        v-model="editor.linearGradient"
        class="mt-2"
        inline
        @change="editor.radialGradient = false"
        @input="redraw"
      >
        Linear
      </b-checkbox>
      <b-checkbox
        v-model="editor.radialGradient"
        class="mt-2"
        inline
        @change="editor.linearGradient = false"
        @input="redraw"
      >
        Radial
      </b-checkbox>
    </b-form-group>
    <b-form-group
      :disabled="disabledRounding"
      label="Rounding"
      label-cols="4"
    >
      <b-input-group>
        <b-input
          v-model="editor.rounding"
          :max="maxRounding"
          min="0"
          number
          type="range"
          @input="redraw"
        />
        <template #append>
          <b-input
            v-model="editor.rounding"
            :max="maxRounding"
            min="0"
            number
            type="number"
            @input="redraw"
          />
        </template>
      </b-input-group>
      <b-checkbox-group
        v-show="editor.rounding && !disabledRounding"
        v-model="selectedCorners"
        button-variant="outline-primary"
        buttons
        class="mt-1"
        size="sm"
        @input="redraw"
      >
        <b-checkbox value="topLeft">
          <b-icon icon="arrow-up-left" />
        </b-checkbox>
        <b-checkbox value="topRight">
          <b-icon icon="arrow-up-right" />
        </b-checkbox>
        <b-checkbox value="bottomRight">
          <b-icon icon="arrow-down-right" />
        </b-checkbox>
        <b-checkbox value="bottomLeft">
          <b-icon icon="arrow-down-left" />
        </b-checkbox>
      </b-checkbox-group>
    </b-form-group>
    <b-form-group
      label="Angle"
      label-cols="4"
    >
      <b-input-group>
        <b-input
          v-model="editor.angle"
          max="360"
          min="0"
          number
          type="range"
          @input="redraw"
        />
        <template #append>
          <b-input
            v-model="editor.angle"
            max="360"
            min="0"
            number
            type="number"
            @input="redraw"
          />
        </template>
      </b-input-group>
    </b-form-group>
    <b-form-group
      label="Transparency"
      label-cols="4"
    >
      <b-input-group>
        <b-input
          v-model="editor.transparency"
          max="100"
          min="0"
          number
          type="range"
          @input="redraw"
        />
        <template #append>
          <b-input
            v-model="editor.transparency"
            max="100"
            min="0"
            number
            type="number"
            @input="redraw"
          />
        </template>
      </b-input-group>
    </b-form-group>
    <b-form-group
      v-if="isGradientSelected"
      label="Amplitude"
      label-cols="4"
    >
      <b-input-group>
        <b-input
          v-model="editor.amplitude"
          max="75"
          min="0"
          type="range"
          @input="redraw"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group
      v-if="isGradientSelected"
      label="Frequency"
      label-cols="4"
    >
      <b-input-group>
        <b-input
          v-model="editor.frequency"
          max="100"
          min="1"
          type="range"
          @input="redraw"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group
      label="Gaussian blur"
      label-cols="4"
    >
      <b-input-group>
        <b-input
          v-model="editor.blur"
          max="200"
          min="0"
          type="range"
          @input="redraw"
        />
        <template #append>
          <b-input
            v-model="editor.blur"
            max="200"
            min="0"
            number
            type="number"
            @input="redraw"
          />
        </template>
      </b-input-group>
    </b-form-group>
    <b-form-group
      label="Colors"
      label-cols="4"
    >
      <b-input-group>
        <b-input
          v-model="colorsNum"
          max="5"
          min="1"
          type="range"
          @input="colorsNumChange"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group :label-cols="false">
      <div
        v-if="!(editor.radialGradient || editor.linearGradient)"
        class="d-inline-flex"
      >
        <b-input
          v-for="(color, index) in editor.colors"
          :key="index"
          v-model="editor.colors[index]"
          class="color-fill-item m-1"
          debounce="50"
          type="color"
          @update="redraw"
        />
      </div>
      <ColorStop
        v-if="editor.radialGradient || editor.linearGradient"
        :active-color="activeColor"
        :editor="editor"
        :show-color-picker="showColorPicker"
        tabindex="0"
        @colorStepsInput="updateStepOfColor"
        @colorStopClicked="setActiveColor"
        @colorUpdated="updateColor"
      />
    </b-form-group>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect';
import {deepCopy} from "@frontend/utils";
import {BackgroundMakerV2} from "@frontend/BackgroundMaker";
import ColorStop from "@frontend/components/modules/layouts-page/EditorControl/components/ColorStop.vue";
import presetApi from "@frontend/services/api/presets";

const MAX_DIMENSION = 300
const MAX_DIMENSION_W_UNIT = `${MAX_DIMENSION}px`
const CREATE_NEW_PRESET_OPTION = { 
  name: 'Create New', 
  id: '' 
};
export default {
  name: 'BackgroundColorEditor',
  components: {
    ColorStop,
    Multiselect
  },
  props: {
    target: {
      type: Object,
      default: () => null,
      required: true
    },

    width: {
      type: Number,
      default: 1000,
    },
    height: {
      type: Number,
      default: 1000,
    },
  },
  data: () => ({
    canvasStyle: {
      width: MAX_DIMENSION_W_UNIT,
      height: MAX_DIMENSION_W_UNIT,
    },
    canvasWrapperStyle: {
      width: MAX_DIMENSION_W_UNIT,
      height: MAX_DIMENSION_W_UNIT,
    },
    showColorPicker: false,
    editor: {},
    colorsNum: 1,
    activeColor: '#FFFFFF',
    activeColorIndex: 0,
    trimTransparentArea: false,
    maxRounding: 0,
    activeShape: 'none',
    shapes: {},
    selectedPreset: '',
    selectedPresetModel: CREATE_NEW_PRESET_OPTION,
    presets: []
  }),
  computed: {
    fileName: {
      get() {
        return this.editor.fileName
      },
      set(val) {
        this.editor.fileName = val
      },
    },
    selectedCorners: {
      get() {
        return _.keys(_.pickBy(this.editor.edges))
      },
      set(val) {
        this.$set(this.editor, 'edges', _.mapValues(this.editor.edges, (v, k) => _.includes(val, k)))
      },
    },
    disabledRounding() {
      return this.activeShape === 'circle'
    },
    isGradientSelected() {
      return !(this.editor.radialGradient || this.editor.linearGradient);
    }
  },
  watch: {
    target() {
      this.init()
    }
  },
  async mounted() {
    const {data} = await presetApi.getAll({type: this.target.fieldType});
    this.presets = [
      CREATE_NEW_PRESET_OPTION,
      ...data
    ];
    this.init(this.target)
  },
  methods: {
    init() {
      this.maxRounding = Math.max(this.target.width, this.target.height) / 2;
      this.editor = this.target.editor;
      this.editor.width = this.target.canvas.width;
      this.editor.height = this.target.canvas.height;
      this.colorsNum = this.editor.colors.length;
      this.activeColor = this.editor.colors[this.activeColorIndex];
      this.selectedPresetModel = this.presets.find(preset => preset.id === this.editor.presetId) || CREATE_NEW_PRESET_OPTION;
      this.updateCanvasDimensions()
    },
    redraw(avoidSettingsUpdate = true) {
      this.editor.redraw()
      this.target.set('fill', new fabric.Pattern({
        source: this.editor.canvas,
        repeat: 'no-repeat'
      }))
      
      if (avoidSettingsUpdate) {
        this.target.updateSettings(deepCopy(this.editor.getSettings()));
        this.target.canvas.requestRenderAll();
      }
    },

    colorsNumChange() {
      const delta = this.colorsNum - this.editor.colors.length

      if (delta > 0) {
        for (let i = 0; i < delta; i++) {
          this.editor.colors.push(BackgroundMakerV2.randomColor())
        }
      } else {
        this.editor.colors.splice(delta)
      }

      for (let i = 0; i < this.editor.colors.length; i++) {
        this.editor.colorSteps[i] = 50;
      }
      this.redraw()
    },

    updateStepOfColor({index, value}) {
      this.editor.colorSteps[index] = value;
      this.redraw();
    },

    setActiveColor({color, index}) {
      this.activeColor = color
      this.activeColorIndex = index
      this.$nextTick(() => {
        this.showColorPicker = true
      })
    },

    updateColor({color,index}) {
      this.$set(this.editor.colors, index, color);
      this.redraw();
    },

    async updateCanvasDimensions() {
      const aspectRatio = this.editor.width / this.editor.height
      
      if (aspectRatio >= 1) {
        this.canvasStyle.width = MAX_DIMENSION_W_UNIT
        this.canvasStyle.height = `${MAX_DIMENSION / aspectRatio}px`
      } else {
        this.canvasStyle.height = MAX_DIMENSION_W_UNIT
        this.canvasStyle.width = `${MAX_DIMENSION * aspectRatio}px`
      }

      this.maxRounding = Math.floor(Math.max(this.editor.width, this.editor.height) / 2)
      
      if (this.activeShape === 'circle') {
        this.editor.rounding = this.maxRounding
      }

      this.redraw(false)
    },
    hideColorPicker ({e, colorPickerElement}) {
      if (!colorPickerElement.contains(e.relatedTarget)) {
        this.showColorPicker = false
      }
    },
    applyPreset() {
      const { settings } = this.presets.find(({id}) => id === this.selectedPreset );
      this.editor.applySettings({ ...JSON.parse(settings), presetId: this.selectedPreset });
      this.redraw();
      toastr.success("Preset applied");
    },
    async savePresetOfOvalShadow() {
      const selectedPreset = this.presets.find(preset => preset.id && preset.id === this.selectedPreset);
      const swalData = await Swal.fire({
        title: selectedPreset ? 'Edit Preset' : `Save Preset`,
        html: `<div class="d-flex flex-column">
          <div>
            <input type="text" id="preset-name" class="form-control" placeholder="name" value="${selectedPreset ? selectedPreset.name : ''}">
          </div>
          <div class="form-check ${!selectedPreset ? 'd-none' : ''}">
            <input type="checkbox" class="form-check-input" id="update-only-name">
            <label for="update-only-name" class="form-check-label">update name only</label>
          </div>
        </div>`,
        inputValue: selectedPreset ? selectedPreset.name : '',
        inputAttributes: {
          autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Save",
        showLoaderOnConfirm: true,

        preConfirm: () => {
          const name = document.getElementById('preset-name').value
          const updateOnlyName = document.getElementById('update-only-name').checked
          return {name, updateOnlyName};
        },
      });

      if (!swalData.isConfirmed) return;
      const data = {
        name: swalData.value.name,
        settings: JSON.stringify(this.editor.getSettings()),
        type: this.target.fieldType
      };
      data.settings = JSON.stringify(this.editor.getSettings());

      if (selectedPreset) {
        if (swalData.value.updateOnlyName) {
          delete data.settings;
        }
        await presetApi.update(this.selectedPreset, data);

        Object.assign(this.presets.find(({id}) => id == this.selectedPreset), data);
        toastr.success("Preset updated");
      } else {
        const {data: preset} = await presetApi.create(data);
        this.presets.push(preset);
        this.selectedPreset = preset.id;
        this.selectedPresetModel = preset;
        toastr.success("Preset saved");
      }
    },
    async deletePreset() {
      await presetApi.delete(this.selectedPreset);
      this.presets.splice(this.presets.findIndex(({id}) => id == this.selectedPreset), 1);
      this.selectedPreset = '';
      this.selectedPresetModel = CREATE_NEW_PRESET_OPTION;
      toastr.success("Shadow preset removed");
    },
    async resetSettings() {
     this.editor.applySettings({
       "linearGradient": true,
       "radialGradient": false,
       "aspectRatio": null,
       "angle": 0,
       "amplitude": 0,
       "frequency": 1,
       "colors": [
         "transparent"
       ],
       "rounding": 0,
       "transparency": 0,
       "edges": {
         "topLeft": false,
         "topRight": false,
         "bottomLeft": false,
         "bottomRight": false
       },
       "colorSteps": [
         50,
         50,
         50,
         50,
         50
       ],
       "blur": 0
     });
     this.redraw();
    },

    handleSelectPreset(value) {
      this.selectedPreset = value.id;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .multiselect {
    &__single {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__option {
      display: flex;
      align-items: center;
    }
  }
}

.preset-preview {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: .25rem;
  margin-right: .5rem;
}

.canvas-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shape {
  display: block;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  border-radius: 0;

  &-circle {
    border-radius: 50%;
  }

  &-rectangle {
    width: 3rem;
  }

  &-none {
    span {
      display: block;
      position: absolute;
      font-size: 44px;
      top: -16px;
      left: 32px;
    }
  }
}

.color-stop-range {
  margin: 15px 0 0 0;
}

.color-fill-item {
  width: 38px;
  height: 31px;
}
</style>
