import {getOrderForAddField} from "../../../utils";
import { ShowPageScopeCollector } from '@frontend/group/modules/scope-collectors/show-page-collector';
import { canvasEditModeControl } from "@frontend/group/modules/canvas-edit-mode-control";

const getShowPageScope = () => ShowPageScopeCollector.get([
  'previews',
  'sliceForCurrentPage',
]);

const getPreview = () => {
  const { previews, sliceForCurrentPage } = getShowPageScope();

  return sliceForCurrentPage(previews);
}



let canvasData,
  drawFieldsFunctions,
  updateLayoutChanges,
  drawPreviews,
  previews,
  order = 0,
  reorderObjects;

export const DEFAULT_FONT_FOR_ADDITION_TEXT_FIELD = 'AlternateGothicATF-Black';

export const DEFAULT_TEXT_FIELD_PROPERTIES = {
  _alignment: "left",
  _angle: "0.00",
  _b_height: "114",
  _b_width: "186.49969100952148",
  _bounding_rect_data: '{"left":136,"top":57,"width":186,"height":114}',
  _color: "#000",
  _font: DEFAULT_FONT_FOR_ADDITION_TEXT_FIELD,
  _fontsize: "100",
  _height: "0",
  _offset_x: "136.00",
  _offset_y: "57.00",
  _order: "0",
  _shadow: "0",
  _skew_coords: '"[]"',
  _skew_image: "",
  _skew_image_point: "",
  _topmost_y: "19",
  _tracking: "0",
  _width: "185",
  _translated_from_element_id: null
};

export const DEFAULT_MULTI_LINE_TEXT_FIELD_PROPERTIES = {
  _alignment: "left",
  _angle: "0.00",
  _bounding_data: '{"left":136,"top":57.00000000000001,"width":735.0987739562988,"height":114}',
  _color: "#000",
  _corner_radius: "",
  _corners: "",
  _font: DEFAULT_FONT_FOR_ADDITION_TEXT_FIELD,
  _fontsize: "100",
  _height: "112.99999999999999",
  _leading: "1",
  _line_heights: "113.00",
  _offset_x: "136.00",
  _offset_y: "57.00",
  _opacity: "",
  _order: "0",
  _radius: "",
  _scaleX: "",
  _scaleY: "",
  _shadow: "0",
  _skew_coords: '"[]"',
  _skew_image: "",
  _skew_image_point: "",
  _thickness: "",
  _topmost_y: '[{"topMostX":14,"topMostY":16}]',
  _tracking: "0",
  _width: "734.0987739562988",
  _translated_from_element_id: null
};
const DEFAULT_RECTANGLE_FIELD_PROPERTIES = {
  _angle: "0",
  _corner_radius: "0",
  _corners: "[0,0,0,0]",
  _fill_color: "#000000",
  _height: "300",
  _offset_x: "0",
  _offset_y: "0",
  _opacity: "1",
  _order: "88",
  _radius: "150",
  _scaleX: "1",
  _scaleY: "1",
  _stroke_color: "#000",
  _thickness: "0",
  _toggle_shape: "on",
  _width: "300",
};
const DEFAULT_CIRCLE_FIELD_PROPERTIES = {
  _angle: "0",
  _corner_radius: "0",
  _corners: "[0,0,0,0]",
  _fill_color: "#000000",
  _height: "300",
  _offset_x: "0",
  _offset_y: "0",
  _opacity: "1",
  _order: "88",
  _radius: "150",
  _scaleX: "1",
  _scaleY: "1",
  _stroke_color: "#000",
  _thickness: "0",
  _toggle_shape: "on",
  _width: "300",
};
export const DEFAULT_BACKGROUND_THEME_IMAGE_FIELD_PROPERTIES = {
  'additional_background_images': '/img/icons/image.png',
  "add_bk_img_offset_x": "260",
  "add_bk_img_offset_y": "200",
  "add_bk_img_scale": "1",
  "add_bk_img_scale_y": "1",
  "add_bk_img_shadow": "0",
  "add_bk_img_angle": "0",
  "add_bk_img_is_mirror": "false",
  "add_bk_img_mirror_position": "right",
  "add_bk_img_mirror_distance": "0",
  "add_bk_img_mirror_coords": "{}",
  "add_bk_img_mirror_skew": "0",
  "add_bk_img_mirror_delete_source": "false",
  "add_bk_img_mirror_fade": "false",
  "add_bk_img_order": "150",
  "add_bk_img_duplicate_data": null,
  "add_bk_img_width": "520",
  "add_bk_img_height": "400",
  "add_bk_img_flipX": "0",
  "add_bk_img_flipY": "0",
  "add_bk_img_masks": []
};

function getFieldName(field_type) {
  return "add_" + field_type.toLowerCase().replace(/ /g, '_') + "_" + Date.now();
}

export async function addAdditionalText(field_type, options = {}) {
  const fieldName = getFieldName(field_type);
  const newData = {};
  newData[fieldName] = options.text || 'text';

  for (let key of Object.keys(DEFAULT_TEXT_FIELD_PROPERTIES)) {
    newData[`${fieldName + key}`] = options[key] || DEFAULT_TEXT_FIELD_PROPERTIES[key];
  }

  canvasData[options.currentInstanceId].additionalTexts.push(fieldName);

  for (let key of Object.keys(newData)) {
    updateLayoutChanges(options.currentInstanceId, key, newData[key]);
  }

  await drawFieldsFunctions.drawAdditionalText({canvas: options.currentPreview.canvas, id: options.currentInstanceId});
  await reorderObjects(options.currentPreview.canvas);
}

async function addMultiLineText(field_type, options = {}) {
  const fieldName = getFieldName(field_type);
  const newData = {};
  newData[fieldName] = options.text || 'Add-multi-line-text';

  for (let key of Object.keys(DEFAULT_MULTI_LINE_TEXT_FIELD_PROPERTIES)) {
    newData[`${fieldName + key}`] = options[key] || DEFAULT_MULTI_LINE_TEXT_FIELD_PROPERTIES[key];
  }

  canvasData[options.currentInstanceId].additionalMultiLineTexts.push(fieldName);

  for (let key of Object.keys(newData)) {
    updateLayoutChanges(options.currentInstanceId, key, newData[key]);
  }

  await drawFieldsFunctions.drawAdditionalMultiLineText({canvas: options.currentPreview.canvas, id: options.currentInstanceId});
  await reorderObjects(options.currentPreview.canvas);
}

async function addRectangle(field_type, options = {}) {
  const fieldName = getFieldName(field_type);
  const newData = {};
  newData[fieldName] = '#000';
  for (let key of Object.keys(DEFAULT_RECTANGLE_FIELD_PROPERTIES)) {
    newData[`${fieldName + key}`] = options[key] || DEFAULT_RECTANGLE_FIELD_PROPERTIES[key];
  }
  canvasData[options.currentInstanceId].additionalRectangles.push(fieldName);
  for (let key of Object.keys(newData)) {
    updateLayoutChanges(options.currentInstanceId, key, newData[key]);
  }
  await drawFieldsFunctions.drawAdditionalRectangle({canvas: options.currentPreview.canvas, id: options.currentInstanceId});
  await reorderObjects(options.currentPreview.canvas);
}

async function addCircle(field_type, options = {}) {
  const fieldName = getFieldName(field_type);
  const newData = {};
  newData[fieldName] = '#000';
  for (let key of Object.keys(DEFAULT_CIRCLE_FIELD_PROPERTIES)) {
    newData[`${fieldName + key}`] = options[key] || DEFAULT_CIRCLE_FIELD_PROPERTIES[key];
  }
  canvasData[options.currentInstanceId].additionalCircles.push(fieldName);
  for (let key of Object.keys(newData)) {
    updateLayoutChanges(options.currentInstanceId, key, newData[key]);
  }
  await drawFieldsFunctions.drawAdditionalCircle({canvas: options.currentPreview.canvas, id: options.currentInstanceId});
  await reorderObjects(options.currentPreview.canvas);
}

async function addBackgroundThemeImage(options = {}) {
  const newData = {};
  let properties = {
    ...DEFAULT_BACKGROUND_THEME_IMAGE_FIELD_PROPERTIES,
    add_bk_img_order: order,
  };
  
  for (let key of Object.keys(properties)) {
    newData[key] = Object.hasOwn(canvasData[options.currentInstanceId]._data, key)
      ? [...canvasData[options.currentInstanceId]._data[key], properties[key]]
      : [properties[key]];
  }
  for (let key of Object.keys(newData)) {
    updateLayoutChanges(options.currentInstanceId, key, newData[key]);
  }
  await drawFieldsFunctions.drawPreviews(getPreview());
  await drawFieldsFunctions.setAdditionalBackgroundImage({data: canvasData[options.currentInstanceId], canvas: options.currentPreview.canvas, id: options.currentInstanceId});
  await reorderObjects(options.currentPreview.canvas);
  options.currentPreview.canvas.renderAll();
}

export async function addAddField(type, instanceId, options = {}) {
  options.currentInstanceId = instanceId;
  options.currentPreview = getPreview().find(preview => preview.id === options.currentInstanceId)
  if (!type || !options.currentInstanceId || !options.currentPreview) return;
  const isHasUsersOrders = 
    Object.keys(canvasData[options.currentInstanceId]._data).includes('users_fields_order')
  && !!canvasData[options.currentInstanceId]._data.users_fields_order;
  if (!isHasUsersOrders) {
    const fields = options.currentPreview.template.fields

    order = getOrderForAddField(fields);
  }
  switch (type) {
    case 'Text':
      await addAdditionalText(type, options)
      break
    case 'Multi Line Text':
      await addMultiLineText(type, options)
      break
    case 'Rectangle':
      await addRectangle(type, options);
      break
    case 'Circle':
      await addCircle(type, options);
      break
    case 'Background Theme Image':
      await addBackgroundThemeImage(options);
      break
  }
}

function onAddField() {
  const type = $(this).attr('value');
  const instanceId = $(this).parent().data('id');
  
  // Setting the keepSelected variable to false allows the text fields to be unselected when an additional field is added
  canvasEditModeControl.setKeepSelected(false);
  
  addAddField(type, instanceId);

  canvasEditModeControl.setKeepSelected(true);
}

export function initAdditionalFields(_canvas_data, _previews, _drawFieldsFunctions, _updateLayoutChanges, _reorderObjects) {
  canvasData = _canvas_data;
  previews = _previews;
  reorderObjects = _reorderObjects;
  drawFieldsFunctions = _drawFieldsFunctions;
  updateLayoutChanges = _updateLayoutChanges;
  $('.additional-fields-dropdown-list li').on('click', onAddField);
}
