export default {
  create(payload) {
    return axios.post('/catalogs', payload)
  },
  list(page = 1) {
    return axios.get(`/catalogs?page=${page}`)
  },
  update(id, payload) {
    return axios.put(`/catalogs/${id}`, payload)
  },
  delete(id) {
    return axios.delete(`/catalogs/${id}`);
  }
}
