var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-folder-layer__item d-flex align-items-center mt-2" },
    [
      _c("div", { staticClass: "c-folder-layer__item--preview mr-2" }, [
        _c("img", {
          staticClass: "layer--item__img",
          style: _vm.getStyle,
          attrs: { src: _vm.src, alt: _vm.node.data.name },
        }),
      ]),
      _vm._v(" "),
      _c("div", [_vm._v("\n    " + _vm._s(_vm.node.data.name) + "\n  ")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }