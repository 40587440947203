<template>
  <div
    class="c-grid-view"
    @click.self="clearSelection"
  >
    <div
      v-show="folderItems.length"
      class="c-grid-view__container folder-items pb-3"
      @click.self="clearSelection"
    >
      <GridItem
        v-for="folderItem in folderItems"
        :key="folderItem.id"
        :item="folderItem"
        :is-selected="isSelected(folderItem)"
        :is-available-overlay="false"
        :draggable="isDragAndDropAvailable"
        @click="itemClickHandled"
        @dblclick="dbClickHandler"
      />
    </div>

    <div
      class="c-grid-view__container other-items"
      @click.self="clearSelection"
    >
      <GridItem
        v-for="otherItem in otherItems"
        :key="otherItem.id"
        :item="otherItem"
        :is-selected="isSelected(otherItem)"
        :is-available-overlay="!onlyOneItemSelection"
        :draggable="isDragAndDropAvailable"
        @click="itemClickHandled"
        @dblclick="dbClickHandler"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import GridItem from "../GridItem.vue";
import {MUTATIONS} from "@frontend/store/file-browser-product-selector";
import {FILE_TYPE} from "@frontend/constants/file-image-types";

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('fileBrowserProductSelector')

export default {
  name: "GridView",
  components: {GridItem},
  props: {
    isDragAndDropAvailable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    dragIcon: null,
  }),
  computed: {
    ...mapGetters([
      'items',
      'isSelected',
      'onlyOneItemSelection',
    ]),
    folderItems() {
      return this.items.filter(item => item.type === FILE_TYPE.FOLDER);
    },
    otherItems() {
      return this.items.filter(item => item.type !== FILE_TYPE.FOLDER);
    }

  },
  methods: {
    ...mapActions(['clearSelection', 'setCurrentFolder', 'fetchList', 'togglePickedItem']),
    ...mapMutations([
      MUTATIONS.TOGGLE_SELECTED_ITEMS, 
      MUTATIONS.CLEAR_SELECTION, 
      MUTATIONS.SET_MODAL,
    ]),
    itemClickHandled(item) {
      if (item.name == '...') {
        return
      }
      this.clearSelection();
      
      if ((item.type !== FILE_TYPE.FOLDER && this.onlyOneItemSelection) || (item.count === 1 || item.count === 0)) {
        this.togglePickedItem(item);
      }
      this[MUTATIONS.TOGGLE_SELECTED_ITEMS](item.id);
    },
    dbClickHandler(item) {
      if (item.type === FILE_TYPE.FOLDER) {
        this.setCurrentFolder(item);
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .c-grid-view {
    display: grid;

    &__container {
      display: grid;
      min-height: 280px;
      padding: 0;
      overflow-y: auto;
      grid-template-rows: 150px auto;
      -moz-column-gap: 25px;
      column-gap: 25px;
      row-gap: 5px;
      justify-self: center;
      grid-template-columns: repeat(6, minmax(100px, 1fr));

      @media (max-width: 1700px) {
        grid-template-columns: repeat(5, minmax(100px, 1fr)) !important;
      }

      @media (max-width: 1500px) {
        grid-template-columns: repeat(4, minmax(100px, 1fr)) !important;
      }

      @media (max-width: 1300px) {
        grid-template-columns: repeat(4, minmax(100px, 1fr)) !important;
      }

      @media (max-width: 1155px) {
        grid-template-columns: repeat(3, minmax(100px, 1fr)) !important;
      }

     @media (max-width: 991px) {
        grid-template-columns: repeat(2, minmax(100px, 1fr)) !important;
     }

      @media (max-width: 650px) {
        grid-template-columns: repeat(1, minmax(100px, 1fr)) !important;
      }
    }

    .folder-items {
      min-height: initial;
      row-gap: 14px;
      grid-template-rows: 56px;
      
    }

  }
</style>
