var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            [
              _c("Multiselect", {
                staticClass: "multiselect-customer",
                attrs: {
                  "track-by": "name",
                  label: "name",
                  placeholder: "Select Customer",
                  value: _vm.value.customer,
                  options: _vm.customers,
                  "show-labels": false,
                },
                on: { input: _vm.onChangeCustomer },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            [
              _c("Multiselect", {
                attrs: {
                  placeholder: "Filter Templates",
                  multiple: "",
                  loading: _vm.isLoading,
                  options: _vm.getTags,
                  "close-on-select": false,
                  "show-labels": false,
                },
                on: { input: _vm.onChangeTags },
                model: {
                  value: _vm.tags,
                  callback: function ($$v) {
                    _vm.tags = $$v
                  },
                  expression: "tags",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c(
                    "b-checkbox",
                    {
                      staticClass: "float-right",
                      attrs: { switch: "" },
                      on: {
                        change: function ($event) {
                          return _vm.$refs.templatesSelect.activate()
                        },
                      },
                      model: {
                        value: _vm.galleryView,
                        callback: function ($$v) {
                          _vm.galleryView = $$v
                        },
                        expression: "galleryView",
                      },
                    },
                    [_vm._v("\n        Gallery view\n      ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("Multiselect", {
            ref: "templatesSelect",
            class: ["templates-dropdown", { "gallery-view": _vm.galleryView }],
            attrs: {
              "track-by": "name",
              label: "name",
              placeholder: "Select Template",
              multiple: "",
              "group-label": "label",
              "group-values": "values",
              "group-select": true,
              loading: _vm.isLoading,
              value: _vm.value.templates,
              options: _vm.getTemplates,
              "close-on-select": false,
              "show-labels": false,
            },
            on: { input: (value) => _vm.onChange("templates", value) },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function (props) {
                  return [
                    !props.option.$groupLabel
                      ? _c("img", {
                          attrs: {
                            src: _vm.resolveUrl(props.option.image_url),
                            alt: props.option.name,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(props.option.$groupLabel || props.option.name)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }