<template>
  <div>
    <b-button-group
      v-if="showNextTemplateButton || selectedInstanceIndex"
      size="sm"
      class="my-2"
    >
      <b-button
        v-if="showNextTemplateButton"
        variant="outline-primary"
        @click="moveInstanceSelection(1)"
      >
        <b-icon icon="arrow-down" />
        Next template
      </b-button>
      <b-button
        v-if="selectedInstanceIndex"
        variant="outline-primary"
        @click="moveInstanceSelection(-1)"
      >
        Previous template
        <b-icon icon="arrow-up" />
      </b-button>
    </b-button-group>

    <b-form-group
      v-for="(field, index) in textFields"
      :key="`${field.element_id}_${index}`"
      :label="field.name"
      :label-for="`field_${field.id}`"
    >
      <b-input
        :id="`field_${field.id}`"
        :value="selectedInstance.data[field.element_id]"
        @focus="setActiveObject(field)"
        @input="updateField(field, $event)"
        @click.stop
      />
    </b-form-group>
    <b-form-group
      v-for="(field, index) in multilineTextFields"
      :key="`${field.element_id}_${index}`"
      :label="field.name"
      :label-for="`field_${field.id}`"
    >
      <b-textarea
        :id="`field_${field.id}`"
        :value="getValidatedValue(selectedInstance.data[field.element_id])"
        max-rows="8"
        @focus="setActiveObject(field)"
        @input="updateField(field, $event)"
        @click.stop
      />
      <b-button
        v-if="shouldShowThisField(field)"
        class="mt-1 btn-sm w-100"
        variant="primary"
        @click="redraw(field)"
      >
        <b-icon icon="align-center" />
        Align text to <strong>{{ JSON.parse(field.options).Option1 }}</strong>
      </b-button>
    </b-form-group>
    <b-form-group class="translation-form-group">
      <div class="d-flex flex-column">
        <b-form-select
          v-model="translateTargetLanguage"
          class="flex-grow-1 mr-2"
          :options="languages"
        />
        <div class="button-wrapper mt-3">
          <b-dropdown
            id="translate-options-dropdown"
            text="Translate"
            variant="primary"
          >
            <b-list-group flush>
              <b-list-group-item>
                <b-overlay
                  :show="loadingTranslate"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-button
                    class="flex-shrink-0"
                    @click="translate"
                  >
                    Translate
                  </b-button>
                </b-overlay>
              </b-list-group-item>
              <b-list-group-item>
                <b-overlay
                  :show="loadingCopyAndTranslate"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-button
                    class="flex-shrink-0"
                    @click="copyAndTranslate"
                  >
                    Copy Template & Translate
                  </b-button>
                </b-overlay>
              </b-list-group-item>
              <b-list-group-item>
                <b-overlay
                  :show="loadingCopyFieldsAndTranslate"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-button
                    class="flex-shrink-0"
                    @click="copyFieldsAndTranslate"
                  >
                    Copy Fields & Translate
                  </b-button>
                </b-overlay>
              </b-list-group-item>
            </b-list-group>
          </b-dropdown>
        </div>
      </div>
    </b-form-group>
    <b-form-group
      v-for="(field) in additionalTextFields"
      :key="`${field.element_id}`"
      :label="field.name"
      :label-for="`add_field_${field.element_id}`"
    >
      <b-input
        :id="`add_field_${field.element_id}`"
        :value="field.text"
        @focus="setActiveObject(field)"
        @input="updateField(field, $event)"
        @click.stop
      />
    </b-form-group>
    <b-form-group
      v-for="(field) in additionalMultilineTextFields"
      :key="`${field.element_id}`"
      :label="field.name"
      :label-for="`add_field_${field.element_id}`"
    >
      <b-textarea
        :id="`add_field_${field.element_id}`"
        :value="field.text"
        max-rows="8"
        @focus="setActiveObject(field)"
        @input="updateField(field, $event)"
        @click.stop
      />
    </b-form-group>
    <b-form-group
      label="Global Update"
    >
      <div class="d-flex align-items-center">
        <b-form-checkbox
          v-model="isApplyToAllGlobalUpdate"
          class="mr-1"
          @change="onChangeApplyToAllGlobal"
        >
          Apply to
        </b-form-checkbox>

        <SelectTemplateDropdown
          :local-storage-key="'selected-templates-global-update'"
          :clear-local-storage="true"
        />
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { editorControlEventBroker, EVENTS } from '@frontend/group/modules/editor-control-popup/event-broker'
import { setSelectedInstance } from '@frontend/group/modules/copy-paste-object'
import { HANDLER_TYPES } from "@frontend/group/modules/translate-template";
import SelectTemplateDropdown
  from "@frontend/components/modules/layouts-page/EditorControl/components/SelectTemplateDropdown.vue";
import {GLOBAL_UPDATE_LOCAL_STORAGE_KEY, isGlobalUpdateOn} from "@frontend/constants/global-update";

export default {
  name: 'FieldsModule',
  components: {SelectTemplateDropdown},

  data: () => ({
    translateTargetLanguage: 'Choose a language...',
    languages: [
      'Choose a language...',
      'Arabic',
      'Bengali',
      'Cantonese',
      'Dutch',
      'English',
      'French-CA',
      'French-FR',
      'German',
      'Hindi',
      'Italian',
      'Japanese',
      'Korean',
      'Polish',
      'Portuguese',
      'Russian',
      'Simplified Chinese',
      'Spanish',
      'Swedish',
      'Thai',
      'Traditional Chinese',
      'Vietnamese'
    ],
    loadingCopyFieldsAndTranslate: false,
    loadingCopyAndTranslate: false,
    loadingTranslate: false,
    isApplyToAllGlobalUpdate: false
  }),

  computed: {
    ...mapGetters('layout', [
      'selectedInstance',
      'selectedInstanceIndex',
      'selectedInstanceFieldsWithType',
      'selectedInstanceAdditionalFields',
      'currentPagePreviews',
    ]),
    textFields () {
      return this.selectedInstanceFieldsWithType('Text')
    },
    multilineTextFields () {
      return this.selectedInstanceFieldsWithType('Multi Line Text')
    },
    additionalTextFields () {
      return this.selectedInstanceAdditionalFields.filter(field => field.type === 'Text');
    },
    additionalMultilineTextFields () {
      return this.selectedInstanceAdditionalFields.filter(field => field.type === 'Multi Line Text');
    },
    showNextTemplateButton () {
      return !!this.currentPagePreviews.at(this.selectedInstanceIndex + 1)
    },
  },
  
  mounted() {
    this.isApplyToAllGlobalUpdate = isGlobalUpdateOn();
    this.changeGlobalUpdateIndicator()
    if (localStorage.getItem(GLOBAL_UPDATE_LOCAL_STORAGE_KEY) === null) {
      localStorage.setItem(GLOBAL_UPDATE_LOCAL_STORAGE_KEY, false);
    }
    editorControlEventBroker.on(EVENTS.TOGGLE_LOADING, this.toggleLoading.bind(this));
  },

  methods: {
    onChangeApplyToAllGlobal() {
      localStorage.setItem(GLOBAL_UPDATE_LOCAL_STORAGE_KEY, this.isApplyToAllGlobalUpdate);
      this.changeGlobalUpdateIndicator()
      if (this.isApplyToAllGlobalUpdate && this.selectedInstance) {
        const GLOBAL_UPDATE = 'globalUpdate'
        editorControlEventBroker.fire(EVENTS.IMAGE_CHANGED, { action:GLOBAL_UPDATE, instance: this.selectedInstance})
      }
    },
    changeGlobalUpdateIndicator() {
      const globalUpdateIndicator = $('div#indicator-global-update');
      globalUpdateIndicator.text(isGlobalUpdateOn() ? "Global update: on" : "Global update: off");
      globalUpdateIndicator.css('display', 'block');
    },
    updateField (field, value) {
      editorControlEventBroker.fire(EVENTS.TEXT_CHANGED, {
        value,
        action: 'inputText',
      })
    },
    setActiveObject (field) {
      const target = _.find(this.selectedInstance.canvas.getObjects(), ['element_id', field.element_id])
      editorControlEventBroker.fire(EVENTS.SET_ACTIVE_OBJECT, { target })
    },
    shouldShowThisField(field){
      const options = $.parseJSON(field.options);
      return (options.Option1 !== null && options.Option1 !== "");
    },
    redraw(field) {
      let value = '';
      editorControlEventBroker.fire(EVENTS.TEXT_CHANGED, {
        value,
        action: 'redrawText',
      })
    },
    moveInstanceSelection(step) {
      const newSelectedInstance = this.currentPagePreviews[this.selectedInstanceIndex + step]
      newSelectedInstance.canvas.wrapperEl.scrollIntoView({ behavior: 'smooth', block: 'center' })
      setSelectedInstance(newSelectedInstance.id)
    },
    translate() {
      this.emitTranslateEvent(HANDLER_TYPES.TRANSLATE);
    },
    copyAndTranslate() {
      this.emitTranslateEvent(HANDLER_TYPES.COPY_AND_TRANSLATE);
    },
    copyFieldsAndTranslate() {
      this.emitTranslateEvent(HANDLER_TYPES.COPY_FIELDS_AND_TRANSLATE);
    },
    emitTranslateEvent(handler) {
      if (this.translateTargetLanguage === 'Choose a language...') {
        return;
      }
      
      editorControlEventBroker.fire(EVENTS.TRANSLATE, {
        textFields: this.textFields,
        multilineTextFields: this.multilineTextFields,
        instance: this.selectedInstance,
        lang: this.translateTargetLanguage,
        handler: handler
      });
    },
    toggleLoading({ loadingType }) {
      this[loadingType] = !this[loadingType];
    },
    getValidatedValue(value) {
      return typeof value === 'boolean' ? '' : (value || '');
    },
  }
}
</script>

<style scoped lang="scss">
.translation-form-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    ::v-deep {
        ul.dropdown-menu {
            width: 100%;
        }
    }
}
  .b-overlay-wrap, button {
    width: 100%;
  }
  
  .button-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  button {
    white-space: nowrap;
  }
</style>
