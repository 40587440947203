<template>
  <b-form-row
    ref="uploadImageRow"
    @drop.prevent="onDrop"
    @dragstart.prevent
    @dragenter.prevent
    @dragleave.prevent
    @dragover.prevent
  >
    <b-col cols="auto">
      <b-button
        v-b-tooltip.hover
        size="sm"
        title="Click or drag the image to this button to upload"
        variant="primary"
        @click.prevent="onClick"
      >
        <b-icon-upload />
      </b-button>
    </b-col>
    <template v-if="uploadedImages.length">
      <b-col
        v-for="({key,src, name}) in uploadedImages"
        :key="key"
        cols="auto"
        @click="removeFileFromList(key)"
      >
        <div
          :style="{backgroundImage: 'url(\''+src+'\')'}"
          :title="name"
          class="preview-image-container"
        />
      </b-col>
    </template>
    <template v-if="files.size">
      <b-col cols="auto">
        <b-button
          size="sm"
          title="Replace"
          variant="primary"
          @click="uploadImages('files')"
        >
          <img
            class="w-6 h-6"
            src="/img/icons/replace.svg"
            style="max-width: 18px; max-height: 18px;"
          >
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button
          size="sm"
          title="Cancel upload"
          variant="danger"
          @click="resetUpload"
        >
          <b-icon-x-lg />
        </b-button>
      </b-col>
    </template>
    <b-col
      :cols="isShownUrlInput ? '10' : 'auto'"
      class="d-flex align-items-center"
    >
      <b-button
        v-b-tooltip.hover
        size="sm"
        title="Upload from URL"
        variant="primary"
        @click="isShownUrlInput = !isShownUrlInput"
      >
        <b-icon-link />
      </b-button>
      <b-input
        v-if="isShownUrlInput"
        v-model="imageUrl"
        size="sm"
        type="url"
      />
      <b-button
        v-if="imageUrl && isShownUrlInput"
        size="sm"
        title="Replace"
        variant="primary"
        @click="uploadImages('url')"
      >
        <img
          class="w-6 h-6"
          src="/img/icons/replace.svg"
          style="max-width: 18px; max-height: 18px;"
        >
      </b-button>
    </b-col>
    <b-col
      cols="12"
      class="mt-2"
    >
      <b-form-checkbox
        v-model="needRemoveBackground"
        class="cursor-pointer"
        inline
      >
        Remove background
      </b-form-checkbox>
      <b-form-checkbox
        v-model="needCropToImage"
        class="cursor-pointer"
        inline
      >
        Crop to image
      </b-form-checkbox>
    </b-col>
  </b-form-row>
</template>

<script>

const ALLOWED_EXTENSIONS = ['.webp', '.png', '.jpg', '.jpeg'];
export default {
  props: {},
  data() {
    return {
      files: [],
      uploadedImages: [],
      isShownUrlInput: false,
      imageUrl: '',
      needRemoveBackground: true,
      needCropToImage: true,
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.$root.$on('productImageReplace', this.onDrop);

  },
  methods: {
    async onDrop(e) {
      let isValidFiles = true;
      for (const file of e.dataTransfer.files) {
        isValidFiles = this.isValidFile(file)
      }
      if (!isValidFiles) {
        return
      }
      const files = new Map();
      for (const file of e.dataTransfer.files) {
        files.set(file.name, {file});
      }
      this.files = files;
      await this.updatePreviews();
    },
    async updatePreviews() {
      const previews = [];
      for (const [key, value] of this.files) {
        previews.push({key, src: await this.getBase64FromFile(value.file), name: value.file.name})
      }
      this.uploadedImages = previews;
    },
    async onClick() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = ".jpg,.jpeg,.png,.webp";
      input.multiple = true;
      input.click();
      const listFiles = await new Promise((resolve) => {
        input.onchange = e => {
          resolve(e.target.files);
        }
      });
      let isValidFiles = true;
      for (const file of listFiles) {
        isValidFiles = this.isValidFile(file)
      }
      if (!isValidFiles) {
        return
      }
      const files = new Map();
      for (const file of listFiles) {
        files.set(file.name, {file});
      }
      this.files = files;
      await this.updatePreviews();
    },
    async getBase64FromFile(file) {
      return await new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          resolve(e.target.result);
        };
      });
    },
    resetUpload() {
      this.files = [];
      this.uploadedImages = [];
    },
    async removeFileFromList(key) {
      this.files.delete(key);
      await this.updatePreviews();
    },
    async uploadImages(type) {
      const formData = new FormData();
      formData.append('image_type', 'product_image');
      formData.append('company_id', '-1');
      formData.append('background_remove', this.needRemoveBackground)
      formData.append('crop_to_image', this.needCropToImage)
      switch (type) {
        case 'url':
          if (!await this.isValidUrl()) {
            break;
          }
          formData.append('upload_images_url', this.imageUrl);
          await this.saveProductImage(formData)
          break
        case 'files':
          const isValid = true
          for (const [, value] of this.files) {
            this.isValidFile(value.file);
            formData.append('upload_images[]', value.file);
          }
          if (!isValid) break
          await this.saveProductImage(formData)
          break
      }
    },
    isValidFile(file) {
      let isAllowedExt = true;

      for (let ext of ALLOWED_EXTENSIONS) {
        if (file.name.includes(ext)) {
          isAllowedExt = true;
          break;
        }
        isAllowedExt = false;
      }

      if (!isAllowedExt) {
        toastr.error('Unsupported file type. Supported file types include: jpg, png, webp', null, {preventDuplicates: true});
      }
      return isAllowedExt;
    },
    async isValidUrl() {
      // Regular expression to validate the URL
      const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
      let isAllowedExt = true;
      let isUrl = !!urlPattern.test(this.imageUrl);
      if (!isUrl) {
        toastr.error('Invalid image url, please contact support@itsrapid.io for more assistance');
      }
      for (let ext of ALLOWED_EXTENSIONS) {
        if (this.imageUrl.includes(ext)) {
          isAllowedExt = true;
          break;
        }
        isAllowedExt = false;
      }
      if (!isAllowedExt) {
        toastr.error('Unsupported file type. Supported file types include: jpg, png, webp');
      }
      return isUrl && isAllowedExt;
    },
    async saveProductImage(formData) {
      try {
        window.replaceInfoToastr = toastr.info(
          `<div class="d-inline-flex align-content-center">Replacing image...<div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div></div>`,
          null,
          {
            timeOut: 0,
            tapToDismiss: false,
            closeButton: false
          }
        );
        const response = await axios.post('/file/uploadimg/store', formData, {
          validateStatus: function (status) {
            return status < 400; // Reject only if the status code is greater than or equal to 400
          }
        });
        this.$emit('replaceImages', response.data.filenames.join(' '));

        this.imageUrl = '';
        if (this.isShownUrlInput) {
          this.isShownUrlInput = !this.isShownUrlInput;
        }
        console.log('here')
        this.resetUpload();
      } catch (error) {
        toastr.clear();
        if (error.response) {
          const { data, status, headers } = error.response
          for(let errorKey in data.errors) {
            for(let errorMessage of data.errors[errorKey]) {
              toastr.error(errorMessage)
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.uploaded-image-preview-container {
  width: 100%;
  height: 100%;
  display: inline-flex;
  max-width: 285px;
}

.dragover {
  filter: drop-shadow(0px 0px 1em #329128);
}

.preview-image-container {
  background-size: cover;
  width: 32px;
  height: 32px;
  &:hover {
    filter: drop-shadow(0px 0px 1em red);
    cursor: pointer;
  }
}
.custom-control-label::before {
  left: -1.1rem !important;
}
</style>
