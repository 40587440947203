var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-item-overlay" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: { hover: true },
          },
        ],
        staticClass: "c-item-overlay__item add-item",
        attrs: { title: "Select" },
        on: { click: _vm.addItem },
      },
      [
        _c("b-icon", {
          staticStyle: {
            color: "#479382",
            stroke: "#fff",
            "stroke-width": "1.5px",
          },
          attrs: { icon: "check-lg", "font-scale": "1.3" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }