import {deepCopy} from "../utils";
import {
    TYPE_SUBSCENE_IMAGE,
    TYPE_SUBSCENE_MUSIC,
    TYPE_SUBSCENE_TEXT,
    TYPE_SUBSCENE_TEXT_BOX,
    TYPE_SUBSCENE_VIDEO
} from "./type-subscene";
export const TRANSPARENT_COLOR_VALUE = ''; // should be empty
export const DEFAULT_IMAGE_PATH = '/storage/media/default/add-scene-image.png';
export const DEFAULT_VIDEO_PATH = '/storage/media/default/add-scene-video.mp4';
export const DEFAULT_MUSIC_PATH = '/storage/media/default/essence-by-hotham-Artlist.mp3';

export const TEXT_SCENE = {
    Actions: "",
    Scene: 1,
    Subscene: 1,
    Type: TYPE_SUBSCENE_TEXT,
    Name: "",
    Media: "",
    Character_Count: "",
    Left_direction: "",
    Left: '',
    Top: '',
    Width: '',
    Height: '',
    AlignH: "Center",
    AlignV: "Center",
    Duration: 3,
    Start: 0,
    End: 0,
    Font_Name: "NeueHaasDisplay-Roman",
    Line_Spacing: 1,
    Size: 120,
    Color: "black",
    Kerning: 0,
    Background_Color: TRANSPARENT_COLOR_VALUE,
    Scene_Background: "#FFFFFF",
    Stroke_Width: "",
    Stroke_Color: "",
    Animation: "",
    Animation_duration: "",
    Transition: "",
    Transition_duration: "",
    Props: "",
    Text: "",
    Filename: "",
    originalIndex: 0,
    creationOffset: 0,
    Angle: 0,
    Timeline_Start: 0,
    Timeline_End: 0,
}

export const getTextBoxInitialState = (specificProps = {}, specificPropsForAll = {}) => deepCopy([
    {
        ...TEXT_SCENE,
        Type: TYPE_SUBSCENE_TEXT_BOX,
        AlignH: "Center-FF",
        ...specificProps,
        ...specificPropsForAll
    },
    {
        ...TEXT_SCENE,
        Text: "LOREM",
        AlignH: "Center",
        ...specificPropsForAll
    },
    {
        ...TEXT_SCENE,
        Text: "IPSUM",
        AlignH: "Center",
        ...specificPropsForAll
    },
    {
        ...TEXT_SCENE,
        Text: "DOLOR",
        AlignH: "Center",
        ...specificPropsForAll
    }
])

export const getTextBoxInitialStateSecondOption = (specificProps = {}, specificPropsForAll = {}) => deepCopy([
    {
        ...TEXT_SCENE,
        Type: TYPE_SUBSCENE_TEXT_BOX,
        AlignH: "Center-FF",
        ...specificProps,
        ...specificPropsForAll
    },
    {
        ...TEXT_SCENE,
        Text: "YOUR",
        AlignH: "Center",
        Size: 100,
        ...specificPropsForAll
    },
    {
        ...TEXT_SCENE,
        Text: "TEXT",
        AlignH: "Center",
        Size: 100,
        ...specificPropsForAll
    },
    {
        ...TEXT_SCENE,
        Text: "HERE",
        AlignH: "Center",
        Size: 100,
        ...specificPropsForAll
    }
])

export const IMAGE_SCENE = {
    Actions: "",
    Scene: 1,
    Subscene: 1,
    Type: TYPE_SUBSCENE_IMAGE,
    Name: "",
    Media: "",
    Character_Count: "",
    Left_direction: "",
    Left: '',
    Top: '',
    Width: 1,
    Height: 1,
    AlignH: "Center-FF",
    AlignV: "Center",
    Duration: 3,
    Start: "0",
    End: "",
    Font_Name: "",
    Line_Spacing: 1,
    Size: 1,
    Color: "",
    Kerning: "",
    Background_Color: TRANSPARENT_COLOR_VALUE,
    Scene_Background: "#FFFFFF",
    Stroke_Width: "",
    Stroke_Color: "",
    Animation: "",
    Animation_duration: "",
    Transition: "",
    Transition_duration: "",
    Props: "",
    Text: "",
    Filename: DEFAULT_IMAGE_PATH,
    originalIndex: 0,
    creationOffset: 0,
    Timeline_Start: 0,
    Timeline_End: 0,
    Opacity: 100,
    Angle: 0
}

export const VIDEO_SCENE = {
    Actions: "",
    Scene: 1,
    Subscene: 1,
    Type: TYPE_SUBSCENE_VIDEO,
    Name: "",
    Media: "",
    Character_Count: "",
    Left_direction: "",
    Left: 0,
    Top: 0,
    Width: 1,
    Height: 1,
    AlignH: "Center",
    AlignV: "Center",
    Duration: 3,
    Start: "0",
    End: "",
    Font_Name: "",
    Line_Spacing: 1,
    Size: 1,
    Color: "",
    Kerning: "",
    Background_Color: TRANSPARENT_COLOR_VALUE,
    Scene_Background: "#FFFFFF",
    Stroke_Width: "",
    Stroke_Color: "",
    Animation: "",
    Animation_duration: "",
    Transition: "",
    Transition_duration: "",
    Props: "",
    Text: "",
    Filename: DEFAULT_VIDEO_PATH,
    originalIndex: 0,
    Original_File_Url: DEFAULT_VIDEO_PATH,
    creationOffset: 0,
    Timeline_Start: 0,
    Timeline_End: 0,
    Play_Rate: 1,
}

export const MUSIC_SCENE = {
    ...VIDEO_SCENE,
    Props: {},
    Filename: "/storage/media/default/essence-by-hotham-Artlist.mp3",
    Scene: '',
    Subscene: '',
    Text: '',
    Type: TYPE_SUBSCENE_MUSIC,
    Duration: 30,
    blockSubsceneCounting: true,
    blockSceneCounting: true,
}

export const TYPES_CONFIG = {
    VIDEO: {
        name: 'Video',
        icon: '/img/icons/play.png'
    },
    TEXT: {
        name: 'Text',
        icon: '/img/icons/text.png'
    },
    IMAGE: {
        name: 'Image',
        icon: '/img/icons/image.png'
    },
    TEXT_OVER_IMAGE: {
        name: 'Text over image',
        icon: '/img/icons/text-over-image.png'
    },
    MULTIPLE_IMAGE_SCENE: {
        name: 'Multi-image',
        icon: '/img/icons/gallery.png'
    },
    MUSIC: {
        name: 'Music',
        icon: '/img/icons/music-solid.svg'
    }
}

export const DEFAULT_NAMES_MAP = {
    [TYPE_SUBSCENE_TEXT_BOX]: 'Textbox',
    [TYPE_SUBSCENE_IMAGE]: 'Image',
    [TYPE_SUBSCENE_VIDEO]: 'Video',
    [TYPE_SUBSCENE_TEXT]: 'Text'
}