<template>
  <div class="c-folder-layer__item d-flex align-items-center mt-2">
    <div class="c-folder-layer__item--preview mr-2">
      <img
        class="layer--item__img"
        :src="src"
        :alt="node.data.name"
        :style="getStyle"
      >
    </div>
    <div>
      {{ node.data.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageLayer",
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  computed: {
    src() {
      return location.origin + '/share?file=' + (this.node.src) + '&lightweight&width=200'
    },
    getStyle() {
      const layer = this.node;
      const transform = `${layer.isFlipH ? 'scaleX(-1)' : ''} ${layer.isFlipV ? 'scaleY(-1)' : ''}` 

      return {
        ...(
          layer.isFlipV || layer.isFlipH ?
            {
              '-webkit-transform': transform,
              transform
            } : {})
      };
    }
  },
}
</script>



<style scoped lang="scss">

</style>